import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { takeUntil } from 'rxjs/operators';

import { ViewRequestComponent } from './view-request/view-request.component';
import { SortFilterComponent } from '../../shared/components/sort-filter/sort-filter.component';

import { GlobalServices } from '../../shared/services/global.services';
import { RestfullServices } from '../../shared/services/restfull.services';

import {
    HouseKeepingCategoryModel,
    HouseKeepingCleaningModel,
    HouseKeepingRequestModel, NotificationModel, SortFilterConfigModel
} from '../../shared/models/shared.model';

import {
    housekeepingStatusOpt,
    sortByOpt,
    requestStatusOpt,
    cleaningDisplayedColumns,
    requestDisplayedColumns, sortFilterConfig
} from './const';
import { fillFilterValues, transformString } from '../../shared/functions/helpers';
import { AutoUnsubscribe } from '../../shared/functions/autounsubscribe';
import { ViewCleaningRequestComponent } from './view-cleaning-request/view-cleaning-request.component';
import * as introJs from 'intro.js';

@Component({
    selector: 'app-housekeeping',
    templateUrl: './housekeeping.component.html',
    styleUrls: ['./housekeeping.component.scss']
})
@AutoUnsubscribe()
export class HousekeepingComponent implements OnInit, AfterViewInit, OnDestroy {
    introJS = introJs.default();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('cleaningDataPaginator') set cleaningDataPaginator(pager: MatPaginator) {
        if (pager) this.cleaningDataSource.paginator = pager;
    }
    @ViewChild('requestDataPaginator') set requestDataPaginator(pager: MatPaginator) {
        if (pager) this.requestDataSource.paginator = pager;
    }

    public cleaningDataSource = new MatTableDataSource();
    public requestDataSource = new MatTableDataSource<HouseKeepingRequestModel>();
    public cleaningList: Array<HouseKeepingCleaningModel> = [];
    public requestList: Array<HouseKeepingRequestModel> = [];
    public cleaningFilterList: Array<HouseKeepingCleaningModel> = [];
    public requestFilterList: Array<HouseKeepingRequestModel> = [];
    public cleaningDisplayedColumns: string[] = cleaningDisplayedColumns;
    public requestDisplayedColumns: string[] = requestDisplayedColumns;
    public cleaningSortBy = '0';
    public requestSortBy = '0';
    public houseKeepingCleaningCount = 0;

    public housekeepingStatusOpt = housekeepingStatusOpt;
    public sortByOpt = sortByOpt;
    public requestStatusOpt = requestStatusOpt;

    public sortFilterConfig: SortFilterConfigModel[] = sortFilterConfig;

    public cleaningFilterForm = new UntypedFormGroup({
        roomNumber: new UntypedFormControl(),
        cleaningStatus: new UntypedFormControl(),
        sortBy: new UntypedFormControl()
    });

    public requestFilterForm = new UntypedFormGroup({
        requestRoomNumber: new UntypedFormControl(),
        requestStatus: new UntypedFormControl(),
        sortBy: new UntypedFormControl()
    });

    public activeTableIndex = 0;
    searchValue: boolean = false
    private requestListItems: Array<HouseKeepingRequestModel> = [];
    private houseKeepingCategory: Array<HouseKeepingCategoryModel> = [];

    public defaultFilterPredicate?: (data: any, filter: string) => boolean;

    private cleaningFilterValues = {
        roomNumber: '',
        cleaningStatus: '',
    };

    private requestFilterValues = {
        requestRoomNumber: '',
        requestStatus: '',
    };

    constructor(
        public globalService: GlobalServices,
        private dialog: MatDialog,
        private restfullServices: RestfullServices,
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnInit(): void {
        this.globalService.headerData$.next({
            headerTitle: 'Housekeeping',
            showBackButton: false
        });
        this.globalService.houseKeepingRequestCount = 0;
        this.getHouseKeepingRequest();
        this.formSubscribe();
        this.getFormsValue();
        this.restoreFilterStates();

        this.requestFilterForm.valueChanges.subscribe(requestStatus => {
            this.requestDataSource.data = this.requestFilterList;
            this.saveRequestFilterState();
        });

        this.cleaningFilterForm.valueChanges.subscribe(cleaningStatus => {
            this.cleaningDataSource.data = this.cleaningFilterList;
            this.saveCleaningFilterState();
        });
    }
    startTour() {
        this.introJS.setOptions({
            steps: [
                {
                    tooltipClass: "intro-step-5",
                    title: "HOUSEKEEPING",
                    element: document.getElementById('step-5_Housekeeping'),
                    intro: "Get an overview of housekeeping requests made by guests via the app and efficiently manage guest amenities requests.",
                    position: 'right'
                },

                {
                    //Step 6

                    tooltipClass: "intro-step-6",
                    title: "CLEANING REQUESTS",
                    element: document.getElementById('clean_request'),
                    intro: "This is where you’ll be handling all cleaning requests initiated by guests through the guest app.",
                    //position: 'top'
                },

                {
                    //Step 7

                    tooltipClass: "intro-step-7",
                    title: "REQUESTED AMENITIES",
                    element: document.getElementById('amenities_request'),
                    intro: "Here, you will get an overview of guests’ requested amenities and effortlessly manage and fulfill all requests.",
                    //position: 'right'
                }

            ],
            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip',
        });

        this.introJS.start();
        this.introJS.onbeforechange((targetElement) => {
            this.introJS.refresh();
        });

        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let skipButton = document.querySelector(".intro-skip");
                skipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }



    skipTour(): void {
        this.introJS.exit();
        this.restfullServices.removeLocalStorage('isTourRunning');
        window.location.href = '#/pages/housekeeping';
    }

    closeStep(): void {
        window.location.href = '#/pages/housekeeping';
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.cleaningDataSource.sort = this.sort;
            this.cleaningDataSource.paginator = this.cleaningDataPaginator;
            this.requestDataSource.sort = this.sort;
            this.requestDataSource.paginator = this.requestDataPaginator;
            if (!this.globalService.isMobile$.getValue()) {
                const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                const housekeepingFlag = this.restfullServices.getLocalStorage('guidedTourHousekeepingFlag');
                if (isTourRunning == 'true' && isFirstLogin == 'false' && housekeepingFlag == 'false') {
                    this.restfullServices.setLocalStorage('guidedTourHousekeepingFlag', 'true');
                    this.startTour();
                    this.cdr.detectChanges();
                }
            }

        }, 500);
    }

    ngOnDestroy() { }

    public resetFilter() {
        this.searchValue = false;
        this.cleaningFilterForm.reset();
        this.requestFilterForm.reset();

        // Clear filter values
        this.cleaningFilterValues = { roomNumber: '', cleaningStatus: '' };
        this.requestFilterValues = { requestRoomNumber: '', requestStatus: '' };

        // Reset data sources
        this.cleaningDataSource.data = this.cleaningFilterList;
        this.requestDataSource.data = this.requestFilterList;

        // Reset filter
        this.cleaningDataSource.filter = '';
        this.requestDataSource.filter = '';

        sessionStorage.removeItem('housekeeping_cleaning_filter');
        sessionStorage.removeItem('housekeeping_request_filter');
    }
    private saveCleaningFilterState() {
        const cleaningFilterState = {
            roomNumber: this.cleaningFilterForm.get('roomNumber').value || '',
            cleaningStatus: this.cleaningFilterForm.get('cleaningStatus').value || '',
            sortBy: this.cleaningFilterForm.get('sortBy').value || '',
            filterValues: this.cleaningFilterValues
        };
        sessionStorage.setItem('housekeeping_cleaning_filter', JSON.stringify(cleaningFilterState));
    }
    private saveRequestFilterState() {
        const requestFilterState = {
            requestRoomNumber: this.requestFilterForm.get('requestRoomNumber').value || '',
            requestStatus: this.requestFilterForm.get('requestStatus').value || '',
            sortBy: this.requestFilterForm.get('sortBy').value || '',
            filterValues: this.requestFilterValues
        };
        sessionStorage.setItem('housekeeping_request_filter', JSON.stringify(requestFilterState));
    }
    public setTableTools(index: number) {

        if (!this.cleaningDataSource.sort) {
            this.cleaningDataSource.sort = this.sort;
        }

        if (!this.requestDataSource.sort) {
            this.requestDataSource.sort = this.sort;
        }

        if (!this.cleaningDataSource.paginator) {
            this.cleaningDataSource.paginator = this.cleaningDataPaginator;
        }

        if (!this.requestDataSource.paginator) {
            this.requestDataSource.paginator = this.requestDataPaginator;
        }

        this.activeTableIndex = index;

    }
    private restoreFilterStates() {
        const savedCleaningFilter = sessionStorage.getItem('housekeeping_cleaning_filter');
        if (savedCleaningFilter) {
            const cleaningFilterState = JSON.parse(savedCleaningFilter);
            this.cleaningFilterForm.patchValue({
                roomNumber: cleaningFilterState.roomNumber,
                cleaningStatus: cleaningFilterState.cleaningStatus,
                sortBy: cleaningFilterState.sortBy
            });

            // Directly apply the saved filter values
            this.cleaningFilterValues = {
                roomNumber: cleaningFilterState.roomNumber || '',
                cleaningStatus: cleaningFilterState.cleaningStatus || ''
            };

            // Reapply filter predicate and filter
            this.cleaningDataSource.filterPredicate = this.cleaningDataSource.filterPredicate;
            this.cleaningDataSource.filter = JSON.stringify(this.cleaningFilterValues);

            if (cleaningFilterState.sortBy) {
                this.applyFilter(String(cleaningFilterState.sortBy), 'cleaningDataSource');
            }
        }

        const savedRequestFilter = sessionStorage.getItem('housekeeping_request_filter');
        if (savedRequestFilter) {
            const requestFilterState = JSON.parse(savedRequestFilter);
            this.requestFilterForm.patchValue({
                requestRoomNumber: requestFilterState.requestRoomNumber,
                requestStatus: requestFilterState.requestStatus,
                sortBy: requestFilterState.sortBy
            });

            // Directly apply the saved filter values
            this.requestFilterValues = {
                requestRoomNumber: requestFilterState.requestRoomNumber || '',
                requestStatus: requestFilterState.requestStatus || ''
            };

            // Reapply filter predicate and filter
            this.requestDataSource.filterPredicate = this.requestDataSource.filterPredicate;
            this.requestDataSource.filter = JSON.stringify(this.requestFilterValues);

            if (requestFilterState.sortBy) {
                this.applyFilter(String(requestFilterState.sortBy), 'requestDataSource');
            }
        }
    }


    public filterSelected(event: any) {
        // if (event?.datum) {
        //     this.applyFilter(String(this.sortByOpt.find(item => item.label === event.datum).value),
        //         this.activeTableIndex ? 'requestDataSource' : 'cleaningDataSource');
        // }
        const statusValue = event?.options[1].items.find(item => item.checked)?.label;
        if (statusValue) {
            this.cleaningFilterForm.get('cleaningStatus')
                .setValue(statusValue.includes('Clean') ? 1 : 2);
        } else {
            this.cleaningFilterForm.get('cleaningStatus').setValue('');
        }
    }

    public applyFilter(str: string, tableName: 'cleaningDataSource' | 'requestDataSource') {
        let filter: any = this[tableName].data;
        if (tableName == 'requestDataSource') {
            this.requestFilterForm.get('sortBy').setValue(Number(str));
            switch (str) {
                case '1': filter = this.requestFilterList;
                    filter.sort((a, b) => {
                        if (a.id && b.id) {
                            return b.id - a.id;
                        }
                    });
                    break;
                case '2':
                    // filter.sort((a, b) => {
                    //     if (a.dateTime && b.dateTime) {
                    //         const dateParts1: any = a.dateTime.split('/');
                    //         const dateParts2: any = b.dateTime.split('/');
                    //         const date1 = new Date(+dateParts1[2], dateParts1[0] - 1, +dateParts1[1]);
                    //         const date2 = new Date(+dateParts2[2], dateParts2[0] - 1, +dateParts2[1]);
                    //         return (date2.getTime() - date1.getTime());
                    //         // return str === '1' ? (date2.getTime() - date1.getTime()) : (date1.getTime() - date2.getTime());
                    //     }
                    // });
                    // break;
                    filter = this.requestFilterList;
                    filter.sort((a, b) => {
                        if (a.id && b.id) {
                            return a.id - b.id;
                        }
                    });
                    break;
                case '0':
                    filter.sort((a, b) => {
                        if (a.id && b.id) {
                            return b.id - a.id;
                        }
                    });
                    filter = filter.filter(element => element.requestStatus == 1)
                    this.requestFilterForm.get('sortBy').setValue('');
                    break;
            }
            // if(this.searchValue==false)
            // {
            //     filter = filter.filter(element=>element.requestStatus == 1)
            // }
            this[tableName].data = filter;
        }
        else if (tableName == 'cleaningDataSource') {
            this.cleaningFilterForm.get('sortBy').setValue(Number(str));
            switch (str) {
                case '1':
                    filter = this.cleaningFilterList;
                    filter.sort((a, b) => {
                        if (a.cleaningDate && b.cleaningDate) {
                            const dateParts1: any = a.cleaningDate.split('/');
                            const dateParts2: any = b.cleaningDate.split('/');
                            const date1 = new Date(+dateParts1[2], dateParts1[0] - 1, +dateParts1[1]);
                            const date2 = new Date(+dateParts2[2], dateParts2[0] - 1, +dateParts2[1]);
                            return (date2.getTime() - date1.getTime());
                        }
                    });
                    break;
                case '2':
                    filter = this.cleaningFilterList;
                    filter.sort((a, b) => {
                        if (a.cleaningDate && b.cleaningDate) {
                            const dateParts1: any = a.cleaningDate.split('/');
                            const dateParts2: any = b.cleaningDate.split('/');
                            const date1 = new Date(+dateParts1[2], dateParts1[0] - 1, +dateParts1[1]);
                            const date2 = new Date(+dateParts2[2], dateParts2[0] - 1, +dateParts2[1]);
                            return (date1.getTime() - date2.getTime());
                        }
                    });
                    break;
                case '0':
                    filter.sort((a, b) => {
                        if (a.cleaningDate && b.cleaningDate) {
                            const dateParts1: any = a.cleaningDate.split('/');
                            const dateParts2: any = b.cleaningDate.split('/');
                            const date1 = new Date(+dateParts1[2], dateParts1[0] - 1, +dateParts1[1]);
                            const date2 = new Date(+dateParts2[2], dateParts2[0] - 1, +dateParts2[1]);
                            return (date2.getTime() - date1.getTime());
                        }
                    });
                    filter = filter.filter(element => element.cleaningStatus == 1)
                    this.cleaningFilterForm.get('sortBy').setValue('');
                    break;
            }
            this[tableName].data = filter;
        }
    }

    public viewRequest(request: HouseKeepingRequestModel) {
        const requestListItems: HouseKeepingRequestModel[] =
            this.requestListItems.filter(value => value.houseKeepingCallId === request.houseKeepingCallId);
        const dialogRef = this.dialog.open(ViewRequestComponent, {
            panelClass: 'custom_dialog_container_',
            width: '50%',
            height: '80%',
            data: {
                title: 'NEW REQUEST!',
                requestDetails: requestListItems,
                houseKeepingCategory: this.houseKeepingCategory
            }
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((result: number) => {
                if (result && request.requestStatus !== result) {
                    this.updateRequestStatus(request, Number(result));
                    if (result == 2) {
                        let amenitiesModel: NotificationModel = { url: "/#/services/housekeeping", title: "Amenities Delivered!", message: "Your requested amenities have been delivered to your room. Enjoy your stay!", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", serviceName: "housekeeping", orderNumber: request.houseKeepingCallId };
                        setTimeout(() =>
                            this.restfullServices.trasactionalNotification(amenitiesModel).subscribe(() => {
                            }), 1000)
                    }
                }
            });
    }

    public viewCleanRequest(request: HouseKeepingCleaningModel) {
        const cleaningListItems: HouseKeepingCleaningModel[] =
            this.cleaningList.filter(value => value.id === request.id);
        //console.log(this.cleaningList);
        const dialogRef = this.dialog.open(ViewCleaningRequestComponent, {
            panelClass: 'custom_dialog_container_',
            width: '50%',
            height: '50%',
            data: {
                title: 'NEW REQUEST!',
                requestDetails: cleaningListItems,
                houseKeepingCategory: this.houseKeepingCategory
            }
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((result: number) => {
                if (result && request.cleaningStatus !== result) {
                    this.updateCleaningStatus(request, Number(result));
                    if (result == 2) {
                        let cleaningModel: NotificationModel = { url: "/#/services/housekeeping", title: "Housekeeping service", message: "Your room has been cleaned and is ready for your comfort. Please let us know if there's anything else we can assist you with.", icon: "../../assets/images/OBRLogo.png", vibrate: "[100, 50, 100]", serviceName: "housekeeping", orderNumber: request.id };
                        setTimeout(() =>
                            this.restfullServices.trasactionalNotification(cleaningModel).subscribe(() => {
                            }), 1000)
                    }
                }
            });
    }

    public resetCleaningFilter() {
        this.cleaningFilterForm.reset();
        //this.cleaningSortBy = '0';
        this.applyFilter('0', 'cleaningDataSource');
        sessionStorage.removeItem('housekeeping_cleaning_filter');
    }

    public resetRequestFilter() {
        this.requestFilterForm.reset();
        //this.requestSortBy = '0';
        this.applyFilter('0', 'requestDataSource');
        sessionStorage.removeItem('housekeeping_request_filter');
    }

    public openSortFilter() {
        this.dialog.open(SortFilterComponent, {
            data: {
                options: this.sortFilterConfig,
                datepickers: true,
            },
            autoFocus: false
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe(event => this.filterSelected(event));
    }

    private updateRequestStatus(request: HouseKeepingRequestModel, requestStatus: number) {
        this.globalService.showLoader$.next(true);
        this.restfullServices.updateHouseKeepingRequestStatus(request.houseKeepingCallId, "request", requestStatus)
            .pipe(takeUntil(this['destroy$']))
            .subscribe(() => {
                request.requestStatus = requestStatus;
                this.globalService.houseKeepingRequestCount--;
                this.globalService.showLoader$.next(false);
            }, () => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }

    private updateCleaningStatus(request: HouseKeepingCleaningModel, requestStatus: number) {
        this.globalService.showLoader$.next(true);
        this.restfullServices.updateHouseKeepingRequestStatus(request.id, "clean", requestStatus)
            .pipe(takeUntil(this['destroy$']))
            .subscribe(() => {
                request.cleaningStatus = requestStatus;
                this.globalService.houseKeepingRequestCount--;
                this.globalService.showLoader$.next(false);
            }, () => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }

    private formSubscribe() {
        fillFilterValues.bind(this)('cleaningFilterForm', 'cleaningDataSource', 'cleaningFilterValues');
        fillFilterValues.bind(this)('requestFilterForm', 'requestDataSource', 'requestFilterValues');
    }

    private getFormsValue() {
        this.cleaningDataSource.filterPredicate = (data: any, filter: string): boolean => {
            const searchString = JSON.parse(filter);
            let validRoomNumber = true;
            let validCleaningStatus = true;

            if (searchString.roomNumber) {
                this.searchValue = true;
                validRoomNumber = transformString(data.roomNumber).indexOf(searchString.roomNumber.toLowerCase()) !== -1;
            }
            if (searchString.cleaningStatus) {
                validCleaningStatus = transformString(data.cleaningStatus)
                    .indexOf(searchString.cleaningStatus.toString().toLowerCase()) !== -1;
            }
            return validRoomNumber && validCleaningStatus;
        };
        this.cleaningDataSource.filter = JSON.stringify(this.cleaningFilterValues);

        this.requestDataSource.filterPredicate = (data: any, filter: string): boolean => {
            const searchString = JSON.parse(filter);
            let validRequestRoomNumber = true;
            let validRequestStatus = true;

            if (searchString.requestRoomNumber) {
                this.searchValue = true;
                validRequestRoomNumber = (transformString(data.id).indexOf(searchString.requestRoomNumber.toLowerCase()) !== -1
                    || transformString(data.roomNumber).indexOf(searchString.requestRoomNumber.toLowerCase()) !== -1);
            }
            if (searchString.requestStatus) {
                validRequestStatus = transformString(data.requestStatus)
                    .indexOf(searchString.requestStatus.toString().toLowerCase()) !== -1;
            }
            return validRequestRoomNumber && validRequestStatus;
        };
        this.requestDataSource.filter = JSON.stringify(this.requestFilterValues);
    }

    //Commented for better performance (obselete) 8/11/2023
    // private getHouseKeepingRequest() {
    //     this.globalService.showLoader$.next(true);
    //     this.cleaningList = [];
    //     this.requestList = [];
    //     this.requestListItems = [];
    //     this.restfullServices.getHouseKeepingRequest()
    //         .subscribe(response => {
    //             if(response)
    //             {
    //             this.houseKeepingCategory = response.houseKeepingCategory;
    //             response.houseKeepingRequest.forEach(requestData => {
    //                 const toDate = new Date();
    //                 const ciDate = new Date(requestData.arrival);
    //                 const coDate = new Date(requestData.departure);
    //                 const presentDate = toDate.getDate() + '/' + toDate.getMonth() + '/' + toDate.getFullYear();
    //                 const tomorrowDate = (toDate.getDate() + 1) + '/' + toDate.getMonth() + '/' + toDate.getFullYear();
    //                 const checkInDate = ciDate.getDate() + '/' + ciDate.getMonth() + '/' + ciDate.getFullYear();
    //                 const checkOutDate = coDate.getDate() + '/' + coDate.getMonth() + '/' + coDate.getFullYear();

    //                 if (presentDate === checkInDate) {
    //                     requestData.arrival = 'Today';
    //                 } else if (tomorrowDate === checkInDate) {
    //                     requestData.arrival = 'Tomorrow';
    //                 } else {
    //                     requestData.arrival = this.getGDate(ciDate);
    //                 }

    //                 if (presentDate === checkOutDate) {
    //                     requestData.departure = 'Today';
    //                 } else if (tomorrowDate === checkOutDate) {
    //                     requestData.departure = 'Tomorrow';
    //                 } else {
    //                     requestData.departure = this.getGDate(coDate);
    //                 }
    //                 let reqFound = false;
    //                 requestData.requests.forEach(element => {
    //                     if (element.type === 'clean') {
    //                         const hkDate = new Date(element.cleaningDate);
    //                         const cleaningDate = hkDate.getDate() + '/' + hkDate.getMonth() + '/' + hkDate.getFullYear();
    //                         if (presentDate === cleaningDate) {
    //                             element.cleaningDate = 'Today';
    //                         } else if (tomorrowDate === cleaningDate) {
    //                             element.cleaningDate = 'Tomorrow';
    //                         } else {
    //                             element.cleaningDate = this.getGDate(hkDate);
    //                         }
    //                         const obj: HouseKeepingCleaningModel = {
    //                             id: requestData.id,
    //                             guestId: element.guestId,
    //                             roomNumber: requestData.roomNumber,
    //                             arrival: requestData.arrival,
    //                             departure: requestData.departure,
    //                             cleaningStatus: element.status,
    //                             cleaningDate: element.cleaningDate,
    //                             cleaningTime: element.cleaningTime,
    //                             dateTime: element.dateTime
    //                         };
    //                         this.cleaningList.push(obj);
    //                     }
    //                     if (element.type === 'request' && !reqFound) {
    //                         if (element.status === 1) {
    //                             this.globalService.houseKeepingRequestCount++;
    //                         }
    //                         const objReq: HouseKeepingRequestModel = {
    //                             id: requestData.id,
    //                             guestId: element.guestId,
    //                             roomNumber: requestData.roomNumber,
    //                             arrival: requestData.arrival,
    //                             departure: requestData.departure,
    //                             requestStatus: element.status,
    //                             houseKeepingCallId: element.houseKeepingCallId,
    //                             requestCategory: element.requestCategory,
    //                             requestItem: element.requestItem,
    //                             requestNote: element.requestNote,
    //                             requestQuantity: element.requestQuantity,
    //                             dateTime: element.dateTime
    //                         };
    //                         this.requestList.push(objReq);
    //                         reqFound = true;
    //                     }
    //                     if (element.type === 'request') {
    //                         const objReq: HouseKeepingRequestModel = {
    //                             id: requestData.id,
    //                             guestId: element.guestId,
    //                             roomNumber: requestData.roomNumber,
    //                             arrival: requestData.arrival,
    //                             departure: requestData.departure,
    //                             requestStatus: element.status,
    //                             houseKeepingCallId: element.houseKeepingCallId,
    //                             requestCategory: element.requestCategory,
    //                             requestItem: element.requestItem,
    //                             requestNote: element.requestNote,
    //                             requestQuantity: element.requestQuantity,
    //                             dateTime: element.dateTime
    //                         };
    //                         this.requestListItems.push(objReq);
    //                     }
    //                 });
    //             });
    //             this.cleaningDataSource.data = this.cleaningList;//.filter((item, index, self) => self.indexOf(item) === index);
    //             this.requestDataSource.data = this.requestList;//.filter((item, index, self) => self.indexOf(item) === index);
    //             this.applyFilter('0', 'cleaningDataSource');
    //             this.applyFilter('0', 'requestDataSource');
    //             this.globalService.showLoader$.next(false);
    //         }
    //         }, () => this.globalService.showAlert(this.globalService.errorMsg));

    // }

    //Comment lines end

    private getHouseKeepingRequest() {
        this.globalService.showLoader$.next(true);
        this.cleaningList = [];
        this.requestList = [];
        this.requestListItems = [];
        this.restfullServices.getHouseKeepingRequestNew()
            .subscribe(response => {
                if (response) {
                    this.houseKeepingCategory = response.houseKeepingCategory;
                    this.cleaningList = response.houseKeepingCleaningList;
                    this.requestList = response.houseKeepingAmenityList;
                    this.cleaningFilterList = response.houseKeepingCleaningList;
                    this.requestFilterList = response.houseKeepingAmenityList;
                    this.requestListItems = response.houseKeepingAmenityList;
                    this.cleaningDataSource.data = response.houseKeepingCleaningList;
                    this.requestDataSource.data = response.houseKeepingAmenityList.filter((item, i, arr) => arr.findIndex((t) => t.houseKeepingCallId === item.houseKeepingCallId) === i);
                    this.globalService.houseKeepingRequestCount = response.houseKeepingAmenityList.filter(response => response.requestStatus === 1).length;
                    this.houseKeepingCleaningCount = response.houseKeepingCleaningList.filter(response => response.cleaningStatus === 1).length;

                    let toDate = new Date();
                    this.cleaningList = this.cleaningList.map(element => {
                        let ciDate = new Date(element.arrival);
                        const coDate = new Date(element.departure);
                        const presentDate = toDate.getDate() + '/' + toDate.getMonth() + '/' + toDate.getFullYear();
                        const tomorrowDate = (toDate.getDate() + 1) + '/' + toDate.getMonth() + '/' + toDate.getFullYear();
                        const checkInDate = ciDate.getDate() + '/' + ciDate.getMonth() + '/' + ciDate.getFullYear();
                        const checkOutDate = coDate.getDate() + '/' + coDate.getMonth() + '/' + coDate.getFullYear();
                        const hkDate = new Date(element.cleaningDate);
                        const cleaningDate = hkDate.getDate() + '/' + hkDate.getMonth() + '/' + hkDate.getFullYear();
                        if (presentDate === cleaningDate) {
                            return { ...element, cleaningDate: 'Today' }
                        } else if (tomorrowDate === cleaningDate) {
                            return { ...element, cleaningDate: 'Tomorrow' }
                        } else {
                            return { ...element, cleaningDate: this.getGDate(hkDate) }
                        }
                        return element;
                    })
                    const savedRequestFilter = sessionStorage.getItem('housekeeping_request_filter');
                    const savedCleaningFilter = sessionStorage.getItem('housekeeping_cleaning_filter');
                    if (!savedCleaningFilter ) {
                        this.applyFilter('0', 'cleaningDataSource');
                    }
                    if (!savedRequestFilter ) {
                        this.applyFilter('0', 'requestDataSource');
                    }
                    this.globalService.showLoader$.next(false);
                }
            }, () => this.globalService.showAlert(this.globalService.errorMsg));

    }

    private getGDate(toDate: Date) {
        // return toDate.getDate() + '-' + (this.globalService.monthList[toDate.getMonth()]) + '-' + toDate.getFullYear();
        return ('0' + toDate.getDate().toString()).slice(-2) + '/' + ('0' + (toDate.getMonth() + 1).toString()).slice(-2) + '/' + toDate.getFullYear();
    }
}
