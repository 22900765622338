import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { SelectOptionsModel, ViewRequestDialogModel, WakeUpCallDialogModel, WakeUpCallModel } from 'src/app/shared/models/shared.model';
import { AddWakeupCallComponent } from '../add-wakeup-call/add-wakeup-call.component';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-view-wake-up-call',
  templateUrl: './view-wake-up-call.component.html',
  styleUrls: ['./view-wake-up-call.component.scss']
})
export class ViewWakeUpCallComponent implements OnInit {

  requestData: any;
  requestStatus: string | number;
  requestStatusLabel: string;
  public selectOptions: SelectOptionsModel[] = [
    {
      label: 'Upcoming',
      value: 1
    },
    {
      label: 'Completed',
      value: 2
    },
    {
      label: 'Missed',
      value: 3
    },
    {
      label: 'Failed',
      value: 4
    },
    {
      label: 'Cancelled',
      value: 5
    }

  ];

  constructor(public dialogRef: MatDialogRef<ViewWakeUpCallComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: WakeUpCallDialogModel, private globalService: GlobalServices, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.requestData = this.data.requestDetails;
    this.requestStatus = this.selectOptions.find(x => x.label == this.data.requestDetails.status).value || this.selectOptions[0].label;
   
  }
  onClose() {
    if (this.data.requestDetails.status != this.selectOptions.find(x => x.value == this.requestStatus).label) {
      let wakeUpDetails = {
        id: this.requestData.id,
        status: this.selectOptions.find(x => x.value == this.requestStatus).label
      }
      this.dialogRef.close(wakeUpDetails);
    }
    else
      this.dialogRef.close();
  }
  onDeleteEvent() {
    const str = 'Are you sure you want to delete this request? Once deleted, it cannot be undone.';
    const alertTitle = 'Delete Wake-up Call Request?';
    const dialogDel = this.dialog.open(AlertDialogComponent, {
      panelClass: 'custom_dialog_container',
      width: '440px',
      data: {
        title: alertTitle,
        message: str,
        type: 'confirmDelete'
      }
    });
    dialogDel.afterClosed()
      .pipe(filter(result => !!result))
      .subscribe(() => {
        this.dialogRef.close(true);
      });
    //this.dialogRef.close();
  }
  onSelectionChange(event: any) {

  }
  onEditEvent() {
    //this.dialogRef.close();
    this.dialog.open(AddWakeupCallComponent, {
      panelClass: ['share-link_container', 'border-top'],
      width: '490px',
      //height:'400px',
      autoFocus: true,
      data: {
        requestDetails:this.data.requestDetails,
        isEdit: true
      }
    }).afterClosed()
      .subscribe((resp) => {
        this.globalService.headerClickAction$.next(null);
        if (resp) {
          this.openSnackBar("Wake-up call successfully updated");
        }
        this.dialogRef.close("Success");
      });
  }
  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ['custom-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: {
        svgIcon: 'check-circle',
        message
      }
    });
  }
}
