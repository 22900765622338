import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { isiOS } from 'src/app/shared/functions/helpers';
import { Member } from 'src/app/shared/models/shared.model';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { OrderService } from 'src/app/shared/services/order.service';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
  selector: 'app-check-in-popup',
  templateUrl: './check-in-popup.component.html',
  styleUrls: ['./check-in-popup.component.scss']
})
export class CheckInPopupComponent implements OnInit {
  public primaryGuest: string;
  public miniHotelReservationId: any;

  public paymentForm = new UntypedFormGroup({
    primaryGuest: new UntypedFormControl(''),
    checkIn: new UntypedFormControl(''),
    roomType: new UntypedFormControl(''),
    roomNo: new UntypedFormControl(''),
    guests: new UntypedFormControl(''),
  });

  public bookingNumber: string = "";

  public codeControl = new UntypedFormControl();
  constructor(public globalService: GlobalServices,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<CheckInPopupComponent>,
    public dialog: MatDialog,
    private restfullServices: RestfullServices,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
    this.initializeValues();
  }
  public get isiOS(): boolean {
    return isiOS();
  }
  initializeValues() {
    this.paymentForm.get('primaryGuest').setValue(this.data.data.primaryGuest);
    this.paymentForm.get('primaryGuest').disable();

    this.paymentForm.get('checkIn').setValue(this.datepipe.transform(this.data.data.checkInDate, 'dd/MM/yyyy') + ' - ' + this.datepipe.transform(this.data.data.checkOutDate, 'dd/MM/yyyy'));
    this.paymentForm.get('checkIn').disable();

    this.paymentForm.get('roomType').setValue(this.data.data.room);
    this.paymentForm.get('roomType').disable();

    this.paymentForm.get('roomNo').setValue(this.data.data.roomNo);
    this.paymentForm.get('roomNo').disable();

    this.paymentForm.get('guests').setValue(this.data.data.guests);

    this.bookingNumber = this.data.data.bookingNumber;
  }
  checkInGuest() {
    let payload = {
      guestId: Number(this.activatedRoute.snapshot.queryParams.id),
      bookingId: this.data.data.bookingNumber,
      checkInDate: this.data.data.checkInDate,
      lastName: 'Checked-out',
      email: this.data.data.email
    };
    this.restfullServices.hotelCheckIn(payload)
      .subscribe(response => {
        if (response) {
          // const queryParams: any = {
          //   id: this.globalService.selectedGuestId,
          // };
          // this.route.navigate(['/pages/guests/guest'], { queryParams })

          //Call update minihotel data
          if (this.data.data.sopagoMinihotelInfo != null && this.data.data.sopagoMinihotelInfo != undefined) {

            var member: Member[] = [{
              serial: this.data.data.miniHotelMemberSerial,
              firstName: "",
              lastName: "",
              email: this.data.data.email,
              phone: "",
              idNumber: "",
              roomType: ""
            }]

            var obj = {
              credentials: {
                username: this.data.data.sopagoMinihotelInfo.username,
                password: this.data.data.sopagoMinihotelInfo.password,
                hotelId: this.data.data.sopagoMinihotelInfo.miniHotel_Id
              },
              reservation: {
                header: {
                  status: "IN"
                },
                members: member
              },
              reservationId: this.data.data.miniHotelReservationId
            }
            this.restfullServices.updateReservationToMiniHotel(obj).subscribe((response) => { })
          }
          this.closePopup();
        } else {
          this.globalService.showAlert(this.globalService.errorMsg);
        }
        this.globalService.showLoader$.next(false);
      }, (err) => {
        {
          this.globalService.showAlert(this.globalService.errorMsg);
          this.globalService.showLoader$.next(false);
        }

        //this.globalService.showAlert(this.globalService.errorMsg)
      });
  }
  public closePopup() {
    this.dialogRef.close({ data: 1 });
  }

}
