import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { filter, takeUntil } from 'rxjs/operators';

import { GlobalServices } from '../../shared/services/global.services';

import { HeaderModel, MenuPermissionModel } from '../../shared/models/shared.model';
import { ActivatedRoute } from '@angular/router';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

const sideNavAnimation = trigger('openCloseSidenav', [
    state('open', style({
        width: '193px',
    })),
    state('closed', style({
        width: '65px',
    })),
    transition('open <=> closed', [
        animate('0.15s')
    ]),
]);

const sideNavContainerAnimation = trigger('openCloseSidenavContent', [
    state('open', style({
        'margin-left': '193px',
    })),
    state('closed', style({
        'margin-left': '65px',
    })),
    transition('open <=> closed', [
        animate('0.15s')
    ]),
]);

@Component({
    selector: 'app-hotel',
    templateUrl: './hotel.component.html',
    styleUrls: ['./hotel.component.scss'],
    animations: [sideNavAnimation, sideNavContainerAnimation]
})
export class HotelComponent implements OnInit,AfterViewInit {

    public isExpanded = true;

    public headerData: HeaderModel;

    public generalMenu: MenuPermissionModel[] = [];
    public servicesMenu: MenuPermissionModel[] = [];
    public managementMenu: MenuPermissionModel[] = [];

    constructor(public globalService: GlobalServices, public restfullService: RestfullServices,private cdr: ChangeDetectorRef,
        private changeDetector: ChangeDetectorRef, private activatedRoute: ActivatedRoute) {
        globalService.sidebarState$.subscribe((sidebarState) => this.isExpanded = sidebarState);
    }

    ngOnInit() {
        this.generalMenu = this.globalService.menuPermission
            .filter((key) => key.childMenuName === 'general');
        this.servicesMenu = this.globalService.menuPermission
            .filter((key) => key.childMenuName === 'services');
        this.managementMenu = this.globalService.menuPermission
            .filter((key) => key.childMenuName === 'management');

        this.globalService.headerData$
            .pipe(filter(data => !!data))
            .subscribe(data => {
                this.headerData = data;
                if (!(this.changeDetector as any).destroyed) {
                    this.changeDetector.detectChanges();
                }
            });
        this.setActiveMenu("pages/" + this.activatedRoute.snapshot.firstChild.routeConfig.path, "pages/" + this.activatedRoute.snapshot.firstChild.routeConfig.path, "");
        this.isExpanded = true;
        this.globalService.sidebarState$.next(true);

        this.getOpenCounts();
        this.cdr.detectChanges();

    }
    ngAfterViewInit(){
        this.cdr.detectChanges();
    }

    public getOpenCounts() {  //wakeupcallcount
        this.cdr.detectChanges();
        this.restfullService.getWakeUpCall()
            .subscribe(
                response => {
                    if (response) {
                        this.globalService.wakeupcallCount = response.filter(x => x.status == "Upcoming").length;
                    }
                },
                () => {
                    this.globalService.showAlert(this.globalService.errorMsg);
                });
        //spaandwellnesscount
        this.restfullService.getSpaAndWellnessData()
            .subscribe(
                response => {
                    this.globalService.spaAndWellnessCount = response.spaList.filter(x => x.transactionStatus === "open").length;
                },
                () => this.globalService.showAlert(this.globalService.errorMsg));
        //trasnportationcoun
        this.restfullService.getTransportationData()
        .subscribe(
            response => {
                this.globalService.transportaitonCount = response.transportationList.filter(x => x.transactionStatus === "open").length;
            },
            () => this.globalService.showAlert(this.globalService.errorMsg));
        //F&Bcount
        this.restfullService.getFBCount().subscribe(response => {
            if (response) {
                this.globalService.foodAndBeveragesCount = response.count;
            }
        })
        //HousekeepinCount
        this.restfullService.getHouseKeepingRequestNew()
            .subscribe(response => {
                if (response) {
                    this.globalService.houseKeepingRequestCount = response.houseKeepingAmenityList.filter(response => response.requestStatus === 1).length;
                    var cleaningCount = response.houseKeepingCleaningList.filter(response => response.cleaningStatus === 1).length;
                    this.globalService.houseKeepingCount = this.globalService.houseKeepingRequestCount + cleaningCount;
                }
            })
        //GuestListCount
        this.restfullService.getGuestList()
            .subscribe(
                response => {
                    if (response)
                        this.globalService.guestListCount = response.guestList.filter(element => element?.statusCode === "checkOutInProgress").length;
                },
                () => this.globalService.showAlert(this.globalService.errorMsg));
    }
    public setActiveMenu(pageName: string, menuName: string, childMenu: string) {
        this.globalService.navigateToPage(pageName, menuName, childMenu);
        if (this.globalService.isMobile$.getValue()) {
            this.globalService.sidebarState$.next(false);
        }
    }

    public switchToggle() {
        this.globalService.sidebarState$.next(!this.isExpanded);
    }
}
