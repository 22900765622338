import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { filter, takeUntil } from 'rxjs/operators';

import { GlobalServices } from '../../shared/services/global.services';
import { RestfullServices } from '../../shared/services/restfull.services';
import { AutoUnsubscribe } from '../../shared/functions/autounsubscribe';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe()
export class LoginComponent implements OnInit, OnDestroy {

    public form: UntypedFormGroup;
    public showPassword: boolean;

    constructor(
        private globalService: GlobalServices,
        private restfullServices: RestfullServices,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            emailAddress: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            password: [null, [Validators.required]]
        });
        this.showPassword = false;
    }

    ngOnDestroy() { }

    public togglePassword() {
        this.showPassword = !this.showPassword;
    }

    public login() {
        if (this.form.valid) {
            // console.log(this.form.value);
            this.globalService.showLoader$.next(true);
            this.restfullServices.loginHotelAdmin(this.form.value.emailAddress, this.form.value.password)
                .toPromise()
                .then(response => {
                    this.globalService.showLoader$.next(false);
                    if (response.hotelStatus == 'inactive') {
                        const dialogRef = this.dialog.open(AlertDialogComponent, {
                            panelClass: "custom_dialog_container",
                            width: "440px",
                            data: {
                                type: "inActiveHotel",
                            },
                        });
                        return
                    }
                    if (response.login) {
                        this.mapResponse(response);
                        this.globalService.createToken();
                    } else {
                        this.globalService.showAlert('Invalid credentials, please try again!');
                    }
                }, () => this.globalService.showAlert(this.globalService.errorMsg));
        }
    }

    private mapResponse(response) {
        this.globalService.HOTELID = response.login.hotelId;
        this.globalService.currency = response.currency;
        this.restfullServices.setLocalStorage("loggedInHotelDetails", JSON.stringify(response.login.hotelId));
        this.restfullServices.setLocalStorage("loggedInHotelCurrency", JSON.stringify(response.currency));
        //Guided tour flags
        this.restfullServices.setLocalStorage("isTourRunning", "true");
        this.restfullServices.setLocalStorage('guidedTourGuestFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourEditGuestFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourChatFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourHousekeepingFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourF&BFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourOrdersFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourAddReservationFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourAddOrderFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourReservationFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourSpaFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourAddEventFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourSpaCalendarFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourWakeUpFlag', 'false');
        this.restfullServices.setLocalStorage('guidedTourAddWakeUpFlag', 'false');
        //Guided tour flags end
        this.restfullServices.setLocalStorage("IsFirstLogin", JSON.stringify(response.login.isFirstLogin))
        this.globalService.userModel = {
            id: response.login.id,
            emailId: response.login.emailId,
            groupCode: response.login.userGroup
        };

        localStorage.setItem('email', response.login.emailId);
        localStorage.setItem('groupCode', response.login.userGroup);

        this.globalService.houseKeepingRequestCount = response.houseKeepingRequestCount;

        // console.log(this.globalService.userModel);
        // console.log(this.globalService.menuPermission);

        // TODO: Torben change config

        response.menuPermission = [
            {
                id: 1,
                userGroupId: 10,
                menuId: 1,
                menuCode: 'pages/dashboard',
                menuName: 'Dashboard',
                childMenuName: 'general',
                svgIcon: 'dashboard',
                isInternal: 0
            },
            {
                id: 2,
                userGroupId: 10,
                menuId: 2,
                menuCode: 'pages/guests',
                menuName: 'Guest List',
                childMenuName: 'general',
                svgIcon: 'user',
                isInternal: 0
            },
            {
                id: 3,
                userGroupId: 10,
                menuId: 3,
                menuCode: 'pages/chat',
                menuName: 'Chat',
                childMenuName: 'general',
                svgIcon: 'customer-service',
                isInternal: 0
            },
            {
                id: 4,
                userGroupId: 10,
                menuId: 4,
                menuCode: 'housekeeping',
                menuName: 'Housekeeping',
                childMenuName: 'services',
                svgIcon: 'broom',
                isInternal: 0
            },
            {
                id: 5,
                userGroupId: 10,
                menuId: 5,
                menuCode: 'food-beverages',
                menuName: 'Bars & Restaurants',
                childMenuName: 'services',
                svgIcon: 'service-toolbox',
                isInternal: 0
            },
            {
                id: 6,
                userGroupId: 10,
                menuId: 6,
                menuCode: 'spa-wellness',
                menuName: 'Spa & Wellness',
                childMenuName: 'services',
                svgIcon: 'spa',
                isInternal: 0
            },
            {
                id: 7,
                userGroupId: 10,
                menuId: 7,
                menuCode: 'calender',
                menuName: 'Calender',
                childMenuName: 'management',
                svgIcon: 'calendar',
                isInternal: 0
            },
            {
                id: 8,
                userGroupId: 10,
                menuId: 8,
                menuCode: 'my-team',
                menuName: 'My Team',
                childMenuName: 'management',
                svgIcon: 'user-group',
                isInternal: 0
            },
            {
                id: 10,
                userGroupId: 10,
                menuId: 10,
                menuCode: 'maintainence',
                menuName: 'Maintainence',
                childMenuName: 'management',
                svgIcon: 'service-toolbox',
                isInternal: 0
            },
            {
                id: 11,
                userGroupId: 10,
                menuId: 11,
                menuCode: 'reports',
                menuName: 'Reports',
                childMenuName: 'management',
                svgIcon: 'reports',
                isInternal: 0
            },
            {
                id: 19,
                userGroupId: 10,
                menuId: 14,
                menuCode: "wake-up-call",
                menuName: "Wake-Up call",
                childMenuName: "services",
                svgIcon: "wake-up",
                //activeIconName: "./assets/images/menu_spa_wellness_active.png",
                isInternal: 0
            },
            {
                id: 20,
                userGroupId: 10,
                menuId: 12,
                menuCode: 'transportation',
                menuName: 'Transportation',
                childMenuName: 'services',
                svgIcon: 'cab',
                isInternal: 0
            },
            {
                id: 21,
                userGroupId: 10,
                menuId: 12,
                menuCode: 'kids-club',
                menuName: 'Kids Club ',
                childMenuName: 'services',
                svgIcon: 'baby-face-outline',
                isInternal: 0
            },
        ];

        this.globalService.menuPermission = response.menuPermission;

        this.globalService.navigateToPage(response.menuPermission[1].menuCode, response.menuPermission[1].menuCode, '');
    }
}
