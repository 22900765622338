<div id="hotel-page">
    <mat-sidenav-container class="navigation-container" autosize *ngIf="!globalService.isMobile$.getValue()">
        <mat-sidenav [@openCloseSidenav]="globalService.sidebarState$.getValue() ? 'open' : 'closed'" opened
            role="navigation" class="navigation-sidenav" [class.navigation-sidenav-opened]="isExpanded"
            [mode]="(globalService.isMobile$ | async) ? 'over' : 'side'"
            (closed)="globalService.sidebarState$.next(false)">
            <mat-nav-list>
                <mat-list-item class="logo-item">
                    <mat-icon [svgIcon]="'logo'"></mat-icon>
                    <span *ngIf="isExpanded">SOPAGO</span>
                </mat-list-item>
                <!-- general menu list -->
                <div *ngFor="let menu of generalMenu; let i = index">
                    <div *ngIf="menu?.childMenuName == 'general' && !i" class="menu_general">
                        <span class="menu_sub_title">{{isExpanded ? 'GENERAL' : '...'}}</span>
                    </div>
                    <mat-list-item *ngIf="menu?.childMenuName == 'general' && !menu.isInternal"
                        (click)="setActiveMenu(menu.menuCode, menu.menuCode, '')"
                        [ngClass]="(globalService.selectedMenu.indexOf(menu.menuCode)>=0 ? 'active' : '')+' '+(menu?.menuName =='Dashboard'?'opac':'')"
                        [ngStyle]="{'display':menu.isActive == 1 ?'':'none'}">
                        <mat-icon [svgIcon]="menu?.svgIcon" [ngClass]="menu?.menuCode"
                            [class.selected]="globalService.selectedMenu.indexOf(menu.menuCode)>=0">
                        </mat-icon>

                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                            style="margin-left: 4px;">
                            <span id="step-2_{{menu?.menuName}}">{{menu?.menuName}}</span>
                            <span *ngIf="globalService?.guestListCount > 0 && menu?.menuName == 'Guest List'"
                                fxLayoutAlign="center center" class="request_count">{{globalService?.guestListCount}}
                            </span>
                        </span>
                        <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span *ngIf="globalService?.guestListCount > 0 && menu?.menuName == 'Guest List'"
                                fxLayoutAlign="center center" class="request_count ">{{globalService?.guestListCount}}
                            </span>
                        </span>


                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                            style="margin-left: 4px;">
                            <span *ngIf="globalService?.guestChatRequestCount > 0 && menu?.menuName == 'Chat'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.guestChatRequestCount}}
                            </span>
                        </span>

                        <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span *ngIf="globalService?.guestChatRequestCount > 0 && menu?.menuName == 'Chat'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.guestChatRequestCount}}
                            </span>
                        </span>


                    </mat-list-item>
                </div>

                <!-- services menu list -->
                <div *ngFor="let menu of servicesMenu; let indexOfelement = index">
                    <div *ngIf="menu?.childMenuName == 'services' && !indexOfelement" class="menu_general">
                        <span class="menu_sub_title">{{isExpanded ? 'SERVICES' : '...'}}</span>
                    </div>
                    <mat-list-item *ngIf="menu?.childMenuName == 'services' && !menu?.isInternal"
                        (click)="setActiveMenu(menu?.menuCode, menu?.menuCode, '')"
                        [ngClass]="globalService.selectedMenu.indexOf(menu.menuCode)>=0 ? 'active' : '' "
                        [ngStyle]="{'display':menu.isActive == 1 ?'':'none'}">

                        <mat-icon [svgIcon]="menu?.svgIcon" [ngClass]="menu?.menuCode"
                            [class.selected]="globalService.selectedMenu.indexOf(menu.menuCode)>=0"></mat-icon>


                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                            style="margin-left: 4px;">
                            <span id="step-5_{{menu?.menuName}}">{{menu?.menuName}}</span>
                            <span
                                *ngIf="globalService?.houseKeepingCount > 0 && menu?.menuName == 'Housekeeping'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.houseKeepingCount}}
                            </span>
                        </span>

                        <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span
                                *ngIf="globalService?.houseKeepingCount > 0 && menu?.menuName == 'Housekeeping'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.houseKeepingCount}}
                            </span>
                        </span>

                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                            style="margin-left: 4px;">
                            <span
                                *ngIf="globalService?.foodAndBeveragesCount > 0 && menu?.menuName == 'Bars & Restaurants'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.foodAndBeveragesCount}}
                            </span>
                        </span>

                        <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span
                                *ngIf="globalService?.foodAndBeveragesCount > 0 && menu?.menuName == 'Bars & Restaurants'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.foodAndBeveragesCount}}
                            </span>
                        </span>

                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                            style="margin-left: 4px;">
                            <span *ngIf="globalService?.spaAndWellnessCount > 0 && menu?.menuName == 'Spa & Wellness'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.spaAndWellnessCount}}
                            </span>
                        </span>

                        <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span *ngIf="globalService?.spaAndWellnessCount > 0 && menu?.menuName == 'Spa & Wellness'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService?.spaAndWellnessCount}}
                            </span>
                        </span>

                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                        style="margin-left: 4px;">
                        <span *ngIf="globalService?.transportaitonCount > 0 && menu?.menuName == 'Transportation'"
                            fxLayoutAlign="center center"
                            class="request_count">{{globalService?.transportaitonCount}}
                        </span>
                    </span>

                    <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                        <span *ngIf="globalService?.transportaitonCount > 0 && menu?.menuName == 'Transportation'"
                            fxLayoutAlign="center center"
                            class="request_count">{{globalService?.transportaitonCount}}
                        </span>
                    </span>


                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                        style="margin-left: 4px;">
                        <span *ngIf="globalService?.wakeupcallCount > 0 && menu?.menuName == 'Wake-Up call'"
                            fxLayoutAlign="center center"
                            class="request_count">{{globalService?.wakeupcallCount}}
                        </span>
                    </span>

                    <span *ngIf="!isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                        <span *ngIf="globalService?.wakeupcallCount > 0 && menu?.menuName == 'Wake-Up call'"
                            fxLayoutAlign="center center"
                            class="request_count">{{globalService?.wakeupcallCount}}
                        </span>
                    </span>

                    </mat-list-item>
                </div>
                <!-- management menu list -->
                <div *ngFor="let menu of managementMenu; let indexOfelement = index" style="opacity: 34%;">
                    <div *ngIf="menu?.childMenuName == 'management' && !indexOfelement" class="menu_general">
                        <span class="menu_sub_title">{{isExpanded ? 'MANAGEMENT' : '...'}}</span>
                    </div>
                    <mat-list-item *ngIf="menu?.childMenuName == 'management' && !menu.isInternal"
                        (click)="setActiveMenu(menu?.menuCode, menu?.menuCode, '')"
                        [ngClass]="globalService.selectedMenu.indexOf(menu.menuCode)>=0 ? 'active' : '' "
                        style="opacity: 0.3;pointer-events: none;">
                        <mat-icon [svgIcon]="menu?.svgIcon" [ngClass]="menu?.menuCode"
                            [class.selected]="globalService.selectedMenu.indexOf(menu.menuCode)>=0"></mat-icon>
                        <span *ngIf="isExpanded">{{menu?.menuName}}</span>
                    </mat-list-item>
                </div>
            </mat-nav-list>
            <div class="fixed_menu">
                <mat-list-item (click)="switchToggle()" disableRipple>
                    <mat-icon [svgIcon]="'toggle'" [style]="isExpanded ? 'transform: rotate(180deg)' : ''"></mat-icon>
                    <span class="no_margin" *ngIf="isExpanded">Toggle Sidebar</span>
                </mat-list-item>
            </div>
            <!-- <div *ngIf="globalService.showAddGuestPage" class="overlay"></div> -->
        </mat-sidenav>
        <mat-sidenav-content [@openCloseSidenavContent]="globalService.sidebarState$.getValue() ? 'open' : 'closed'">
            <app-header [headerData]="headerData"></app-header>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <!-- mobile view side menu -->
    <mat-sidenav-container class="navigation-container" autosize [hasBackdrop]="true"
        *ngIf="globalService.isMobile$.getValue()">
        <mat-sidenav [opened]="globalService.sidebarState$.getValue()" role="navigation" class="navigation-sidenav"
            [class.navigation-sidenav-opened]="isExpanded" [mode]="(globalService.isMobile$ | async) ? 'over' : 'side'"
            (closed)="globalService.sidebarState$.next(false)">
            <mat-nav-list>
                <mat-list-item class="logo-item">
                    <mat-icon [svgIcon]="'logo'"></mat-icon>
                    <span *ngIf="isExpanded">SOPAGO</span>
                </mat-list-item>
                <!-- general menu list -->
                <div *ngFor="let menu of generalMenu; let i = index">
                    <div *ngIf="menu.childMenuName == 'general' && !i" class="menu_general">
                        <span class="menu_sub_title">{{isExpanded ? 'GENERAL' : '...'}}</span>
                    </div>
                    <mat-list-item *ngIf="menu.childMenuName == 'general' && !menu.isInternal"
                        (click)="setActiveMenu(menu.menuCode, menu.menuCode, '')"
                        [ngClass]="(globalService.selectedMenu.indexOf(menu.menuCode)>=0 ? 'active' : '')+' '+(menu?.menuName =='Dashboard'?'opac':'')"
                        [ngStyle]="{'display':menu.isActive == 1 ?'':'none'}" >
                        <mat-icon [svgIcon]="menu.svgIcon" [ngClass]="menu.menuCode"
                            [class.selected]="globalService.selectedMenu.indexOf(menu.menuCode)>=0"></mat-icon>

                        <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span>{{menu.menuName}}</span>
                            <span *ngIf="globalService.guestChatRequestCount > 0 && menu.menuName == 'Chat'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService.guestChatRequestCount}}
                            </span>
                        </span>

                    </mat-list-item>
                </div>

                <!-- services menu list -->
                <div *ngFor="let menu of servicesMenu; let indexOfelement = index">
                    <div *ngIf="menu.childMenuName == 'services' && !indexOfelement" class="menu_general">
                        <span class="menu_sub_title">{{isExpanded ? 'SERVICES' : '...'}}</span>
                    </div>
                    <mat-list-item *ngIf="menu.childMenuName == 'services' && !menu.isInternal"
                        (click)="setActiveMenu(menu.menuCode, menu.menuCode, '')"
                        [ngClass]="globalService.selectedMenu.indexOf(menu.menuCode)>=0 ? 'active' : '' "
                        [ngStyle]="{'display':menu.isActive == 1 ?'':'none'}">
                       
                        <mat-icon [svgIcon]="menu.svgIcon" [ngClass]="menu.menuCode"
                            [class.selected]="globalService.selectedMenu.indexOf(menu.menuCode)>=0"></mat-icon>                            
                            <span *ngIf="isExpanded" fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px">
                            <span>{{menu.menuName}}</span>
                            <span *ngIf="globalService.houseKeepingRequestCount > 0 && menu.name == 'Housekeeping'"
                                fxLayoutAlign="center center"
                                class="request_count">{{globalService.houseKeepingRequestCount}}
                            </span>
                        </span>
                    </mat-list-item>
                </div>

                <!-- management menu list -->
                <div *ngFor="let menu of managementMenu; let indexOfelement = index">
                    <div *ngIf="menu.childMenuName == 'management' && !indexOfelement" class="menu_general">
                        <span class="menu_sub_title">{{isExpanded ? 'MANAGEMENT' : '...'}}</span>
                    </div>
                    <mat-list-item *ngIf="menu.childMenuName == 'management' && !menu.isInternal"
                        (click)="setActiveMenu(menu.menuCode, menu.menuCode, '')"
                        [ngClass]="globalService.selectedMenu.indexOf(menu.menuCode)>=0 ? 'active' : '' "
                        style="opacity: 0.3;pointer-events: none;">
                        <mat-icon [svgIcon]="menu.svgIcon" [ngClass]="menu.menuCode"
                            [class.selected]="globalService.selectedMenu.indexOf(menu.menuCode)>=0"></mat-icon>
                        <span *ngIf="isExpanded">{{menu.menuName}}</span>
                    </mat-list-item>
                </div>
            </mat-nav-list>
            <mat-nav-list></mat-nav-list>
            <!-- <div *ngIf="globalService.showAddGuestPage" class="overlay"></div> -->
        </mat-sidenav>
        <mat-sidenav-content>
            <app-header [headerData]="headerData"></app-header>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<app-loader></app-loader>