import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import firebase from 'firebase/compat/app';

import { DeviceDetectorService } from 'ngx-device-detector';

import { takeUntil } from 'rxjs/operators';

import { GlobalServices } from '../../shared/services/global.services';
import { RestfullServices } from '../../shared/services/restfull.services';

import { ChatGuestListModel } from '../../shared/models/shared.model';
import { AutoUnsubscribe } from '../../shared/functions/autounsubscribe';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
@AutoUnsubscribe()
export class ChatComponent implements OnInit, OnDestroy {
    @ViewChild('chatcontent') chatcontent: ElementRef;
    public guestList: ChatGuestListModel[];
    public selectedChat: string;

    public data = {roomname: '', message: '', type: '', guestname: '', key: '', messageCount: 0, chatStatus: null}; // TODO

    get isMobileDisplay() {
        return !this.deviceDetectorService.isDesktop();
    }

    constructor(
        public dialog: MatDialog,
        public globalService: GlobalServices,
        public restfullServices: RestfullServices,
        public deviceDetectorService: DeviceDetectorService,
    ) {
    }

    ngOnInit(): void {
        this.globalService.headerData$.next({
            headerTitle: 'Chat',
            showBackButton: false
        });
        this.data.type = 'message';

        this.getChatGuestList();
    }

    ngOnDestroy() {
    }

    private getChatGuestList() {
        this.guestList = [];
        this.globalService.showLoader$.next(true);
        this.restfullServices.getChatGuestList()
            .pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                response.forEach(element => {
                    element.checkInDate = new Date(element.checkInDate);
                    element.checkOutDate = new Date(element.checkOutDate);
                });
                this.guestList = response;
                // this.globalService.showLoader$.next(false);
            }, () => this.globalService.showAlert(this.globalService.errorMsg));
    }

    public openChat(room) {
        this.selectedChat = room?.roomname;
        this.data = {
            ...this.data,
            roomname: room?.roomname,
            guestname: room?.guestname,
            chatStatus: room?.status,
            key: room?.key,
            messageCount: room?.unreadGuestMessages ? Number(room.unreadGuestMessages) : 0,
            message: ''
        };
        if (!room) {
            this.globalService.headerData$.next({
                headerTitle: 'Chat',
                showBackButton: false
            });
            return;
        }

        firebase.database().ref(`${room.key}/unreadAdminMessages`).set(0);
    }
}
