import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { filter, takeUntil } from 'rxjs/operators';

import { GlobalServices } from '../../shared/services/global.services';
import { RestfullServices } from '../../shared/services/restfull.services';

import {
    GuestAppActivityModel,
    GuestListModel,
    GuestStatusModel,
    SortFilterConfigModel
} from '../../shared/models/shared.model';

import { fillFilterValues, transformString } from '../../shared/functions/helpers';
import { SortFilterComponent } from '../../shared/components/sort-filter/sort-filter.component';
import { ShareLinkComponent } from './share-link/share-link.component';
import { AutoUnsubscribe } from '../../shared/functions/autounsubscribe';

import * as introJs from 'intro.js';

@Component({
    selector: 'app-guest',
    templateUrl: './guest.component.html',
    styleUrls: ['./guest.component.scss']
})
@AutoUnsubscribe()
export class GuestComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('guestListDataPaginator') set guestListDataPaginator(pager: MatPaginator) {
        if (pager) this.dataSource.paginator = pager;
    }
    searchValue: boolean = false;
    public guestStatus: GuestStatusModel[];
    public guestAppActivity: GuestAppActivityModel[];
    private guestList: GuestListModel[];
    public dataSource = new MatTableDataSource();
    public displayedColumns: string[] = ['bookingNumber', 'firstName', 'roomNumber', 'checkInDate', 'checkOutDate', 'nights', 'status', 'appActivity', 'action'];
    
    public filterForm = new UntypedFormGroup({
        name: new UntypedFormControl(),
        checkInDate: new UntypedFormControl(),
        checkOutDate: new UntypedFormControl(),
        status: new UntypedFormControl(),
        appActivity: new UntypedFormControl(),
    });

    public sortFilterConfig: SortFilterConfigModel[] = [
        {
            label: 'Datum',
            mode: 'radio',
            items: [
                {
                    label: 'Last 7 Days',
                    checked: false,
                },
                {
                    label: 'Last Month',
                    checked: false
                },
                {
                    label: 'Custom',
                    checked: false
                }
            ]
        },
        {
            label: 'Status',
            mode: 'checkbox',
            items: [
                {
                    label: 'Paid',
                    checked: false
                },
                {
                    label: 'Unpaid',
                    checked: false
                },
                {
                    label: 'Cancelled',
                    checked: false
                },
            ]
        }
    ];

    private filterValues = {
        name: '',
        checkInDate: '',
        checkOutDate: '',
        status: '',
        appActivity:''
    };


    public get status() {
        return this.filterForm.get('status');
    }
    public get appActivity() {
        return this.filterForm.get('appActivity');
    }

    public get isValueInFilterForm(): boolean {
        return !!Object.keys(this.filterForm.value).find(item => !!this.filterForm.value[item]);
    }


    introJS = introJs.default();


    constructor(
        public globalService: GlobalServices,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private route: Router,
        private restfullServices: RestfullServices,
        private cdr: ChangeDetectorRef
    ) {
    }




    ngOnInit() {
        this.globalService.headerData$.next({
            headerTitle: 'Guest list',
            showBackButton: false
        });
        this.globalService.guestListCount = 0;
        // this.getGuestList();
        // this.formSubscribe();
        // this.getFormsValue();

        const savedFilterValues = this.restfullServices.getSessionStorage('guestListFilterValues');
        if (savedFilterValues) {
            const parsedFilterValues = JSON.parse(savedFilterValues);
            Object.keys(parsedFilterValues).forEach(key => {
                if (parsedFilterValues[key]) {
                    this.filterForm.get(key).setValue(parsedFilterValues[key]);
                }
            });
            this.filterValues = parsedFilterValues;
        }

        if (this.globalService.isMobile$.getValue()) {
            this.displayedColumns = ['bookingNumber', 'firstName', 'status', 'appActivity', 'action'];
        }
    }

    startTour() {
        this.introJS.setOptions({
            steps: [
                {
                    // Step 1 Model

                    tooltipClass: "intro-step-1",
                    intro: "<div class='intro-banner'><img src='assets/images/png/Hotel Frontend-2x.png'></div>" +
                        "<div class='step1_title'>Welcome to Hotel Frontend!</div>" +
                        "<p>"
                        + "Your gateway to seamless guest management! Explore seamless operations and elevated hospitality. Dive in by taking a quick tour to unlock all the features and elevate your management experience!" +
                        "</p>"
                        +
                        // "<div class='btnPart'><a class='intro-skip'>Skip tour</a><a class='intro-start'>Start Tour Now</a><div>",
                        "<div class='btnPart'><a class='intro-skip'>Skip tour</a><div>",
                },
                {
                    //Step 2

                    tooltipClass: "intro-step-2",
                    title: "GUEST LIST",
                    element: document.getElementById('step-2_Guest List'),
                    intro: "Effortlessly handle your guests by entering their details in this section by providing information like names, contact details, and check-in/check-out dates.",
                    position: 'right'
                },

                {
                    //Step 3
                    tooltipClass: "intro-step-3",
                    title: "ADD NEW GUEST",
                    element: document.getElementById('add_guest'),
                    intro: "Click on ‘+ Add Guest’ to add a new guest, grant access, and invite them to use the hotel’s exclusive guest app.",
                    position: 'right',
                    nextToDone: true
                }
            ],

            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip',
        });

        this.introJS.start();
        this.introJS.onbeforechange((targetElement) => {
            this.introJS.refresh();
        });

        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let SkipButton = document.querySelector(".intro-skip");
                SkipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }

    skipTour(): void {
        this.introJS.exit();
        this.restfullServices.removeLocalStorage('isTourRunning');
        window.location.href = '#/pages/guests';
    }

    closeStep(): void {
        window.location.href = '#/pages/guests';
    }


    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.guestListDataPaginator;
        setTimeout(() => {

            if (!this.globalService.isMobile$.getValue()) {
                const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                const guidedTourGuestFlag = this.restfullServices.getLocalStorage('guidedTourGuestFlag');
                if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourGuestFlag == 'false') {
                    this.restfullServices.setLocalStorage('guidedTourGuestFlag', 'true');
                    this.startTour();
                    this.cdr.detectChanges();
                }
            }
        }, 500);

        this.getGuestList();
        this.formSubscribe();
        this.getFormsValue();
    }


    public openGuestForm() {
        this.route.navigate(['/pages/guests/add-guest']);
    }

    public openSortFilter() {
        this.dialog.open(SortFilterComponent, {
            data: {
                options: this.sortFilterConfig
            },
            autoFocus: false
        });
    }

    public openGuestDetail(guestData: GuestListModel, shareLink?: boolean) {
        this.globalService.selectedGuestId = guestData.id;
        this.globalService.selectedGuestName = guestData.lastName + ', ' + guestData.firstName;

        const queryParams: any = {
            id: guestData.id,
        };
        if (shareLink) {
            queryParams.shareLink = true;
        }
        this.route.navigate(['/pages/guests/guest'], { queryParams });
    }

    public openGuestDetailManage(guestData: GuestListModel, shareLink?: boolean) {
        if (guestData.appActivity === "Manage") {
            this.globalService.selectedGuestId = guestData.id;
            this.globalService.selectedGuestName = guestData.lastName + ', ' + guestData.firstName;

            const queryParams: any = {
                id: guestData.id,
            };
            if (shareLink) {
                queryParams.shareLink = true;
            }
            this.route.navigate(['/pages/guests/guest'], { queryParams });
        }
    }

    public resetFilter() {
        this.searchValue = false;
        this.filterForm.reset();
        this.filterValues = {
            name: '',
            checkInDate: '',
            checkOutDate: '',
            status: '',
            appActivity:''
        };
        this.dataSource.filter = JSON.stringify(this.filterValues);
        this.restfullServices.removeSessionStorage('guestListFilterValues');
    }

    public openShareLink(guestId: number, appActivity: string) {
        if (appActivity === 'Manage') {
            this.restfullServices.getGuestDetails(guestId)
                .pipe(takeUntil(this['destroy$']))
                .subscribe(response => {

                    // response.guests = [
                    //     {
                    //         firstName: 'Josefina Magdalene',
                    //         lastName: 'Erlinger',
                    //         age: 30,
                    //         email: 'test@gmail.com',
                    //         controlNo: 1,
                    //         appActivity: 'Send Link',
                    //         appActivityCode: 'sendLink'
                    //     },
                    //     {
                    //         firstName: 'Marie',
                    //         lastName: 'Erlinger',
                    //         age: 34,
                    //         email: 'test@gmail.com',
                    //         controlNo: 1,
                    //         appActivity: 'Active',
                    //         appActivityCode: 'active'
                    //     },
                    //     {
                    //         firstName: 'Gerry',
                    //         lastName: 'Erlinger',
                    //         age: 8,
                    //         email: 'test@gmail.com',
                    //         controlNo: 1,
                    //         appActivity: 'Inactive',
                    //         appActivityCode: 'inactive'
                    //     },
                    // ];

                    this.dialog.open(ShareLinkComponent, {
                        panelClass: ['share-link_container', 'border-top'],
                        width: '440px',
                        data: {
                            guests: [
                                // {
                                //     names: response.lastName + ' ' + response.firstName,
                                //     email: response.email
                                // },
                                ...response.guests.map(guest => ({
                                    names: guest.lastName + ' ' + guest.firstName,
                                    email: guest.email
                                }))
                            ],
                            type: 'confirmDelete'
                        }
                    }).afterClosed().pipe(filter(resp => !resp))
                        .pipe(takeUntil(this['destroy$']))
                        .subscribe();
                });
        }
    }

    ngOnDestroy() {
        this.dataSource.data = [];
        const currentFilterValues = {
            name: this.filterForm.get('name').value,
            checkInDate: this.filterForm.get('checkInDate').value,
            checkOutDate: this.filterForm.get('checkOutDate').value,
            status: this.filterForm.get('status').value,
            appActivity: this.filterForm.get('appActivity').value
        };
        this.restfullServices.setSessionStorage('guestListFilterValues', JSON.stringify(currentFilterValues));
    }


    private formSubscribe() {
        fillFilterValues.bind(this)('filterForm', 'dataSource', 'filterValues');
        this.filterForm.valueChanges
            .pipe(takeUntil(this['destroy$']))
            .subscribe(() => {
                const currentFilterValues = {
                    name: this.filterForm.get('name').value,
                    checkInDate: this.filterForm.get('checkInDate').value,
                    checkOutDate: this.filterForm.get('checkOutDate').value,
                    status: this.filterForm.get('status').value,
                    appActivity: this.filterForm.get('appActivity').value,
                };
                this.restfullServices.setSessionStorage('guestListFilterValues', JSON.stringify(currentFilterValues));
            });
    }

    private getFormsValue() {
        this.dataSource.filterPredicate = (data: any, filterPhrase: string): boolean => {
            const searchString = JSON.parse(filterPhrase);
            const config = {
                isCheckInMatch: true,
                isCheckOutMatch: true
            };

            let isNameMatch = true;
            let isStatusMatch = true;
            let isAppActivityMatch = true;

            const transformDate = (property, isMatch) => {
                const sDate = new Date(searchString[property]);
                const cDate = new Date(data[property]);
                const searchDate = sDate.getDate() + '/' + (sDate.getMonth() + 1) + '/' + sDate.getFullYear();
                const checkDate = cDate.getDate() + '/' + (cDate.getMonth() + 1) + '/' + cDate.getFullYear();
                config[isMatch] = this.process(checkDate) >= this.process(searchDate);
            };

            if (searchString.checkInDate) {
                transformDate('checkInDate', 'isCheckInMatch');
            }

            if (searchString.checkOutDate) {
                transformDate('checkOutDate', 'isCheckOutMatch');
            }

            if (searchString.name) {
                this.searchValue = true;
                isNameMatch = (transformString(data.firstName).indexOf(searchString.name.toLowerCase()) !== -1 ||
                    transformString(data.lastName).indexOf(searchString.name.toLowerCase()) !== -1 ||
                    transformString(data.bookingNumber).indexOf(searchString.name.toLowerCase()) !== -1);
            }

            if (searchString.status) {
                isStatusMatch = data.status.toString().trim().toLowerCase().indexOf(searchString.status.toLowerCase()) !== -1;
            }

            if (searchString.appActivity) {
                isAppActivityMatch = data.appActivity.toString().trim().toLowerCase().indexOf(searchString.appActivity.toLowerCase()) !== -1;
            }

            return isNameMatch && isStatusMatch && isAppActivityMatch && config.isCheckInMatch && config.isCheckOutMatch;
        };
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    private process(date) {
        const parts = date.split('/');
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    private getGuestList() {
        this.guestList = [];
        this.guestStatus = [];
        this.guestAppActivity = [];
        this.globalService.showLoader$.next(true);
        this.restfullServices.getGuestList()
            .pipe(takeUntil(this['destroy$']))
            .subscribe(
                response => {
                    this.mapGuestStatusActivity(response);
                    this.globalService.showLoader$.next(false);
                },
                () => this.globalService.showAlert(this.globalService.errorMsg));
    }

    private mapGuestStatusActivity(response) {
        this.guestStatus = response.guestStatus;
        this.sortFilterConfig[1].items = this.guestStatus.map(item => ({ label: item.name, checked: false }));
        this.guestAppActivity = response.guestAppActivity;
        response.guestList.forEach((element, index) => {
            element.checkInDate = new Date(element.checkInDate);
            element.checkOutDate = new Date(element.checkOutDate);

            if (element?.statusCode === "checkOutInProgress") {
                // this.globalService.guestListCount++;
            }

            response.guestStatus.map(fValue => {
                if (fValue.code === element.statusCode) {
                    element.status = fValue.name;
                }
            });
            response.guestAppActivity.map(fValue => {
                if (fValue.code === element.appActivityCode) {
                    element.appActivity = fValue.name;
                }
            });
        });
        this.guestList = response.guestList;
        this.dataSource.data = this.guestList;
        // for (let i = 0; i < this.guestList.length; i++) {
        //     console.log(this.guestList[i].status, 'status');

        //     if (this.guestList[i].status === "Check out in progress ") {
        //         console.log('amit');
        //         //this.globalService.guestListCount++;
        //     }
        // }
        // rooms.forEach((element, index) => {
        //     if (element.chats && element.chats.length > 0) {
        //         if (element.unreadAdminMessages > 0) {
        //             this.globalService.guestChatRequestCount = this.globalService.guestChatRequestCount + 1;
        //         }
        //         bindUserListDate(element.chats[element.chats.length - 1]);
        //     }
        // });
    }
}
