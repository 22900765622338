import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogModel } from '../../models/shared.model';

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogModel) {
    }

    onNoClick(): void {
        this.dialogRef.close(true);
    }

    onLeaveClick(): void {
        this.dialogRef.close();
    }
    contactSupport() {
        window.location.href = 'mailto:info@sopago.agency';
      }
}
