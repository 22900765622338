import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { getTotalNumber, isiOS } from 'src/app/shared/functions/helpers';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { OrderService } from 'src/app/shared/services/order.service';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
@Component({
  selector: 'app-spa-add-payment',
  templateUrl: './spa-add-payment.component.html',
  styleUrls: ['./spa-add-payment.component.scss']
})
export class SpaAddPaymentComponent implements OnInit {

  public invoiceNumber: number;

  public paymentForm = new UntypedFormGroup({
    invoiceNumber: new UntypedFormControl(''),
    amount: new UntypedFormControl(''),
    paymentMethod: new UntypedFormControl('', [Validators.required])
  });

  public totalAmount = 0;

  public codeControl = new UntypedFormControl();

  constructor(public globalService: GlobalServices,
    private resfullService: RestfullServices,
    private route: Router,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SpaAddPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _decimalPipe: DecimalPipe, private activatedRoute: ActivatedRoute) {
  }

  public get isiOS(): boolean {
    return isiOS();
  }

  public getTotalNumber(value: number) {
    return getTotalNumber(Number(value));
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.spaId) this.invoiceNumber = +this.activatedRoute.snapshot.queryParams.spaId
    else this.invoiceNumber = this.data.order.invoiceNumber;

    this.paymentForm.get('invoiceNumber').setValue(this.invoiceNumber);
    this.paymentForm.get('invoiceNumber').disable();

    if (this.activatedRoute.snapshot.queryParams.spaId) this.totalAmount =this.data.amountDue|| this.data.totalAmount.replace(/,/g, '')|| this.data.total.replace(/,/g, '');
    else this.totalAmount = this.data.order.amountDue||this.data.order.totalAmount.replace(/,/g, '')||this.data.order.price.replace(/,/g, '');

    this.paymentForm.get('amount').setValue(this._decimalPipe.transform(this.totalAmount, '1.2-2'));
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public savePayment() {
    let payload = {
      orderNumber: this.activatedRoute.snapshot.queryParams.spaId?this.activatedRoute.snapshot.queryParams.spaId:this.data.order.invoiceNumber,
      // paymentStatus: "paid",
      paymentStatus: this.paymentForm.controls["paymentMethod"].value == 'billing' ? "billing" : Number(this.totalAmount)-Number(this.paymentForm.controls["amount"].value.replace(/,/g, '')) <= 0 ? "paid" : "unpaid",
      amountPaid:(this.data?.amountPaid || this.data.order?.amountPaid )>0 ?(this.data?.amountPaid || this.data.order?.amountPaid )+ Number(this.paymentForm.controls["amount"].value.replace(/,/g, '')):Number(this.paymentForm.controls["amount"].value.replace(/,/g, '')),
      amountDue: Number(this.totalAmount)-Number(this.paymentForm.controls["amount"].value.replace(/,/g, ''))>=0?Number(this.totalAmount)-Number(this.paymentForm.controls["amount"].value.replace(/,/g, '')):0
    }

    this.resfullService.addSpaPayment(payload)
      .subscribe(response => {
        if (response) {
          this.dialogRef.close(true);
        }
      })
    // this.snackBar.openFromComponent(SnackBarComponent, {
    //   panelClass: ['custom-snackbar'],
    //   horizontalPosition: 'right',
    //   verticalPosition: 'top',
    //   data: {
    //     svgIcon: 'check-circle',
    //     message: 'Payment Successfully Updated!'
    //   }
    // });
  }


}
