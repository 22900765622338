import { Component, Input } from '@angular/core';

import { ChatGuestListModel, ChatLineModel } from '../../../shared/models/shared.model';

@Component({
    selector: 'app-chat-user-info',
    templateUrl: './chat-user-info.component.html',
    styleUrls: ['./chat-user-info.component.scss']
})
export class ChatUserInfoComponent  {

    @Input() public selectedGuest: ChatGuestListModel;
    @Input() guest: { firstName: string, lastName: string };
    iconColor: string;
  
    ngOnChanges(){
        this.iconColor=this.generateRandomColor()
    }
    getInitial(): string {
      return this.selectedGuest?.lastName ? this.selectedGuest.lastName[0].toUpperCase() : '';
    }
    generateRandomColor(): string {
        const letters = '0123456789';
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const hex = Math.floor(Math.random() * 16).toString(16);
            color += letters[Math.floor(Math.random() * 6)] + hex;
        }
        return color;
    }
    public lines: ChatLineModel[] = [
        {
            name: 'Booking ID',
            method: 'bookingNumber',
        },
        {
            name: 'Check-In Date',
            method: 'checkInDate',
            pipes: [
                {
                    name: 'date',
                    transformer: 'dd/MM/yyyy'
                },
            ]
        },
        {
            name: 'Check-Out Date',
            method: 'checkOutDate',
            pipes: [
                {
                    name: 'date',
                    transformer: 'dd/MM/yyyy'
                },
            ]
        },
        {
            name: 'Room Type',
            method: 'roomType',
        },
        {
            name: 'No.',
            method: 'roomNumber',
        },
        {
            name: 'Cancellation Policy',
            method: 'cancellationPolicy',
        },
        {
            name: 'Board',
            method: 'board',
        },
        {
            name: 'Adults',
            method: 'adults',
        },
        {
            name: 'Children (0 - 17)',
            method: 'children',
        }
    ];

    constructor() {}
}
