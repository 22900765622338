<div class="addReservation">
    <form [formGroup]="guestForm" class="w-100">
        <div class="addReservation__inner">

            <div class="addReservation_box left">

                <div class="TriggerBox">
                    <div class="TriggerBox_label">Status</div>
                    <div class="TriggerBox__menu active reservation_status" fxLayout="row"
                        fxLayoutAlign="space-between center" [matMenuTriggerFor]="menu" #t="matMenuTrigger"
                        [ngClass]="currentStatus  | specialCharPipe | removeWhiteSpace">
                        <span>{{currentStatus}}</span>
                        <mat-icon [class.rotated]="t.menuOpen" [svgIcon]="'arrow-down'"></mat-icon>
                    </div>
                </div>

                <mat-menu #menu="matMenu" class="TriggerBox__status reservation_status" yPosition="below">
                    <div class="menu-option" (click)="changeStatus(status)"
                        *ngFor="let status of reservationStatus; let i = index">
                        <div class="menuItem reservation_status"
                            [ngClass]="status.value=='No Show'?'NoShow':status.value">{{status.label}}</div>
                    </div>
                </mat-menu>

                <div class="addReservation__title">RESERVATION DETAILS</div>
                <div class="reservationForm" fxLayout="row" *ngIf="!globalService.isMobile$.getValue()">
                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                        <div class="formGroup">
                            <mat-label>Guest Name <span class="color_red">*</span></mat-label>

                            <mat-form-field class="width_100" appearance="outline">
                                <input type="text" aria-label="Number" matInput formControlName="guestName"
                                    placeholder="Last name, first name" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let guest of filteredUser | async" [value]="guest">
                                        {{guest.guestName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <div *ngIf="guestForm.controls['guestName'].invalid && (guestForm.controls['guestName'].dirty || guestForm.controls['guestName'].touched)"
                                class="alert">
                                <mat-error *ngIf="(guestForm.get('guestName')).errors?.required">Guest Name is
                                    required
                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <div fxFlex="32" fxLayout="column" fxLayoutAlign="start start">
                        <div class="formGroup filter_Box app-selectBox">
                            <div class="filter_section dropdownCustom">
                                <mat-label>People</mat-label>
                                <mat-form-field class="custom_Select">
                                    <mat-select placeholder="Select" formControlName="guestAspeople"
                                        style="height: 32px">
                                        <mat-option value="" disabled>Select</mat-option>
                                        <mat-option *ngFor="let people of peopleOpt" [value]="people.value">
                                            {{ people.label }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <i class="bi bi-arrow-down"></i> -->
                                    <mat-icon class="mat-icon" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div fxFlex="32" fxLayout="column" fxLayoutAlign="start start">
                        <div class="formGroup">
                            <div class="">
                                <mat-label>Time</mat-label>
                                <!-- <mat-form-field class="selectBox">
                                    <mat-select placeholder="Time" formControlName="selectedTime">
                                        <mat-option value="" disabled>Select</mat-option>
                                        <mat-option *ngFor="let time of reservationTime" [value]="time.value">
                                            {{ time.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon class="mat-icon" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                                </mat-form-field> -->
                                <mat-form-field class="width_100" appearance="outline">
                                    <div class="custom_field" fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
                                        <input type="time" onfocus="this.showPicker()" matInput
                                            formControlName="selectedTime">
                                        <img src="../../../../assets/images/alarm1.svg" alt="">
                                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="#000" width="14" height="14"
                                            viewBox="0 0 14 14">
                                            <path fill="#868E96" id="alarm"
                                                d="M10,15.4a4.866,4.866,0,1,1,4.9-4.866A4.883,4.883,0,0,1,10,15.4M10,4.278a6.256,6.256,0,1,0,6.3,6.256A6.278,6.278,0,0,0,10,4.278m.35,2.781H9.3v4.171l3.325,1.981.525-.855-2.8-1.647V7.058m-3.234-3.2L6.22,2.79,3,5.466,3.9,6.53,7.116,3.854M17,5.473,13.78,2.79l-.9,1.064L16.1,6.537Z"
                                                transform="translate(-3 -2.79)" fill="#a7a7a7" />
                                        </svg> -->
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start start">
                        <div class="formGroup">
                            <mat-label>Date</mat-label>
                            <mat-form-field class="filter_1 w-100 customDatepicker" appearance="outline"
                                fxLayoutAlign="start center">
                                <mat-datepicker-toggle matPrefix [for]="picker2"></mat-datepicker-toggle>
                                <input formControlName="reservation_Date" matInput [matDatepicker]="picker2"
                                    placeholder="DD/MM/YYYY" (dateInput)="startDate('input', $event)"
                                    (click)="picker2.open()" (dateChange)="startDate('change', $event)">
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>

                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start">
                        <div class="formGroup">
                            <mat-label>Note/Remarks</mat-label>
                            <textarea matInput placeholder="add note..." formControlName="notes"></textarea>
                        </div>
                    </div>

                </div>
            </div>

            <div class="addReservation_box right" *ngIf="globalService.HOTELID!=globalService.baobabHotelId">
                <div class="reservationForm">
                    <div>
                        <div class="addReservation__title">ASSIGN TABLE<span class="text-theme">
                                <!-- (Coming
                                Soon!) -->
                            </span></div>
                        <div class="container_section1 w-100" fxLayoutGap="20px" fxLayout="column"
                            fxLayoutAlign="start center" *ngIf="!globalService.isMobile$.getValue()">
                            <div class="formGroup filter_Box app-selectBox">
                                <div class="filter_section dropdownCustom">
                                    <mat-label>Table No</mat-label>
                                    <mat-form-field class="custom_Select">
                                        <mat-select placeholder="Time" formControlName="tableNo" style="height: 32px;">
                                            <mat-option value="">Select</mat-option>
                                            <mat-option *ngFor="let tableNo of tableNumberList" [value]="tableNo.value">
                                                {{ tableNo.viewValue }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon class="mat-icon" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                                    </mat-form-field>
                                    <!-- <div *ngIf="guestForm.controls['tableNo'].invalid && (guestForm.controls['tableNo'].dirty)"
                                        class="alert">
                                        <mat-error *ngIf="(guestForm.get('tableNo')).errors?.required">Table no is
                                            required.
                                        </mat-error>
                                    </div> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div class="addReservation__title">OTHER OPTIONS<span class="text-theme">
                                <!-- (Coming
                                Soon!) -->
                            </span></div>
                        <div class="checkBox" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start"
                            *ngIf="!globalService.isMobile$.getValue()">
                            <mat-checkbox class="" formControlName="bookedOpt">
                                <span style="color: #868e96">Fully booked. Decline
                                    request
                                </span>
                            </mat-checkbox>
                            <mat-checkbox class="">
                                <span style="color: #868e96">
                                    Add guest to the waiting list
                                </span>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="addReservation__btnPart">
        <button class="btn dark-theme w-100" (click)="save('save')" type="submit" *ngIf="!reservationId">Save</button>
        <button class="btn dark-theme w-100" (click)="save('update')" type="submit"
            *ngIf="reservationId">Update</button>
    </div>
</div>