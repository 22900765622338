import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { takeUntil } from 'rxjs/operators';

import { SortFilterComponent } from '../../../shared/components/sort-filter/sort-filter.component';
import { AddPaymentComponent } from '../add-payment/add-payment.component';
import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';
import { ApplyQueryComponent } from '../../../shared/functions/apply-query.component';

import { GlobalServices } from '../../../shared/services/global.services';
import { OrderService } from '../../../shared/services/order.service';

import { fillFilterValues, getTotalNumber, transformString } from '../../../shared/functions/helpers';

import { GlobalOrderModel, GlobalReservationModel, SortFilterConfigModel } from '../../../shared/models/shared.model';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import * as introJs from 'intro.js';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})


@AutoUnsubscribe()
export class OrdersComponent extends ApplyQueryComponent implements OnInit, AfterViewInit, OnDestroy {
    introJS = introJs.default();
    introJsReservation = introJs.default();
    selectedIndex: number = 0;
    searchValue: boolean = false;
    reservationSearch: boolean = false;
    @ViewChild('reservationDataPaginator') set reservationDataPaginator(pager: MatPaginator) {
        if (pager) this.dataSource.paginator = pager;
    }
    @ViewChild('ordersDataPaginator') ordersDataPaginator: MatPaginator;
    @ViewChild("craneTable", { static: false }) sortCrane: MatSort;
    @ViewChild("orderTable", { static: false }) sort: MatSort;


    public ordersFilterForm = new UntypedFormGroup({
        search: new UntypedFormControl(),
        sortBy: new UntypedFormControl(),
        payment: new UntypedFormControl(),
        status: new UntypedFormControl(),
    });


    public sortFilterConfig: SortFilterConfigModel[] = [
        {
            label: 'Sort By',
            mode: 'radio',
            items: [
                {
                    label: 'Most Recent',
                    checked: false,
                },
                {
                    label: 'Oldest',
                    checked: false
                }
            ]
        },
        {
            label: 'Payment Status',
            mode: 'checkbox',
            items: [
                {
                    label: 'paid',
                    checked: false
                },
                {
                    label: 'unpaid',
                    checked: false
                },
                {
                    label: 'cancelled',
                    checked: false
                },
            ]
        },
        {
            label: 'Order Status',
            mode: 'checkbox',
            items: [
                {
                    label: 'Pending',
                    checked: false
                },
                {
                    label: 'Processing',
                    checked: false
                },
                {
                    label: 'Delivered',
                    checked: false
                },
                {
                    label: 'Cancelled',
                    checked: false
                },
            ]
        }
    ];

    public sortingList = [];
    public reservationStatus = [];
    public sortByOpt = [];
    public paymentStatusOpt = [];
    public orderStatusOpt = [];
    public headerTitle: string = "";


    public ordersDataSource = new MatTableDataSource<GlobalOrderModel>();
    public ordersDisplayedColumns: string[] = ['orderNumber', 'delivery', 'payment', 'total', 'status', 'action'];
    public activeTableIndex = 0;

    public orderNotifications = 6;
    public reservationNotifications = 3;

    public restaurantId: number;

    private initialOrdersDataSource: GlobalOrderModel[];


    localFoodAndBeveragesCount: number = 0;
    localTableReservationCount: number = 0;

    public dataSource = new MatTableDataSource<GlobalReservationModel>();
    public reservationDisplayColumn: string[] = ['reservationId', 'guestName', 'people', 'date', 'status'];
    private reservationDataSource: GlobalReservationModel[];

    get isValueInFilterForm(): boolean {
        return !!Object.keys(this.ordersFilterForm.value).find(item => !!this.ordersFilterForm.value[item]);
    }

    public getTotalNumber(value: number) {
        return getTotalNumber(value);
    }

    public getFormControl(control: string) {
        return this.ordersFilterForm.get(control);
    }


    /** Reservation Form  control methods start*/

    public reservationForm = new UntypedFormGroup({
        search: new UntypedFormControl(),
        status: new UntypedFormControl(),
        sortingValue: new UntypedFormControl()
    });

    public filterValues = {
        search: '',
        status: '',
        sortingValue: ''
    };

    public get search() {
        return this.reservationForm.get('search');
    }

    public get status() {
        return this.reservationForm.get('status');
    }

    public get sortingValue() {
        return this.reservationForm.get('sortingValue');
    }

    private formSubscribe() {
        fillFilterValues.bind(this)('reservationForm', 'dataSource', 'filterValues');
    }


    get isReservationInForm(): boolean {
        return !!Object.keys(this.reservationForm.value).find(item => !!this.reservationForm.value[item]);
    }

    /** Reservation Form  control methods end*/

    /** Reservation search filter */
    searchFilter(value) {
        if (value) {
            this.reservationSearch = true;
        } else {
            this.reservationSearch = false;
        }
    }

    /** Filter using reservation list */
    private getFormsValue() {
        this.dataSource.filterPredicate = (data: any, filterPhrase: string): boolean => {
            const searchString = JSON.parse(filterPhrase);
            let isNameMatch = true;
            let isStatusMatch = true;
            if (searchString.search) {
                this.reservationSearch = true;
                isNameMatch = (transformString(data.reservationId).indexOf(searchString.search.toLowerCase()) !== -1
                    || transformString(data.guestName).indexOf(searchString.search.toLowerCase()) !== -1);
            }

            if (searchString.status) {
                isStatusMatch = data.status.toString().trim().toLowerCase().indexOf(searchString.status.toLowerCase()) !== -1;
            }

            return isNameMatch && isStatusMatch;
        }
    }


    public formatDate(value: any) {
        value = new Date(value);
        const date = new Date().toDateString() === value.toDateString()
            ? 'Today'
            : `${value.getUTCDate()}/${value.getUTCMonth() + 1}/${value.getUTCFullYear()}`;
        return {
            date,
            // time: `${value.getUTCHours()}:${value.getMinutes()}`
            time: `${value.toLocaleTimeString('en-US',
                { hour12: true, hour: 'numeric', minute: 'numeric' }
            ).toLowerCase()}`
        };
    }

    constructor(public globalService: GlobalServices,
        public router: Router,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private restfullServices: RestfullServices,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar) {
        super(activatedRoute, router);
        this.ordersFilterForm.get('payment').setValue(this.filters?.payment || null);
        this.ordersFilterForm.get('status').setValue(this.filters?.status || null);
        /** Reservation Form */
        this.reservationForm.get('status').setValue(this.filters?.status || null);
        if (!globalService.isMobile$.getValue()) {
            this.ordersDisplayedColumns.splice(1, 0, 'created');
        }
        this.restoreFilterFromSessionStorage();
    }

    ngOnInit(): void {
        window.location.href = '#/pages/food-beverages/orders?mode=snack';
        // this.globalService.headerData$.next({
        //     headerTitle: this.headerTitle,
        //     showBackButton: true,
        //     //navigationPage: '/pages/food-beverages/orders?mode=snack',
        //     //  navigationPage: '/pages/food-beverages/orders',
        //     navigationPage: '/pages/food-beverages/',
        // });
        this.ordersFilterForm.valueChanges.subscribe(() => {
            this.saveFilterToSessionStorage();
        });
        this.globalService.tabIndexSet.subscribe((res: any) => {
            if (res) {
                this.activeTableIndex = Number(res);
            }
        });
        //this.globalService.foodAndBeveragesCount = 0;
        this.globalService.tableReservationCount = 0;
        this.sortingList = [
            {
                key: 'Date (Ascending)',
                value: {
                    'field': 'date',
                    'start': 'asc'
                }
            },
            {
                key: 'Date (Descending)',
                value: {
                    'field': 'date',
                    'start': 'desc'
                }
            },
            {
                key: 'Name A-Z',
                value: {
                    'field': 'guestName',
                    'start': 'asc'
                }
            },
            {
                key: 'Name Z-A',
                value: {
                    'field': 'guestName',
                    'start': 'desc'
                }
            }
        ]
        this.reservationStatus = [
            { label: 'New Request', value: 'New Request' },
            { label: 'Confirmed', value: 'Confirmed' },
            { label: 'Seated', value: 'Seated' },
            { label: 'No Show', value: 'No Show' },
            { label: 'Cancel', value: 'Cancelled' },
        ];
        // this.ordersService.getOrdersTableData()
        //     .pipe(takeUntil(this['destroy$']))
        //     .subscribe(resp => {
        //         this.initialOrdersDataSource = resp;
        //         this.ordersDataSource.data = resp;

        //         this.filterTableData();
        //     });


        this.sortFilterConfig.forEach((sort, index) => {
            switch (index) {
                case 0:
                    return this.sortByOpt = sort.items.map(item => ({
                        label: item.label,
                        value: item.label.toLowerCase()
                    }));
                case 1:
                    return this.paymentStatusOpt = sort.items.map(item => ({
                        label: item.label,
                        value: item.label.toLowerCase()
                    }));
                case 2:
                    return this.orderStatusOpt = sort.items.map(item => ({
                        label: item.label,
                        value: item.label.toLowerCase()
                    }));
            }
        });

        this.restaurantId = this.activatedRoute.snapshot.queryParams.id;
        this.ordersFilterForm.valueChanges.subscribe(() => this.filterTableData());
        this.fetchOrderList();
        this.getReservationList();
        this.formSubscribe();
        this.getFormsValue();
        this.assignTableIndex();


    }
    private saveFilterToSessionStorage() {
        const filterValues = this.ordersFilterForm.value;
        sessionStorage.setItem('orderFilterValues', JSON.stringify(filterValues));
    }

    // New method to restore filter from session storage
    private restoreFilterFromSessionStorage() {
        const savedFilterValues = sessionStorage.getItem('orderFilterValues');
        if (savedFilterValues) {
            const parsedFilterValues = JSON.parse(savedFilterValues);

            // Set form values
            Object.keys(parsedFilterValues).forEach(key => {
                if (this.ordersFilterForm.get(key)) {
                    this.ordersFilterForm.get(key).setValue(parsedFilterValues[key], { emitEvent: false });
                }
            });
        }
    }

    startTourAddOrder() {
        this.introJS.setOptions({
            steps: [
                {
                    //Step 12
                    tooltipClass: "intro-step-order",
                    title: "ALL ORDERS",
                    element: document.getElementById('all_order'),
                    intro: "Effortlessly oversee orders, handle payments, and get a quick overview of your restaurant operations.",
                    position: 'right'
                },

                {
                    //Step 13
                    tooltipClass: "intro-step-13",
                    title: "ADD NEW ORDER",
                    element: document.getElementById('add_order'),
                    intro: "Click on the ‘+ Add Order’ button to open the menu and add a new order.",
                    position: 'right'
                },


            ],
            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip',
        });
        this.introJS.start();
        this.introJS.onbeforechange((targetElement) => {
            this.introJS.refresh();
        });
        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let skipButton = document.querySelector(".intro-skip");
                skipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }

    skipTour(): void {
        this.introJS.exit();
        this.introJsReservation.exit();
        this.restfullServices.removeLocalStorage('isTourRunning');
        window.location.href = '#/pages/food-beverages/orders?mode=snack';
    }

    closeStep(): void {
        window.location.href = '#/pages/food-beverages/orders?mode=snack';
    }


    startTourReservation() {
        this.introJsReservation.setOptions({
            steps: [
                {
                    //Step 14
                    //  table_reservation
                    tooltipClass: "intro-step-reservation",
                    title: "TABLE RESERVATIONS",
                    element: document.getElementById('table_reservation'),
                    intro: "Here, you can keep track of all reservations. Update customer status, indicating whether they are seated or marked as a no-show, for example.",
                    position: 'right'
                },

                {
                    //Step 15
                    tooltipClass: "intro-step-15",
                    title: "ADD NEW RESERVATION",
                    element: document.getElementById('add_reservation'),
                    intro: "Click the ‘+ Add Reservation’ button to add a new reservation.",
                    position: 'right'
                }

            ],
            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip',
        });
        this.introJsReservation.start();
        this.introJsReservation.onbeforechange((targetElement) => {
            this.introJsReservation.refresh();
        });

        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let skipButton = document.querySelector(".intro-skip");
                skipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }


    assignTableIndex() {
        var tabIndex = this.restfullServices.getLocalStorage('tabindex');
        this.activeTableIndex = Number(tabIndex);
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.ordersDataSource.sort = this.sort;
            this.ordersDataSource.paginator = this.ordersDataPaginator;
            this.dataSource.sort = this.sortCrane;
            this.dataSource.paginator = this.reservationDataPaginator;
            if (!this.globalService.isMobile$.getValue()) {
                const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                const guidedTourAddOrderFlag = this.restfullServices.getLocalStorage('guidedTourAddOrderFlag');
                if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourAddOrderFlag == "false") {
                    this.restfullServices.setLocalStorage('guidedTourAddOrderFlag', 'true');
                    this.startTourAddOrder();
                    this.cdr.detectChanges();
                }
            }
        }, 500);

    }

    /** Fetch Oreder List */
    fetchOrderList() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getOrderList(this.restaurantId).subscribe(response => {
            if (response) {
                this.initialOrdersDataSource = response;
                this.ordersDataSource.data = response;
                this.headerTitle = response[0].restaurantName;
                this.globalService.headerData$.next({
                    headerTitle: response[0].restaurantName,
                    showBackButton: true,
                    navigationPage: '/pages/food-beverages/',
                });
                this.filterTableData();

                this.ordersDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'created': return this.formatDate(item.datePlaced)?.date;
                        default: return item[property];
                    }
                };
                this.ordersDataSource.data = this.ordersDataSource.data.filter(x => x.status == "pending" || x.status == "processing" || x.status == "outfordelivery" || x.status == "readyforpickup")
                this.globalService.showLoader$.next(false);
                response.forEach((response, index) => {
                    if (response.status === "pending" || response.status === "processing") {
                        // this.globalService.foodAndBeveragesCount++;
                        this.localFoodAndBeveragesCount++;
                    }
                });
            }
        })
    }


    /*** Fetch reservation list */
    getReservationList() {
        this.restfullServices.getReservationList(this.restaurantId).subscribe(response => {
            if (response) {
                this.globalService.headerData$.next({
                    headerTitle: this.headerTitle,
                    showBackButton: true,
                    navigationPage: '/pages/food-beverages/',
                });
                this.reservationDataSource = response;
                this.dataSource.data = this.reservationDataSource;
                this.dataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'date': return new Date(item.date);
                        default: return item[property];
                    }
                };
                this.dataSource.sort = this.sort;
                response.forEach((response, index) => {
                    if (response.status === "New Request") {
                        //this.globalService.tableReservationCount++;
                        this.localTableReservationCount++;
                    }
                });
            }
        });
    }

    public setFilterValue(control: string, value: string) {
        this.getFormControl(control).setValue(value);
        this.filters[control] = value;
    }

    public resetFilter() {
        this.searchValue = false;
        this.ordersFilterForm.reset();
        sessionStorage.removeItem('orderFilterValues');
        this.ordersDataSource.data = this.initialOrdersDataSource.filter(x =>
            x.status == "pending" ||
            x.status == "processing" ||
            x.status == "outfordelivery" ||
            x.status == "readyforpickup"
        );
    }


    resetReservation() {
        this.reservationSearch = false;
        this.reservationForm.reset();
        this.sortCrane.sort({ id: null, start: 'desc', disableClear: false });
    }

    public addPayment(order) {
        this.dialog.open(AddPaymentComponent, {
            panelClass: ['share-link_container', 'border-top'],
            width: '440px',
            autoFocus: false,
            data: {
                order
            }
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((resp) => {
                this.globalService.headerClickAction$.next(null);
                if (resp == true) {
                    window.location.reload();
                    this.router.navigate(['/pages/food-beverages/orders?mode=snack'], {
                        queryParams: { id: this.restaurantId }
                    });
                    this.openSnackBar('Payment added successfully.');
                }
            });
    }

    public setReservationTable(index: number) {
        if (!this.dataSource.sort) {
            this.dataSource.sort = this.sortCrane;
        }
        if (!this.dataSource.paginator) {
            this.dataSource.paginator = this.reservationDataPaginator;
        }
        this.activeTableIndex = index;
        if (this.activeTableIndex == 1) {
            setTimeout(() => {
                if (!this.globalService.isMobile$.getValue()) {
                    const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                    const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                    const guidedTourReservationFlag = this.restfullServices.getLocalStorage('guidedTourReservationFlag');
                    if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourReservationFlag == 'false') {
                        this.restfullServices.setLocalStorage('guidedTourReservationFlag', "true");
                        this.startTourReservation();
                        this.cdr.detectChanges();
                    }
                }
            }, 500);
        }

    }

    public openSortFilter() {
        this.dialog.open(SortFilterComponent, {
            data: {
                options: this.sortFilterConfig
            },
            autoFocus: false
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe(data => data
                ? this.setNewSortConfig(data?.options)
                : this.ordersDataSource.data = this.initialOrdersDataSource.filter(x => x.status == "pending" || x.status == "processing" || x.status == "outfordelivery" || x.status == "readyforpickup"));
    }

    public addOrder() {
        this.router.navigate(['/pages/food-beverages/add-order'], { queryParams: { restaurantId: this.restaurantId } });
    }

    public openOrderDetails(orderNumber: number) {
        this.router.navigate(['/pages/food-beverages/order-details'], {
            queryParams: {
                orderNumber: orderNumber,
                restaurantId: this.activatedRoute.snapshot.queryParams.id
            }
        });
    }

    /** Go to add reservation */

    public addReservation() {
        this.router.navigate(['/pages/food-beverages/add-reservation'], {
            queryParams: {
                restaurantId: this.activatedRoute.snapshot.queryParams.id
            }
        })
    }

    /** Open reservation details */
    public openReservationDetails(resevationData) {
        const queryParams: any = {
            id: resevationData.id,
            restaurantId: this.activatedRoute.snapshot.queryParams.id
        };
        this.router.navigate(['/pages/food-beverages/add-reservation'], { queryParams })
    }

    private openSnackBar(message: string) {
        this.snackBar.openFromComponent(SnackBarComponent, {
            panelClass: ['custom-snackbar'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            data: {
                svgIcon: 'check-circle',
                message
            }
        });
    }

    private filterTableData() {
        let initialData = this.initialOrdersDataSource;

        const searchValue = this.getFormControl('search').value;
        const paymentValue = this.getFormControl('payment').value;
        const statusValue = this.getFormControl('status').value;

        if (searchValue) {
            this.searchValue = true;
            initialData = initialData.filter(item =>
                item.orderNumber.toString().includes(searchValue)
            );
        }

        if (paymentValue) {
            initialData = initialData.filter(item =>
                item.payment.toLowerCase() === paymentValue
            );
        }

        if (statusValue) {
            initialData = initialData.filter(item =>
                item.status.toLowerCase() === statusValue
            );
        }

        this.ordersDataSource.data = initialData;
    }

    private setNewSortConfig(options: SortFilterConfigModel[]) {
        this.sortFilterConfig = options;
        let initialData = this.initialOrdersDataSource;
        options.filter(item => item.items.find(status => status.checked)).forEach(item => {
            const checkedArrLabels = item.items.filter(status => status.checked).map(status => status.label.toLowerCase());
            switch (item.label) {
                case 'Payment Status':
                    initialData = initialData
                        .filter(tableItem => checkedArrLabels.includes(tableItem.payment.toLowerCase()));
                    break;
                case 'Order Status':
                    initialData = initialData
                        .filter(tableItem => checkedArrLabels.includes(tableItem.status.toLowerCase()));
            }
            this.ordersDataSource.data = initialData;
        });
    }


    ngOnDestroy() {
        this.globalService.tabIndexSet.unsubscribe();
        sessionStorage.removeItem('orderFilterValues');
        // String(this.restfullServices.removeLocalStorage('tabindex'));
    }


    /** Reservation custome sort */
    onCustomeSorting(event) {
        this.sortCrane.sort(({ id: event?.value?.field, start: event?.value?.start }) as MatSortable);
    }
    getDeliverType(type: string) {
        if (type == 'pickup') {
            return "Pickup"
        } else if (type == 'roomService') {
            return "Room Service"
        } else if (type == 'Deliver') {
            return 'Deliver'
        }
    }
    capitalizeFirstLetter(str) {
        if (str.length === 0) return str;
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
