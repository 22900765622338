import { Component, OnInit, Input, Output, ChangeDetectorRef, OnDestroy, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import firebase from 'firebase/compat/app';

import { takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';

import { GlobalServices } from '../../../shared/services/global.services';
import { RestfullServices } from '../../../shared/services/restfull.services';

import { CountryListModel, GuestDetailModel, HeaderModel, Member } from '../../../shared/models/shared.model';
import { cancellationPolicyOpt, boardOpt, roomNumberOpt, genderOpt } from './consts';

import countryData from '../../../../assets/json/countires.json';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as introJs from 'intro.js';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-edit-guest',
    templateUrl: './edit-guest.component.html',
    styleUrls: ['./edit-guest.component.scss']
})
@AutoUnsubscribe()
export class EditGuestComponent implements OnInit, AfterViewInit, OnDestroy {
    introJS = introJs.default();
    hidden: boolean = true
    @Input() guestDetail: GuestDetailModel;

    public guestForm: UntypedFormGroup;
    public countryList: CountryListModel[];

    public isEditMode: boolean;

    public genderOpt = genderOpt;
    public roomNumberOpt = roomNumberOpt;
    public boardOpt = boardOpt;
    public cancellationPolicyOpt = cancellationPolicyOpt;
    public roomTypeOpt: any;
    public age: number = 0;

    public minDate = new Date();

    public guestsArray = [];
    public availableGuestsArrayLabels = [
        {
            formName: 'secondGuest',
            label: '2nd'
        },
        {
            formName: 'thirdGuest',
            label: '3rd'
        },
        {
            formName: 'fourthGuest',
            label: '4th'
        }
    ];

    private key: string;
    private destroy$ = new Subject<void>();

    //public miniHotelBookingRoomTypeId:any;

    constructor(
        public globalService: GlobalServices,
        private fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private restfullServices: RestfullServices,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef
    ) {
        this.key = this.activatedRoute.snapshot.queryParams.key;
        this.globalService.selectedGuestId = this.activatedRoute.snapshot.queryParams.id;
    }

    public getFormControl(formControlName: string) {
        return this.guestForm.get(formControlName);
    }

    ngOnInit() {
        this.getRoomDetails();
        this.isEditMode = this.activatedRoute.snapshot.queryParams.id;

        this.countryList = countryData;

        const headerData: HeaderModel = {
            headerTitle: this.isEditMode ? 'Edit Guest' : 'Add Guest',
            showBackButton: true,
            info1: '',
            info2: '',
            confirmBack: false,
            backActionTitle: 'Unsaved changes',
            backActionMsg: 'Are you sure you want to leave this page? Unsaved changes will be lost.',
            navigationPage: this.isEditMode ? '/pages/guests/guest' : '/pages/guests',
        };
        if (this.isEditMode) {
            headerData.queryParams = {
                id: this.activatedRoute.snapshot.queryParams.id
            };
        }
        this.globalService.headerData$.next(headerData);


        if (this.activatedRoute.snapshot.url.toString().includes('edit-guest')) {
            this.getGuestDetails();
        } else {
            setTimeout(() => {
                this.createGuestForm();
            }, 500)
        }
    }
    subscribeToFormChanges() {
        if (this.guestForm) {
            this.guestForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
                if (this.guestForm.dirty) {
                    const currentHeaderData = this.globalService.headerData$.getValue();
                    if (!currentHeaderData.confirmBack) {
                        this.globalService.headerData$.next({ ...currentHeaderData, confirmBack: true });
                    }
                }
            });
        }
    }

    startTour() {
        this.introJS.setOptions({
            steps: [
                {
                    //Step 5
                    tooltipClass: "intro-step-4",
                    title: "ADDING NEW GUEST",
                    intro:
                        "<p>"
                        + "Well done! Here, you can enter the necessary details of guests with confirmed booking. This is your hub for managing and providing them access to the guest app. Simply fill in the mandatory fields to ensure a seamless experience for your guests." +
                        "</p>"
                        +
                        "<div class='btnPart'><a class='intro-skip-back'>Back</a><div>",
                }
            ],
            disableInteraction: true,
            showBullets: false,
            showButtons: true,
            exitOnOverlayClick: false,
            keyboardNavigation: true,
            scrollToElement: true,
            scrollTo: 'tooltip'
        });

        this.introJS.start();
        this.introJS.onbeforechange((targetElement) => {
            this.introJS.refresh();
        });

        setTimeout(() => {
            if (document.querySelector(".intro-skip")) {
                let SkipButton = document.querySelector(".intro-skip");
                //SkipButton.addEventListener('click', this.skipTour.bind(this));
            }

            if (document.querySelector(".introjs-skipbutton")) {
                let closeButton = document.querySelector(".introjs-skipbutton");
                //closeButton.addEventListener('click', this.closeStep.bind(this));
            }
        }, 100);
    }


    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getRoomDetails() {
        this.restfullServices.getHotelRooms().pipe(takeUntil(this['destroy$'])).subscribe(rooms => {
            if (rooms) {
                this.roomTypeOpt = rooms;
            }
        });
    }

    public addGuestToForm() {
        if (this.guestsArray.length < this.availableGuestsArrayLabels.length) {
            const availableGuestLabel = this.availableGuestsArrayLabels[this.guestsArray.length];
            this.guestsArray.push(availableGuestLabel);
            this.guestForm.addControl(availableGuestLabel.formName, this.fb.group({
                lastName: ['', Validators.required],
                firstName: ['', Validators.required],
                birthDate: ['', Validators.required],
                contactNo: '',
                email: ''
            }));
        }
    }

    public removeGuestToForm() {
        if (this.guestsArray.length) {
            const availableGuestLabel = this.availableGuestsArrayLabels[this.guestsArray.length - 1];
            this.guestsArray.pop();
            //this.guestForm.removeControl(availableGuestLabel.formName);
        }
    }

    public checkNonNull = (control: AbstractControl): boolean => {
        if (control instanceof UntypedFormControl) {
            return control.value ? true : false;
        }
    }

    public editGuest() {
        this.guestsArray = [];
        if (this.guestForm.valid) {
            this.fillGuestForm();
            this.guestForm.value.bookingNumber = this.guestDetail.bookingNumber;
            this.globalService.showLoader$.next(true);
            if (this.isNullish(this.guestForm.value.secondGuest)) {
                this.guestForm.value.secondGuest = null;
            }
            if (this.isNullish(this.guestForm.value.thirdGuest)) {
                this.guestForm.value.thirdGuest = null;
            }
            if (this.isNullish(this.guestForm.value.fourthGuest)) {
                this.guestForm.value.fourthGuest = null;
            }
            this.restfullServices.editGuest(this.guestForm.value)
                .subscribe(response => {
                    if (response) {

                        if (response.id > 0) {
                            if (this.guestDetail.sopagoMinihotelInfo != null && this.guestDetail.sopagoMinihotelInfo != undefined) {
                                //Update MiniHotel UpdateReservation() - Not complete yet to test it once we have guests import in DB
                                var header = {
                                    status: this.guestForm.value.status == "Checked-In" ? "IN" : this.guestForm.value.status == "Confirmed" ? "OK" : "WL",
                                    arrivalTime: "",
                                    departureTime: ""
                                    //,
                                    //remarks: remarks
                                }
                                var remarks = {
                                    appendRemarks: true,
                                    printed: "",
                                    nonPrinted: ""
                                }
                                var roomType = {
                                    type: this.guestDetail.roomType
                                }
                                var allGuestRequest = {
                                    username: this.guestDetail.sopagoMinihotelInfo.username,
                                    password: this.guestDetail.sopagoMinihotelInfo.password,
                                    hotelId: this.guestDetail.sopagoMinihotelInfo.miniHotel_Id
                                }
                                var member: Member[] = [{
                                    serial: this.guestDetail.miniHotelMemberSerial,
                                    firstName: this.guestForm.value.firstName,
                                    lastName: this.guestForm.value.lastName,
                                    email: this.guestForm.value.email,
                                    phone: this.guestForm.value.mobileNumber != null ? this.guestForm.value.mobileNumber : "",
                                    idNumber: "",
                                    roomType: this.guestForm.value.roomType
                                }]
                                var reservation = {
                                    header: header,
                                    members: member
                                }

                                var obj = {
                                    credentials: allGuestRequest,
                                    reservation: reservation,
                                    reservationId: this.guestDetail.miniHotelReservationId //Sample id - call backend API to get reservationId from guestId
                                }
                                this.restfullServices.updateReservationToMiniHotel(obj).subscribe(response => { console.log("success") })
                            }
                            this.updateGuestDetails(response);
                        }
                    } else {
                        this.globalService.showAlert(this.globalService.errorMsg);
                    }
                    this.globalService.showLoader$.next(false);
                }, (err) => {
                    {
                        if (err === 402) {
                            this.globalService.showAlert("Guest already exist with same email id");
                        }
                        this.globalService.showLoader$.next(false);
                    }

                    //this.globalService.showAlert(this.globalService.errorMsg)
                });
        } else {
            this.guestForm.markAllAsTouched();
        }
    }

    public isNullish(object: any) {
        return Object.values(object).every(value => {
            if (value !== null) {
                return false;
            }
            return true;
        });
    }

    public addGuest() {
        if (this.guestForm.valid) {
            this.fillGuestForm();
            this.guestForm.value.status = 'confirmed';
            this.guestForm.value.appActivity = 'manage';
            if (this.isNullish(this.guestForm.value.secondGuest)) {
                this.guestForm.value.secondGuest = null;
            }
            if (this.isNullish(this.guestForm.value.thirdGuest)) {
                this.guestForm.value.thirdGuest = null;
            }
            if (this.isNullish(this.guestForm.value.fourthGuest)) {
                this.guestForm.value.fourthGuest = null;
            }
            this.globalService.showLoader$.next(true);
            this.restfullServices.addGuest(this.guestForm.value)
                .subscribe(response => {
                    if (response == 1) {
                        this.globalService.selectedGuestId = response;
                        this.openSnackBar('Successfully added guest.');
                        this.router.navigate(['/pages/guests']);
                    }
                    this.globalService.showLoader$.next(false);
                }, (err) => {
                    {
                        if (err === 402) {
                            this.globalService.showAlert("Guest already exist with same email id");
                        }
                        this.globalService.showLoader$.next(false);
                    }

                    //this.globalService.showAlert(this.globalService.errorMsg)
                });
        } else {
            this.guestForm.markAllAsTouched();
        }
    }

    private updateGuestDetails(newGuestDetail: GuestDetailModel) {
        const guestName = newGuestDetail.lastName + ', ' + newGuestDetail.firstName;

        const headerData = this.getHeaderData();
        headerData.info1 = guestName;
        // update guest name in firebase chat
        firebase.database().ref('chatrooms/' + this.key + '/guestname').set(guestName);
        this.globalService.headerData$.next(headerData);
        this.guestDetail = newGuestDetail;
        this.openSnackBar('Guest updated.');
        const queryParams: any = {
            id: this.guestDetail.id,
        };
        this.router.navigate(['/pages/guests/guest'], { queryParams });
    }

    private openSnackBar(message: string) {
        this.snackBar.openFromComponent(SnackBarComponent, {
            panelClass: ['custom-snackbar'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            data: {
                svgIcon: 'check-circle',
                message
            }
        });
    }

    private getGuestDetails() {
        this.globalService.showLoader$.next(true);
        this.restfullServices.getGuestDetails(this.globalService.selectedGuestId)
            .subscribe(response => {
                this.guestDetail = response;
                //debugger
                //assing miniHotelBookingRoomTypeId
                //this.miniHotelBookingRoomTypeId=this.guestDetail.miniHotelBookingRoomTypeId;

                // const headerData = this.getHeaderData();
                // headerData.info1 = this.guestDetail.lastName + ', ' + this.guestDetail.firstName;
                // headerData.info2 = 'Booking ID: ' + this.guestDetail.bookingNumber;
                // this.globalService.headerData$.next(headerData);

                this.createGuestForm();
                if (response.secondGuest != null) {
                    this.addGuestToForm();
                    // this.guestForm.controls.secondGuest = this.fb.group({
                    //     lastName: [response.secondGuest.lastName, Validators.required],
                    //     firstName: [response.secondGuest.firstName, Validators.required],
                    //     birthDate: [response.secondGuest.birthDate, Validators.required],
                    //     contactNo: response.secondGuest.contactNo,
                    //     email: response.secondGuest.email
                    // });
                }
                if (response.thirdGuest != null) {
                    this.addGuestToForm();
                    // this.guestForm.controls.thirdGuest = this.fb.group({
                    //     lastName: [response.thirdGuest.lastName, Validators.required],
                    //     firstName: [response.thirdGuest.firstName, Validators.required],
                    //     birthDate: [response.thirdGuest.birthDate, Validators.required],
                    //     contactNo: response.thirdGuest.contactNo,
                    //     email: response.thirdGuest.email
                    // });
                }
                if (response.fourthGuest != null) {
                    this.addGuestToForm();
                    // this.guestForm.controls.fourthGuest = this.fb.group({
                    //     lastName: [response.fourthGuest.lastName, Validators.required],
                    //     firstName: [response.fourthGuest.firstName, Validators.required],
                    //     birthDate: [response.fourthGuest.birthDate, Validators.required],
                    //     contactNo: response.fourthGuest.contactNo,
                    //     email: response.fourthGuest.email
                    // });
                }
                this.globalService.showLoader$.next(false);
            }, () => {
                this.globalService.showAlert(this.globalService.errorMsg);
                this.globalService.showLoader$.next(false);
            });
    }

    private createGuestForm() {
        let roomType
        if (this.guestDetail) {
            roomType = this.roomTypeOpt.find(x => x.id == this.guestDetail?.roomId).value
        }
        const dateStr = this.guestDetail?.dob;
        const parts = dateStr?.split('/');
        let date

        this.guestForm = this.fb.group({
            id: [this.guestDetail?.id || null],
            hotelId: [],
            lastName: [this.guestDetail?.lastName || null, [Validators.required]],
            firstName: [this.guestDetail?.firstName || null, [Validators.required]],
            dob: [this.guestDetail?.dob ? new Date(this.guestDetail?.dob) : null],
            // dob: [this.guestDetail., Validators.compose([Validators.required, DateValidator.dateVaidator])],
            age: [this.guestDetail?.age || 0],
            gender: [this.guestDetail?.gender || null],
            nationality: [this.guestDetail?.nationality || null],
            companyName: [this.guestDetail?.companyName || null],
            streetName: [this.guestDetail?.streetName || null],
            houseNumber: [this.guestDetail?.houseNumber || null],
            zipCode: [this.guestDetail?.zipCode || null],
            town: [this.guestDetail?.town || null],
            state: [this.guestDetail?.state || null],
            country: [this.guestDetail?.country || null],
            mobileNumber: [this.guestDetail?.mobileNumber || null, [Validators.required, Validators.pattern('^[0-9.+-, ]+$')]],
            bookingNumber: [this.guestDetail?.bookingNumber || null, [Validators.required]],
            email: [this.guestDetail?.email || null, { validators: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')], updateOn: 'blur' }],
            checkInDate: [this.guestDetail?.checkInDate ? new Date(this.guestDetail?.checkInDate) : new Date(), [Validators.required]],
            checkOutDate: [this.guestDetail?.checkOutDate ? new Date(this.guestDetail?.checkOutDate) : new Date(new Date().setDate(new Date().getDate() + 1)), [Validators.required]],
            nights: [this.guestDetail?.nights ? this.guestDetail?.nights : 1 || null],
            numberOfRooms: [this.guestDetail?.numberOfRooms || null, [Validators.required, Validators.pattern('^[0-9]+$')]],

            roomType: [roomType || null, [Validators.required]],
            roomId: [this.guestDetail?.roomId || null],
            roomNumber: [this.guestDetail?.roomNumber.toString() || null],
            board: [this.guestDetail?.board || null],
            cancellationPolicy: [this.guestDetail?.cancellationPolicy || null],
            children: [this.guestDetail?.children || null],
            extras: [this.guestDetail?.extras || null],
            status: [this.guestDetail?.status || null],
            appActivity: [this.guestDetail?.appActivity || null],
            created: [null],
            secondGuest: this.fb.group({
                lastName: [this.guestDetail?.secondGuest?.lastName || null],
                firstName: [this.guestDetail?.secondGuest?.firstName || null],
                birthDate: [this.guestDetail?.secondGuest?.birthDate || null],
                contactNo: [this.guestDetail?.secondGuest?.contactNo || null],
                email: [this.guestDetail?.secondGuest?.email || null]
            }) || null,
            thirdGuest: this.fb.group({
                lastName: [this.guestDetail?.thirdGuest?.lastName || null],
                firstName: [this.guestDetail?.thirdGuest?.firstName || null],
                birthDate: [this.guestDetail?.thirdGuest?.birthDate || null],
                contactNo: [this.guestDetail?.thirdGuest?.contactNo || null],
                email: [this.guestDetail?.thirdGuest?.email || null]
            }) || null,
            fourthGuest: this.fb.group({
                lastName: [this.guestDetail?.fourthGuest?.lastName || null],
                firstName: [this.guestDetail?.fourthGuest?.firstName || null],
                birthDate: [this.guestDetail?.fourthGuest?.birthDate || null],
                contactNo: [this.guestDetail?.fourthGuest?.contactNo || null],
                email: [this.guestDetail?.fourthGuest?.email || null]
            }) || null
        });


        if (parts) {
            date = new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]));
            this.getAge({ value: date })
            this.guestDetail.age = this.age?.toString() || '0';
            this.cdr.detectChanges()
        }

        // this.guestForm.get('checkInDate').valueChanges
        //     .pipe(takeUntil(this['destroy$']))
        //     .subscribe(() => this.setDates(true));
        // this.guestForm.get('checkOutDate').valueChanges
        //     .pipe(takeUntil(this['destroy$']))
        //     .subscribe(() => this.setDates(true));
        this.hidden = false
        this.subscribeToFormChanges();
        this.cdr.detectChanges();
    }

    private setDates(checkIn?: boolean) {
        setTimeout(() => {
            const dayDifference = Number((((this.guestForm.value.checkOutDate?.getTime() + (6 * 60 * 60 * 1000))
                - this.guestForm.value.checkInDate?.getTime()) / (1000 * 3600 * 24)).toFixed(0));
            this.guestForm.get('nights').setValue(dayDifference.toString() ? dayDifference.toString() : 0);
            if (dayDifference < 0) {
                this.guestForm.get('checkOutDate').setValue(this.guestForm.value.checkInDate);
            }
        }, 100);
    }

    private fillGuestForm() {
        this.guestForm.value.hotelId = this.globalService.HOTELID;
        this.guestForm.value.bookingNumber = this.guestForm.value.bookingNumber;//new Date().getTime().toString();
        this.guestForm.value.dob = this.guestForm.value.dob != null ? this.getDate(new Date(this.guestForm.value.dob)) : "";
        this.guestForm.value.checkInDate = this.getDate(new Date(this.guestForm.value.checkInDate));
        this.guestForm.value.checkOutDate = this.getDate(new Date(this.guestForm.value.checkOutDate));
        this.guestForm.value.created = new Date();
        this.guestForm.value.age = this.age.toString();
    }

    private getDate(toDate: Date) {
        return ('0' + (toDate.getMonth() + 1).toString()).slice(-2) + '/' + ('0' + toDate.getDate().toString()).slice(-2) + '/' + toDate.getFullYear();
    }

    private getHeaderData(): HeaderModel {
        return this.globalService.headerData$.getValue();
    }

    public getAge(event: any) {
        if (this.guestForm.value.dob != "" && this.guestForm.value.dob != null) {
            var timeDiff = Math.abs(Date.now() - event.value);
            this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
        }
        else {
            this.age = 0;
        }
    }
    onDateBlur(event: any, type: string) {
        if (event.target.value.length >= 6) {
            let inputValue = event.target.value;
            const cleanValue = inputValue.replace(/\//g, '');
            if (/^\d+$/.test(cleanValue)) {
                if (cleanValue.length >= 2) {
                    inputValue = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}`;

                    if (cleanValue.length > 4) {
                        inputValue += `/${cleanValue.slice(4, 8)}`;
                    }
                }
            }
            const isValidDate = this.isValidDate(inputValue);
            if (isValidDate) {
                event.target.value = inputValue;
                const [day, month, year] = inputValue.split('/');
                const parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
                if (type == 'dob') {
                    this.guestForm.patchValue({
                        dob: parsedDate
                    });
                    this.getAge({ value: this.guestForm.value.dob })
                }
                if (type == 'checkIn') {
                    this.guestForm.patchValue({
                        checkInDate: parsedDate
                    });
                    if(this.guestForm.value.checkInDate && this.guestForm.value.checkOutDate) this.setDates()
                }
                if (type == 'checkOut') {
                    this.guestForm.patchValue({
                        checkOutDate: parsedDate
                    }); 
                    if(this.guestForm.value.checkInDate && this.guestForm.value.checkOutDate) this.setDates()
                }
            } else {
                if (type == 'dob') {
                    this.guestForm.get('dob').setErrors({ 'invalidDate': true });
                }
                if (type == 'checkIn') {
                    this.guestForm.get('checkInDate').setErrors({ 'invalidDate': true });
                }
                if (type == 'checkOut') {
                    this.guestForm.get('checkOutDate').setErrors({ 'invalidDate': true });
                }
                event.target.value = '';
            }
        }
    }

    isValidDate(dateString: string): boolean {
        if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
            return false;
        }
        const [day, month, year] = dateString.split('/').map(Number);

        if (month < 1 || month > 12) {
            return false;
        }
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > daysInMonth) {
            return false;
        }
        const currentYear = new Date().getFullYear();
        if (year < 1900 || year > currentYear) {
            return false;
        }
        return true;
    }


    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.globalService.isMobile$.getValue()) {
                const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
                const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
                const guidedTourEditGuestFlag = this.restfullServices.getLocalStorage('guidedTourEditGuestFlag');
                if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourEditGuestFlag == 'false') {
                    this.restfullServices.setLocalStorage('guidedTourEditGuestFlag', 'true');
                    this.startTour();
                    this.cdr.detectChanges();
                }
            }
        }, 500);
    }
    selectionChangeEvent($event) {
        this.getFormControl('roomType').setValue($event);
        let id = this.roomTypeOpt.find(x => x.label == $event).id;
        this.getFormControl('roomId').setValue(id);
    }
}
