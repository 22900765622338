<div class="add-payment">
    <div class="add-payment__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="add-payment__header-title">
            ADD PAYMENT
        </div>
        <div class="add-payment__header-subtitle">
            Order no. {{orderNumber}}
        </div>
        <div class="add-payment__header-close">
            <mat-icon class="close-icon" [svgIcon]="'close'" (click)="closePopup()"></mat-icon>
        </div>
    </div>
    <form [formGroup]="paymentForm" *ngIf="paymentForm" class="add-payment__main " [class.iphone-container]="isiOS"
        fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div class="width_100 add-payment__main-orders" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Order no.</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput formControlName="orderNumber">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <mat-label>Total Amount </mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput [suffix]="' '+globalService.currency" mask="separator.2" thousandSeparator="," separatorLimit="999999" [dropSpecialCharacters]="false"
                        formControlName="amount">
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <app-select class="filter_2 dropdownCustom" placeholder="Select" [label]="'Payment Method'"
                    [customControl]="paymentForm.get('paymentMethod')" [noEmptyValue]="true"
                    [value]="paymentForm.get('paymentMethod').value"
                    (selectionChange)="paymentForm.get('paymentMethod').setValue($event)"
                    [selectOptions]="[{label: 'Direct Billing', value: 'billing'}, {label: 'Cash', value: 'cash'}]"></app-select>
            </div>
            <div fxLayout="column" *ngIf="paymentForm.get('paymentMethod').value === 'cash'"
                fxLayoutAlign="start start">
                <mat-label>Amount received</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput [suffix]="' '+globalService.currency" mask="separator.2" thousandSeparator=","
                        separatorLimit="999999" formControlName="amountReceived"
                        [dropSpecialCharacters]="false" 
                         (blur)="formatAmount($event);calculateChange($event)">
                </mat-form-field>
            </div>
            <div fxLayout="column" *ngIf="paymentForm.get('paymentMethod').value === 'cash'"
                fxLayoutAlign="start start">
                <mat-label>Change</mat-label>
                <mat-form-field class="width_100" appearance="outline">
                    <input matInput [suffix]="' '+globalService.currency" mask="separator.2" [dropSpecialCharacters]="false" thousandSeparator=","
                        separatorLimit="999999" formControlName="change">
                </mat-form-field>
            </div>
            <div class="add-payment__main-qr width_100" fxLayoutAlign="center center"
                *ngIf="paymentForm.get('paymentMethod').value === 'billing'">
                <mat-icon [svgIcon]="'qrcode-scan'"></mat-icon>
                Scan Guest's QR-code
            </div>
        </div>
    </form>
    <div class="add-payment__summary" fxLayout="column" fxLayoutAlign="center center">
        <div class="add-payment__summary-actions" fxLayoutAlign="center center">
            <div mat-ripple class="add-payment__summary-clear" (click)="closePopup()">
                Cancel
            </div>
            <div mat-ripple class="add-payment__summary-show" [class.disabled]="!paymentForm.get('paymentMethod').value"
                (click)="sendLink()">
                Save
            </div>
        </div>
    </div>
</div>