<div class="housekeeping-page width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
    <!-- <form [formGroup]="wakeupCallForm" class="width_100" *ngIf="globalService.isMobile$.getValue()">
        <div class="filter_sectionMobile" fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            <div class="filter_section width_100" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
                    <mat-icon matPrefix>search</mat-icon>
                    <input formControlName="search" matInput placeholder="Search here." #input
                        (keyup)="searchFilter($event.target.value)">
                    <button type="button" class="search_button" (click)="resetFilter()" *ngIf="searchValue">
                        <i class="material-icons">close</i>
                    </button>
                </mat-form-field>
            </div> -->
    <!-- <div class="button-section" fxLayout="center center" (click)="addWakeUpCall()">
                <div fxLayoutAlign="space-between center">
                    <mat-icon>add</mat-icon>
                    Add Wakeup Call
                </div>
            </div> 
        </div>
    </form> -->
    <div class="width_100 position_relative" fxLayout="row" fxLayoutAlign="start center">
        <mat-tab-group fxFlex="100" (selectedIndexChange)="setTableTools($event)" [selectedIndex]="activeTableIndex">
            <mat-tab label="Requests">
                <div class="cleaning_detail_container">
                    <div fxFlex="100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start">
                        <div class="cleaning_filter width_100" fxLayout="row" fxLayoutAlign="start center"
                            *ngIf="!globalService.isMobile$.getValue()">
                            <form [formGroup]="wakeupCallForm" class="width_100" fxLayout="row"
                                fxLayoutAlign="space-between center">
                                <div class="filter_searchBox" fxLayoutAlign="start center">
                                    <div class="filter_section" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-form-field class="filter_1" appearance="outline"
                                            fxLayoutAlign="start center">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <input formControlName="search" matInput placeholder="Search here." #input
                                                (keyup)="searchFilter($event.target.value)">
                                            <button type="button" class="search_button" (click)="resetFilter()"
                                                *ngIf="searchValue">
                                                <i class="material-icons">close</i>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="filter_Box" fxLayout="row" fxLayoutGap="15px"
                                    fxLayoutAlign="flex-end center">
                                    <div (click)="resetFilter()"
                                        [class.clear_filter_section-hidden]="!isValueInFilterForm"
                                        class="clear_filter_section cursor_pointer" fxLayoutGap="5px" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <div>Clear filter</div>
                                        <mat-icon [svgIcon]="'refresh'"></mat-icon>
                                    </div>
                                    <div class="filter_section dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Status" [value]="wakeupCallForm.get('status').value"
                                            (selectionChange)="status.setValue($event)" [selectOptions]="selectOptions">
                                        </app-select>
                                    </div>
                                    <!-- <div class="filter_section" fxLayout="row" fxLayoutAlign="start center">
                                        <app-select placeholder="Sort by" [noEmptyValue]="true" [value]="wakeupCallForm.get('sortBy').value"
                                            (selectionChange)="applyFilter($event.toString(), 'requestDataSource')"
                                            [selectOptions]="sortByOpt"></app-select>
                                    </div> -->
                                    <div class="filter_section sortSection dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <mat-form-field class="custom_Select">
                                            <mat-select placeholder="Sort by"
                                                (selectionChange)="onCustomeSorting($event)" formControlName="sortBy">
                                                <mat-option value="">Select</mat-option>
                                                <mat-option [value]="sort.value" *ngFor="let sort of sortingList;">
                                                    {{sort.key}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon class="mat-icon" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <span id="add_wakeup_call">
                                        <div class="button-section" fxLayout="center center" (click)="addWakeUpCall()">
                                            <div fxLayoutAlign="space-between center">
                                                <mat-icon>add</mat-icon>
                                                Add Wakeup Call
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="width_100 d-none" fxLayoutAlign="flex-end center">
                            <div class="sort-section" *ngIf="globalService.isMobile$.getValue()">
                                <div fxLayoutAlign="center center" (click)="openSortFilter()">
                                    <mat-icon [svgIcon]="'settings'"></mat-icon>
                                    Sort / Filter
                                </div>
                            </div>
                        </div> -->
                        <!--*ngIf="transactions && transactions.length > 0"-->
                        <div class="cleaning_body_container width_100" fxLayout="column" fxLayoutGap="30px"
                            fxLayoutAlign="center center">
                            <div class="request_list width_100">
                                <mat-table [dataSource]="requestDataSource" class="width_100" matSort
                                     matSortDisableClear>
                                    <ng-container matColumnDef="bookingId">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Booking ID
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="openWakeUpDetail(element)">
                                            {{element.bookingId}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="guestName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Guest Name
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="openWakeUpDetail(element)">
                                            {{element.guestName}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="room">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Room
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="openWakeUpDetail(element)">
                                            {{element.room}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Date
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="openWakeUpDetail(element)">
                                            {{element.date | date:'dd/MM/yyyy'}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="time">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Time
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="openWakeUpDetail(element)">
                                            {{element.time}} </mat-cell>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="note">
                                        <mat-header-cell [hidden]="true" *matHeaderCellDef mat-sort-header>
                                            Note
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.note}} </mat-cell>
                                    </ng-container> -->
                                    <ng-container matColumnDef="status">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="openWakeUpDetail(element)">
                                            <div class="wakeupCall_status" fxLayout="row" fxLayoutAlign="start center"
                                                [ngClass]="element.status">
                                                <div style="margin:0 auto">{{element.status}}</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                    [ngClass]="{'highlightRow': row?.status == 'Upcoming'}"></tr>
                                </mat-table>
                                <mat-paginator #wakeUpCallDataPaginator [pageSizeOptions]="[10, 25, 50]"
                                    *ngIf="!(globalService.isMobile$ | async)" showFirstLastButtons
                                    aria-label="Select page of wake up call">
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>