export interface UserModel {
    id: number;
    emailId: string;
    groupCode: number;
}

export interface MenuPermissionModel {
    id: number;
    userGroupId: number;
    menuId: number;
    menuCode: string;
    menuName: string;
    childMenuName: string;
    svgIcon: string;
    isInternal: number;
    isActive:number;
}
export interface MenuModel {
    id: number;
    name: string;
    isActive: number;
    menuItems: MenuItemModel[];
}
// export interface MenuListModel {
//   id: number;
//   menuName: string;
//   childMenuName: string;
// }

export interface HeaderModel {
    headerTitle: string;
    showBackButton: boolean;
    info1?: string;
    info2?: string;
    info3?: string;
    confirmBack?: boolean;
    navigationPage?: string;
    queryParams?: {};
    backActionTitle?: string;
    backActionMsg?: string;
}

export interface DialogModel {
    title?: string;
    message: string;
    action?: boolean;
    type?: string;
    buttonText?: string;
}

export interface SearchUserDialogModel {
    title: string;
    guestList: Array<ChatGuestListModel>;
}

export interface ViewRequestDialogModel {
    title: string;
    requestDetails: Array<HouseKeepingRequestModel>;
    houseKeepingCategory: Array<HouseKeepingCategoryModel>;
    referenceNumber: string;
}
export interface ViewCleaningDialogModel {
    title: string;
    requestDetails: Array<HouseKeepingCleaningModel>;
    houseKeepingCategory: Array<HouseKeepingCategoryModel>;
    referenceNumber: string;
}

export interface CreateRequestDialogModel {
    title: string;
    bookingId: string;
    roomNumber: string;
}

export interface HouseKeepingTimeModel {
    value: string;
    label: string;
}

export interface GuestModelSpa {
    id?: number;
    bookingNumber?: string;
    guestName?: string;
    room?: string;
}

export interface GuestListModel {
    id: number;
    bookingNumber: string;
    firstName: string;
    lastName: string;
    roomNumber: string;
    checkInDate: Date;
    checkOutDate: Date;
    nights: string;
    status?: string;
    statusCode: string;
    appActivity?: string;
    appActivityCode: string;
}

/** Spa & Wellness interface Start */
export interface SerivesTypeModel {
    id?: number;
    serviceName: string;
    code?: string;
    active?: boolean;
    icon?: string;
}

export interface paymentStatusModel {
    id?: number;
    name: string;
    code: string;
    active?: boolean;
    icon?: string;
}

export interface transactionStatusModel {
    id?: number;
    name: string;
    code: string;
    active?: boolean;
    icon?: string;
}


export interface SpaAndWellnessModel {
    invoiceNumber?: string;
    guestName?: string;
    room?: string
    appointmentDate?: string;
    appointmentTime?: string;
    appointmentDateTime?: any
    serviceType?: string;
    paymentStatus?: string;
    totalAmount?: string;
    transactionStatus?: string;
}

/** Spa & Wellness interface end */

/*Wake up call*/
export interface WakeUpCallModel {
    id: number;
    bookingNumber: string;
    guestName: string;
    room: string;
    date: string;
    time: string;
    note?: string;
    status: string;
}
export interface WakeUpCallDialogModel {
    id: number;
    name: string;
    requestDetails: WakeUpCallModel;
}
/*wake-up call end*/

export interface ChatGuestListModel {
    id: number;
    firstName: string;
    lastName: string;
    bookingNumber: string;
    checkInDate: Date;
    checkOutDate: Date;
    roomType: string;
    roomNumber: string;
    cancellationPolicy: string;
    board: string;
    adults: string;
    children: string;
}

export interface ChatModel {
    roomname: string;
    guestname: string;
    status: string;
    date?: string;
    time?: string;
    key?: string;
    unreadAdminMessages: number;
    unreadGuestMessages: number;
}

export interface GuestDetailModel {
    id: number;
    firstName: string;
    bookingId?: string;
    lastName: string;
    dob: string;
    age: string;
    gender: string;
    nationality: string;
    companyName: string;
    streetName: string;
    houseNumber: string;
    zipCode: string;
    town: string;
    state: string;
    country: string;
    email: string;
    mobileNumber: string;
    bookingNumber: string;
    checkInDate: Date;
    checkOutDate: Date;
    nights: string;
    numberOfRooms: string;
    roomType: string;
    roomId: number;
    roomNumber: number;
    board: string;
    cancellationPolicy: string;
    adults: string;
    children: string;
    extras: string;
    status: string;
    appActivity: string;
    guests?: any[];
    userId: string;
    checkInstatus?: any;
    secondGuest?: any;
    thirdGuest?: any;
    fourthGuest: any;
    isRegistrationFormFilled?: boolean;
    sopagoMinihotelInfo?:MinihotelCredentials;
    miniHotelReservationId?:string;
    miniHotelMemberSerial?:string;
    miniHotelBookingRoomTypeId?:any;
}

export interface MinihotelCredentials{
    username:string;
    password:string;
    miniHotel_Id:string;
}

export interface GuestStatusModel {
    name: string;
    code: string;
    id?: number;
    active?: boolean;
    icon?: string;
}

export interface GuestAppActivityModel {
    id: number;
    name: string;
    code: string;
}

export interface CountryListModel {
    name: string;
    code: string;
}

export interface HouseKeepingCategoryModel {
    id: number;
    name: string;
    houseKeepingRequest: Array<HouseKeepingRequestModel>;
}

export interface HouseKeepingCleaningModel {
    id: number;
    guestId: string;
    roomNumber: string;
    arrival: string;
    departure: string;
    cleaningStatus: number;
    cleaningDate: string;
    cleaningTime: string;
    dateTime: string;
}

export interface HouseKeepingRequestModel {
    id: number;
    guestId: string;
    roomNumber: string;
    type?: string;
    arrival: string;
    departure: string;
    requestStatus: number;
    houseKeepingCallId: number;
    requestCategory: number;
    requestItem: string;
    requestNote: string;
    requestQuantity: number;
    dateTime: string;
}

export interface GlobalOrderModel {
    orderNumber: number;
    delivery: string;
    payment: string;
    total: number;
    status: string;
    datePlaced: string;
    note?: string;
    paymentMethod: string;
    orders?: OrderInstanceModel[];
}

/** Reservation List */
export interface GlobalReservationModel {
    id?: number;
    date: string;
    guestName: string;
    people: number;
    reservationId: string;
    status: string;
    table: string;
    time: string;

}
export interface GlobalSpaModel {
    payment: string;
    status: string;
    orderNumber: number;
    bookingDate: string;
    amountDue: number;
    amountPaid: number;
    serviceType: string;
    price: string;
    quantity: number;
    total: any;
    note?: string;
    voucher?: number;
}

export interface OrderModel {
    title: string;
    description: string;
    price: any;
    discountedPrice: any;
    waitingTime?: number;
    menuCategoryId: string;
    img: string;
}

export interface OrderInstanceModel {
    order: OrderModel;
    amount: number;
}

export interface SortFilterConfigModel {
    label: string;
    mode: 'radio' | 'checkbox';
    items?: { label: string, checked: boolean, icon?: string }[];
}

export interface SelectOptionsModel {
    label: string;
    value: string | number;
}

export interface ChatLineModel {
    name: string;
    method: string;
    pipes?: {
        name: string;
        transformer: string;
    }[];
}
export interface MenuItemModel {
    id: number;
    menuCategoryId: number;
    name: string;
    description: string;
    preparationTime: string;
    price: number;
    discountedPrice: number;
    bannerUrl: string;
    quantity: number;
    status: number;
}
export interface CartModel {
    cartTotal: number;
    cartSubTotal: number;
    cartQuantity: number;
    cartTax: number;
    cartTaxPercent: number;
    cartVoucher: number;
    cartPaymentType: string;
    cartDeliveryType: string;
    cartDeliveryTime?: string;
    cartOrderNumber: string;
    cartPaymentStatus: string;
}
export interface NotificationModel {
    url: string,
    title: string,
    message: string,
    vibrate: any,
    icon: string,
    orderNumber?: number,
    serviceName: string,
    actions?: Actions
}
export interface Actions {
    action: string,
    title: string
}

export interface Member {
    serial: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    idNumber: string,
    roomType: string
}

export const DefaultErrorMessage = 'Oops! Something went wrong. Please try again.';
