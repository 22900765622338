import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { UserModel, MenuPermissionModel, HeaderModel, MenuItemModel, CartModel } from '../models/shared.model';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class GlobalServices {

    public sidebarState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public headerData$: BehaviorSubject<HeaderModel> = new BehaviorSubject<HeaderModel>(null);
    public headerBackAction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public headerClickAction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isSortPopupOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public tabIndexSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public KEY = 'S0p@g0@!@#$%';
    public DATA = 'SopagoApisByWebTechEvolution';

    public HOTELID: string;
    public userModel: UserModel;
    public menuPermission: MenuPermissionModel[] = [];


    public currency = '€';
    public errorMsg = 'Something went wrong! Please try again!!';
    public storageName = 'sopagoHotel';

    public monthList: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    public activeMenu = 0;
    public guestListCount = 0;
    public guestChatRequestCount = 0;
    public houseKeepingRequestCount = 0;
    public houseKeepingCount = 0;
    public foodAndBeveragesCount = 0;
    public tableReservationCount = 0;
    public spaAndWellnessCount = 0;
    public transportaitonCount = 0
    public wakeupcallCount = 0;
    // public menuCategoryLimit: number = 10;
    // public menuItemLimit: number = 10;
    // public spaCategoryLimit: number = 8;
    // public spaProductsItemLimit: number = 5;

    public selectedMenu: string;
    public selectedChildMenu: string;
    // public canEditHotel: boolean = false;
    public selectedGuestId: number;
    public selectedGuestName: string;




    get isAdminRole(): boolean {
        return this.userModel?.groupCode === 10;
    }

    get isReceptionRole(): boolean {
        return this.userModel?.groupCode === 11;
    }

    get isMobile(): boolean {
        return this.isMobile$.getValue();
    }

    constructor(
        public dialog: MatDialog,
        private route: Router,
    ) {
        this.HOTELID = JSON.parse(localStorage.getItem('loggedInHotelDetails'))
    }

    checkUserLoggedIn(): void {
        if (!environment.production) {
            this.setDummyData();
            this.HOTELID = JSON.parse(localStorage.getItem('loggedInHotelDetails'))
            this.currency = JSON.parse(localStorage.getItem('loggedInHotelCurrency'))
        }
        if (!this.getToken()) {
            this.logOut();
        }
    }

    setDummyData() {
        this.HOTELID = this.HOTELID;
        // this.guestListCount = 3;
        // this.guestChatRequestCount = 3;
        // this.houseKeepingRequestCount = 3;
        // this.foodAndBeveragesCount = 3;
        // this.spaAndWellnessCount = 4;
        this.userModel = {
            id: 1,
            emailId: localStorage.getItem('email'),
            groupCode: +localStorage.getItem('groupCode'),
        };
        this.menuPermission = [
            {
                id: 1,
                userGroupId: 10,
                menuId: 1,
                menuCode: 'pages/dashboard',
                menuName: 'Dashboard',
                childMenuName: 'general',
                svgIcon: 'dashboard',
                isInternal: 0
            },
            {
                id: 2,
                userGroupId: 10,
                menuId: 2,
                menuCode: 'pages/guests',
                menuName: 'Guest List',
                childMenuName: 'general',
                svgIcon: 'user',
                isInternal: 0
            },
            {
                id: 3,
                userGroupId: 10,
                menuId: 3,
                menuCode: 'pages/chat',
                menuName: 'Chat',
                childMenuName: 'general',
                svgIcon: 'customer-service',
                isInternal: 0
            },
            {
                id: 4,
                userGroupId: 10,
                menuId: 4,
                menuCode: 'pages/housekeeping',
                menuName: 'Housekeeping',
                childMenuName: 'services',
                svgIcon: 'broom',
                isInternal: 0
            },
            {
                id: 5,
                userGroupId: 10,
                menuId: 5,
                menuCode: 'pages/food-beverages',
                menuName: 'Bars & Restaurants',
                childMenuName: 'services',
                svgIcon: 'service-toolbox',
                isInternal: 0
            },
            {
                id: 6,
                userGroupId: 10,
                menuId: 6,
                menuCode: 'pages/spa-wellness',
                menuName: 'Spa & Wellness',
                childMenuName: 'services',
                svgIcon: 'spa',
                isInternal: 0
            },
            {
                id: 7,
                userGroupId: 10,
                menuId: 7,
                menuCode: 'pages/wake-up-call',
                menuName: 'Wake-Up call',
                childMenuName: 'services',
                svgIcon: 'alarm',
                isInternal: 0
            },
            {
                id: 8,
                userGroupId: 10,
                menuId: 8,
                menuCode: 'pages/calender',
                menuName: 'Calender',
                childMenuName: 'management',
                svgIcon: 'calendar',
                isInternal: 0
            },
            {
                id: 9,
                userGroupId: 10,
                menuId: 9,
                menuCode: 'pages/my-team',
                menuName: 'My Team',
                childMenuName: 'management',
                svgIcon: 'user-group',
                isInternal: 0
            },
            {
                id: 10,
                userGroupId: 10,
                menuId: 10,
                menuCode: 'pages/maintenance',
                menuName: 'Maintenance',
                childMenuName: 'management',
                svgIcon: 'service-toolbox',
                isInternal: 0
            },
            {
                id: 11,
                userGroupId: 10,
                menuId: 11,
                menuCode: 'pages/reports',
                menuName: 'Reports',
                childMenuName: 'management',
                svgIcon: 'reports',
                isInternal: 0
            },{
                id: 12,
                userGroupId: 10,
                menuId: 12,
                menuCode: 'pages/transportation',
                menuName: 'Transportation',
                childMenuName: 'services',
                svgIcon: 'cab',
                isInternal: 0
            },{
                id: 13,
                userGroupId: 10,
                menuId: 12,
                menuCode: 'pages/kids-club',
                menuName: 'Kids Club',
                childMenuName: 'services',
                svgIcon: 'baby-face-outline',
                isInternal: 0
            },
        ];
    }

    logOut(): void {
        this.HOTELID = null;
        this.userModel = null;
        localStorage.removeItem('encryptionKey');
        localStorage.removeItem('email');
        this.route.navigate(['/login']);
    }

    setToken(token): void {
        localStorage.setItem('encryptionKey', token);
    }

    getToken(): string {
        return localStorage.getItem('encryptionKey');
    }

    createToken(): void {
        const hmacsha1 = require('hmacsha1');
        this.setToken(hmacsha1(this.KEY, this.DATA));
    }

    // getSessionToken(value): string {
    //   var hmacsha1 = require('hmacsha1');
    //   var token = hmacsha1(this.KEY, value);
    //   return token;
    // }

    showAlert(msg: string) {
        this.dialog.open(AlertDialogComponent, {
            panelClass: 'custom_dialog_container',
            width: '400px',
            data: {
                title: 'Sopago Hotel Admin',
                message: msg,
                type: 'message'
            }
        });
    }

    navigateToPage(pageName: string, menuName: string, childMenu: string) {
        this.route.navigateByUrl(pageName);
        this.selectedMenu = menuName ? menuName : '';
        this.selectedChildMenu = childMenu ? childMenu : '';
    }

    validateAdminRoles(path: string) {
        const result = this.menuPermission.filter((obj) => obj.menuCode === path);
        return (result && result.length > 0);
    }

    bindDate(chats) {
        let sdate;
        const tDate = new Date();
        const tMonth = (tDate.getMonth() + 1) > 9 ? (tDate.getMonth() + 1) : (0 + '' + (tDate.getMonth() + 1));
        const toDate = (tDate.getDate()) > 9 ? (tDate.getDate()) : (0 + '' + (tDate.getDate()));
        const today = toDate + ',' + tMonth + ',' + tDate.getFullYear();

        chats.map((key: any) => {
            const cDate = key.date.split(' ')[0].split('-').toString();
            if (sdate === cDate) {
                key.date = null;
            } else {
                sdate = key.date.split(' ')[0].split('-').toString();
                if (cDate === today) {
                    key.date = 'Today';
                }
            }
            key.time = key.time.split(':')[0] + ':' + key.time.split(':')[1];
        });
        return chats;
    }


}
