<div class="share-link">
    <div *ngIf="!isEdit" class="share-link__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="share-link__header-title">
            ADD NEW APPOINTMENT
        </div>
        <div class="share-link__header-subtitle">
            Add new appointment by filling out the necessary details, such as the guest’s name, preferred date and time,
            and the service they desire.
        </div>
    </div>
    <div *ngIf="isEdit" class="share-link__header" fxLayout="column" fxLayoutAlign="center flex-start">
        <div class="share-link__header-title">
            EDIT APPOINTMENT
        </div>
        <div class="share-link__header-subtitle">
            Edit appointment by filling out the necessary details, such as the guest’s name, preferred date and time,
            and the service they desire.
        </div>
    </div>
    <form [formGroup]="addEventForm">
        <div class="share-link__main" id="add_appointment">
            <div class="width_100">
                <div class="share-link__main-section">
                    <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Service Type</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <mat-select formControlName="serviceType" (selectionChange)="serviceChange($event)">
                                <mat-optgroup *ngFor="let service of servicesGroup" [label]="service.name">
                                    <mat-option *ngFor="let sar of service.services" [value]="sar.spaId">
                                        {{sar.viewValue}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="addEventForm.controls['serviceType'].invalid && (addEventForm.controls['serviceType'].dirty || addEventForm.controls['serviceType'].touched)"
                            class="alert">
                            <mat-error *ngIf="(addEventForm.get('serviceType')).errors?.required">Service type is
                                required
                            </mat-error>
                        </div>
                    </div>
                    <!-- <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Appointment Title</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="appointmentTitle">
                        </mat-form-field>
                        <div *ngIf="addEventForm.controls['appointmentTitle'].invalid && (addEventForm.controls['appointmentTitle'].dirty || addEventForm.controls['appointmentTitle'].touched)"
                            class="alert">
                            <mat-error *ngIf="(addEventForm.get('appointmentTitle')).errors?.required">Title is
                                required
                            </mat-error>
                        </div>
                    </div> -->
                    <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Date</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <mat-datepicker-toggle matPrefix [for]="dtb"></mat-datepicker-toggle>
                            <input formControlName="dtb" matInput [matDatepicker]="dtb">
                            <mat-datepicker #dtb></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="addEventForm.controls['dtb'].invalid && (addEventForm.controls['dtb'].dirty || addEventForm.controls['dtb'].touched)"
                            class="alert">
                            <mat-error *ngIf="(addEventForm.get('dtb')).errors?.required">Date is
                                required
                            </mat-error>
                        </div>
                    </div>
                    <div class="width_100" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start start">
                        <div fxFlex="31.5" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>Duration</mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <mat-select formControlName="duration" (selectionChange)="durationChange($event)">
                                    <mat-option *ngFor="let time of endTime" [value]="time">
                                        <span>{{time}}</span>
                                    </mat-option>
                                </mat-select>
                                <!-- <mat-icon matPrefix><svg style="margin-bottom:5px" xmlns="http://www.w3.org/2000/svg"
                                    width="12" height="12" viewBox="0 0 12 12">
                                    <path id="clock-outline"
                                        d="M9,13.8A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8M9,3A6,6,0,1,1,3,9,6,6,0,0,1,9,3m.3,3V9.15l2.7,1.6-.45.738L8.4,9.6V6Z"
                                        transform="translate(-3 -3)" fill="#000000" />
                                </svg></mat-icon> -->
                            </mat-form-field>
                            <div *ngIf="addEventForm.controls['duration'].invalid && (addEventForm.controls['duration'].dirty || addEventForm.controls['duration'].touched)"
                                class="alert">
                                <mat-error *ngIf="(addEventForm.get('duration')).errors?.required">Duration is
                                    required
                                </mat-error>
                            </div>
                        </div>
                        <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>From</mat-label>
                            <mat-form-field class="width_100 time" appearance="outline">
                                <div matPrefix><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                        viewBox="0 0 12 12" style="margin: 2px 5px 4px 2px;">
                                        <path id="clock-outline"
                                            d="M9,13.8A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8M9,3A6,6,0,1,1,3,9,6,6,0,0,1,9,3m.3,3V9.15l2.7,1.6-.45.738L8.4,9.6V6Z"
                                            transform="translate(-3 -3)" fill="#868e96" />
                                    </svg> </div>
                                <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
                                    <input type="time" style="margin-top: 14px;" matInput onfocus="this.showPicker()"
                                        step="1800" (focusout)="onSearchChange($event.target.value)"
                                        formControlName="startTime">
                                    <!-- <mat-select formControlName="startHour" class="borderless">
                                        <mat-option *ngFor="let hour of hourList" [value]="hour">
                                            <span>{{hour}}</span>
                                        </mat-option>
                                    </mat-select>:
                                    <mat-select formControlName="startMin" class="borderless">
                                        <mat-option *ngFor="let min of minList" [value]="min">
                                            <span>{{min}}</span>
                                        </mat-option>
                                    </mat-select>                               -->
                                </div>
                            </mat-form-field>
                            <div *ngIf="addEventForm.controls['startTime'].invalid && (addEventForm.controls['startTime'].dirty || addEventForm.controls['startTime'].touched)"
                                class="alert">
                                <mat-error *ngIf="(addEventForm.get('startTime')).errors?.required">Time is
                                    required
                                </mat-error>
                            </div>
                        </div>
                        <div fxFlex="6" fxLayout="column" fxLayoutAlign="start start">
                            <div style="margin: 8px 0px;"></div>
                            <div class="width_100" appearance="outline">
                                <svg xmlns="http://www.w3.org/2000/svg" style="margin: 12px 4px;" width="10.56"
                                    height="10.56" viewBox="0 0 10.56 10.56">
                                    <path id="arrow-right"
                                        d="M2.667,7.333V8.666h8L7,12.333l.947.947L13.227,8,7.947,2.72,7,3.667l3.667,3.666Z"
                                        transform="translate(-2.667 -2.72)" fill="#868e96" />
                                </svg>
                            </div>
                        </div>
                        <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>Until</mat-label>
                            <mat-form-field class="width_100 time" appearance="outline">
                                <div matPrefix><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                        viewBox="0 0 12 12" style="margin: 2px 5px 4px 2px;">
                                        <path id="clock-outline"
                                            d="M9,13.8A4.8,4.8,0,1,0,4.2,9,4.8,4.8,0,0,0,9,13.8M9,3A6,6,0,1,1,3,9,6,6,0,0,1,9,3m.3,3V9.15l2.7,1.6-.45.738L8.4,9.6V6Z"
                                            transform="translate(-3 -3)" fill="#868e96" />
                                    </svg> </div>
                                <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
                                    <input type="time" style="margin-top: 14px;" matInput readonly
                                        formControlName="endTime">
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Total Amount</mat-label>
                        <mat-form-field class="width_100 totalAmount" appearance="outline">
                            <span matPrefix
                                style="color: black;margin-bottom: 100px;top: 0.2em !important;">{{currency}}
                                &nbsp;</span>
                            <input type="text" aria-label="Number" matInput formControlName="totalAmount">
                        </mat-form-field>
                        <!-- <mat-form-field class="width_100" appearance="outline">
                            <mat-select formControlName="guestName">
                                <mat-option *ngFor="let guest of userGroup" [value]="guest.bookingNumber">
                                    <span style="">{{guest.guest
                                        Name}}</span>
                                    <br>
                                    <span>{{guest.bookingNumber}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="guestName">
                        </mat-form-field> -->
                    </div>
                    <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Guest Name</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input type="text" aria-label="Number" matInput formControlName="guestName"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let guest of filteredUser | async" [value]="guest">
                                    {{guest.guestName}}, {{guest.bookingNumber}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div *ngIf="addEventForm.controls['guestName'].invalid && (addEventForm.controls['guestName'].dirty || addEventForm.controls['guestName'].touched)"
                            class="alert">
                            <mat-error *ngIf="(addEventForm.get('guestName')).errors?.required">Guest name is
                                required
                            </mat-error>
                        </div>
                        <!-- <mat-form-field class="width_100" appearance="outline">
                            <mat-select formControlName="guestName">
                                <mat-option *ngFor="let guest of userGroup" [value]="guest.bookingNumber">
                                    <span style="">{{guest.guest
                                        Name}}</span>
                                    <br>
                                    <span>{{guest.bookingNumber}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="guestName">
                        </mat-form-field> -->
                    </div>
                    <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Assignee</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <mat-select formControlName="assignee">
                                <mat-option [value]="option.value" *ngFor="let option of Assignee">{{ option.viewValue
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="addEventForm.controls['assignee'].invalid && (addEventForm.controls['assignee'].dirty || addEventForm.controls['assignee'].touched)"
                            class="alert">
                            <mat-error *ngIf="(addEventForm.get('assignee')).errors?.required">Assignee is
                                required
                            </mat-error>
                        </div>
                    </div>
                    <div class="width_100" fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Note</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <textarea matInput matInput formControlName="note" cdkAutosizeMinRows="5"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="share-link__summary" fxLayout="column" fxLayoutAlign="end end">
            <div class="share-link__summary-actions" fxLayoutAlign="end center">
                <div mat-ripple class="share-link__summary-clear cursor_pointer" (click)="onCancel()">
                    {{data?.order ? 'Close':'Cancel'}}
                </div>
                <a *ngIf="data?.order" (click)=" cancelRequest('cancel')"
                    [ngClass]="data?.order?.status == 'open' ? 'delete_button' : 'delete_buttonDisabled'">Cancel
                    Appointment</a>
         
                <a *ngIf="data?.order" (click)="onSubmit()" type="submit"
                [ngClass]="data?.order?.status == 'open' ? 'edit_button' : 'editDisabled'">Save</a>

                <div *ngIf="!data?.order" mat-ripple class="share-link__summary-show cursor_pointer" (click)="onSubmit()" type="submit">
                    Save
                </div>
            </div>
        </div>
    </form>
</div>