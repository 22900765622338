<div class="guest-page width_100"
    [class.expanded]="globalService.isSortPopupOpened$.getValue() && globalService.isMobile$.getValue()"
    fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
    <div class="guest-page__filter width_100" fxLayout="row" fxLayoutAlign="start center">
        <form [formGroup]="filterForm" class="width_100" fxLayoutAlign="space-between center">
            <div class="filter_searchBox" fxFlex="32" fxLayout="row" fxLayoutAlign="start center">
                <div class="filter_section" fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
                        <mat-icon matPrefix>search</mat-icon>
                        <input formControlName="name" matInput placeholder="Booking ref., name, company">
                        <button type="button" class="search_button" (click)="resetFilter()" *ngIf="searchValue">
                            <i class="material-icons">close</i>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="filter_Box" fxFlex="68" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
                <div (click)="resetFilter()" [class.clear_filter_section-hidden]="!isValueInFilterForm"
                    class="clear_filter_section cursor_pointer" fxLayoutGap="5px" fxLayout="row"
                    fxLayoutAlign="start center">
                    <div>Clear filter</div>
                    <mat-icon [svgIcon]="'refresh'"></mat-icon>
                </div>
                <div class="filter_section" fxLayout="row" fxLayoutAlign="start center"
                [ngStyle]="{'display':globalService.HOTELID==globalService.baobabHotelId?'none':''}">
                    <mat-form-field class="filter_2" appearance="outline" fxLayoutAlign="start center">
                        <mat-datepicker-toggle matPrefix [for]="checkInPicker"></mat-datepicker-toggle>
                        <input formControlName="checkInDate" matInput [matDatepicker]="checkInPicker"
                            placeholder="Check-In Date" disabled>
                        <mat-datepicker #checkInPicker disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="filter_section" fxLayout="row" fxLayoutAlign="start center"
                [ngStyle]="{'display':globalService.HOTELID==globalService.baobabHotelId?'none':''}">
                    <mat-form-field class="filter_2" appearance="outline" fxLayoutAlign="start center">
                        <mat-datepicker-toggle matPrefix [for]="checkOutPicker"></mat-datepicker-toggle>
                        <input formControlName="checkOutDate" matInput [matDatepicker]="checkOutPicker"
                            placeholder="Check-Out Date" disabled>
                        <mat-datepicker #checkOutPicker disabled="false"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutAlign="start center"
                [ngStyle]="{'display':globalService.HOTELID==globalService.baobabHotelId?'none':''}">
                    <app-select placeholder="Booking Status" [value]="status.value"
                        (selectionChange)="status.setValue($event)" [selectOptions]="guestStatus" [valueField]="'name'"
                        [labelField]="'name'"></app-select>
                </div>
                <div class="filter_section dropdownCustom" fxLayout="row" fxLayoutAlign="start center">
                    <app-select placeholder="App Activity" [value]="appActivity.value"
                        (selectionChange)="appActivity.setValue($event)" [selectOptions]="guestAppActivity"
                        [valueField]="'name'" [labelField]="'name'"></app-select>
                </div>
                <div class="sort-section">
                    <div fxLayoutAlign="center center" (click)="openSortFilter()">
                        <mat-icon [svgIcon]="'settings'"></mat-icon>
                        Sort / Filter
                    </div>
                </div>
                <span id="add_guest" [ngStyle]="{'display':globalService.HOTELID==globalService.baobabHotelId?'none':''}">
                    <div class="button-section" (click)="openGuestForm()">
                        <div fxLayoutAlign="center center">+ Add Guest</div>
                    </div>
                </span>
            </div>
        </form>
    </div>
    <div class="width_100" fxLayout="column" fxLayoutGap="30px" fxLayoutAlign="center center">
        <div class="guest_list width_100">
            <mat-table [dataSource]="dataSource" matSort matSortActive="checkInDate" matSortDirection="desc"
                class="width_100" [ngStyle]="{'pointer-events':globalService.HOTELID==globalService.baobabHotelId?'none':''}">

                <ng-container matColumnDef="bookingNumber">
                    <mat-header-cell mat-sort-header *matHeaderCellDef>
                        Booking ID
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" mat-cell *matCellDef="let element">
                        {{element.bookingNumber}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="firstName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Guest Name
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" *matCellDef="let element"> {{element.lastName}}
                      {{element.lastName!=""?',':""}}  {{element.firstName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="roomNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Room
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" *matCellDef="let element"> {{element.roomNumber}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="checkInDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Arrival
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" *matCellDef="let element"> 
                        {{globalService.HOTELID!=globalService.baobabHotelId ? ( element.checkInDate |
                        date: 'dd/MM/yyyy') : "-"}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="checkOutDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Depart
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" *matCellDef="let element">
                         {{globalService.HOTELID!=globalService.baobabHotelId ? (element.checkOutDate |
                        date: 'dd/MM/yyyy'):"-"}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="nights">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Nights
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" *matCellDef="let element"> 
                        {{globalService.HOTELID!=globalService.baobabHotelId ? (element.nights):"-"}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        Booking Status
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetail(element)" *matCellDef="let element">
                        <div class="guest_status" fxLayout="row" fxLayoutAlign="center center"
                            [ngClass]="element.statusCode"
                            *ngIf="globalService.HOTELID!=globalService.baobabHotelId">
                            <div *ngIf="element.status ==='Check-out in progress'">{{element.status ==
                                "Check out inprogress" ? 'COIP' : element.status}}</div>
                            <div>{{element.status == "Check out in progress " ? 'COIP' : element.status}}</div>
                        </div>
                        <div class="guest_status_baobab" fxLayout="row" fxLayoutAlign="center center"
                        [ngClass]="element.statusCode"
                        *ngIf="globalService.HOTELID==globalService.baobabHotelId">
                        <div>-</div>
                    </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="appActivity">
                    <mat-header-cell *matHeaderCellDef>
                        App Activity
                    </mat-header-cell>
                    <mat-cell (click)="openGuestDetailManage(element, true)" *matCellDef="let element">
                        <div class="guest_status" fxLayout="row" fxLayoutAlign="start center"
                            [ngClass]="element.appActivityCode">
                            <div style="margin:0 auto">{{element.appActivity}}</div>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef
                    [ngStyle]="{'display':globalService.HOTELID==globalService.baobabHotelId?'none':''}"
                    >
                        Action
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" 
                    [ngStyle]="{'display':globalService.HOTELID==globalService.baobabHotelId?'none':''}">
                        <button mat-button fxLayoutAlign="center center" [matMenuTriggerFor]="menu"
                            class="send_link_button">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" yPosition="below">
                            <button mat-menu-item (click)="openGuestDetail(element, true)">
                                Guest App User Management
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>


                <tr mat-row
                    [ngClass]="{'highlightRow': row?.status === 'Check out in progress ' || row?.status ==='Check-out in progress'}"
                    *matRowDef="let row; columns: displayedColumns;">
                </tr>


            </mat-table>
            <mat-paginator #guestListDataPaginator [pageSizeOptions]="[10, 25, 50]"
                *ngIf="!(globalService.isMobile$ | async)" showFirstLastButtons
                aria-label="Select page of periodic elements"></mat-paginator>
        </div>
    </div>
</div>