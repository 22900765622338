<div class="add-payment">
  <div class="add-payment__header" fxLayout="column" fxLayoutAlign="center flex-start">
    <div class="add-payment__header-title">ADD PAYMENT</div>
    <div class="add-payment__header-subtitle">
      Invoice no. {{ invoiceNumber }}
    </div>
    <div class="add-payment__header-close">
      <mat-icon class="close-icon" [svgIcon]="'close'" (click)="closePopup()"></mat-icon>
    </div>
  </div>
  <form [formGroup]="paymentForm" *ngIf="paymentForm" class="add-payment__main" [class.iphone-container]="isiOS"
    fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
    <div class="width_100 add-payment__main-orders" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-label>Invoice no.</mat-label>
        <mat-form-field class="width_100" appearance="outline">
          <input matInput formControlName="invoiceNumber" />
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-label>Total Amount </mat-label>

        <mat-form-field class="width_100" style="display: flex; align-items: center" appearance="outline">
          <input matInput thousandSeparator="" formControlName="amount" />
          <span matSuffix>{{ " " + globalService.currency }}</span>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <app-select class="filter_2 dropdownCustom" placeholder="Select" [label]="'Payment Method'"
          [customControl]="paymentForm.get('paymentMethod')" [noEmptyValue]="true"
          [value]="paymentForm.get('paymentMethod').value"
          (selectionChange)="paymentForm.get('paymentMethod').setValue($event)" [selectOptions]="[
            { label: 'Direct Billing', value: 'billing' },
            { label: 'Cash', value: 'cash' }
          ]"></app-select>
      </div>
    </div>
  </form>
  <div class="add-payment__summary" fxLayout="column" fxLayoutAlign="center center">
    <div class="add-payment__summary-actions" fxLayoutAlign="center center">
      <div mat-ripple class="add-payment__summary-clear" (click)="closePopup()">
        Cancel
      </div>
      <div mat-ripple class="add-payment__summary-show" [class.disabled]="!paymentForm.get('paymentMethod').value"
        (click)="savePayment()">
        Save
      </div>
    </div>
  </div>
</div>