import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";

import { GlobalServices } from "../../shared/services/global.services";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { SortFilterComponent } from "src/app/shared/components/sort-filter/sort-filter.component";
import { MatDialog } from "@angular/material/dialog";
import {
  SerivesTypeModel,
  SortFilterConfigModel,
  SpaAndWellnessModel,
  paymentStatusModel,
  transactionStatusModel,
} from "src/app/shared/models/shared.model";
import { sortFilterConfig } from "../housekeeping/const";

import { RestfullServices } from "src/app/shared/services/restfull.services";
import {
  MatSort,
  MatSortHeader,
  MatSortable,
  Sort,
} from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import {
  fillFilterValues,
  transformString,
} from "src/app/shared/functions/helpers";

import { LiveAnnouncer } from "@angular/cdk/a11y";
import { AddEventHandlerComponent } from "./add-event-handler/add-event-handler.component";

import {
  TemplateRef,
  ViewContainerRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { CalendarEvent, CalendarEventPanelMode } from "./spa-calendar.types";
import { TemplatePortal } from "@angular/cdk/portal";

import { CalendarOptions } from "@fullcalendar/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { EventInput, Calendar as FullCalendar } from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { DatePipe } from "@angular/common";
import { AlertDialogComponent } from "src/app/shared/components/alert-dialog/alert-dialog.component";
import { filter, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { SnackBarComponent } from "src/app/shared/components/snack-bar/snack-bar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as introJs from "intro.js";
import { SpaAddPaymentComponent } from "./spa-add-payment/spa-add-payment.component";

@Component({
  selector: "app-spa-wellness",
  templateUrl: "./spa-wellness.component.html",
  styleUrls: ["./spa-wellness.component.scss"],
})
export class SpaWellnessComponent implements OnInit, AfterViewInit {
  introJS = introJs.default();
  introJsCalendar = introJs.default();
  @ViewChild("spaTable", { static: false }) sort: MatSort;
  @ViewChild("spaListDataPaginator") set spaListDataPaginator(
    pager: MatPaginator
  ) {
    if (pager) this.dataSource.paginator = pager;
  }

  public searchValue: boolean = false;
  public activeTableIndex = 0;
  public sortFilterConfig: SortFilterConfigModel[] = sortFilterConfig;
  public dataSource = new MatTableDataSource<SpaAndWellnessModel>();
  sortedData: SpaAndWellnessModel[];

  public displayedColumns: string[] = [
    "invoiceNumber",
    "guestName",
    "room",
    "appointmentDate",
    "serviceType",
    "paymentStatus",
    "totalAmount",
    "transactionStatus",
    "action",
  ];

  public spaList: SpaAndWellnessModel[];
  public serviceList: SerivesTypeModel[];
  public paymentList: paymentStatusModel[];
  public transactionList: transactionStatusModel[];
  public sortingList = [];

  @ViewChild("fullCalendar") public _fullCalendar: FullCalendarComponent;
  @ViewChild("eventPanel") public _eventPanel: TemplateRef<any>;
  public _fullCalendarApi: FullCalendar;
  public _eventPanelOverlayRef: OverlayRef;
  panelMode: CalendarEventPanelMode = "view";

  public currentEvent;
  eventTitle: string = "";
  datetAndTime: string = "July 20, 2023, 09:00 - 09:45";
  Service: string = "Service";
  note: string = "Allergic to Nut oils.";
  guestName: string = "Zainabu Nyambura";
  assignee: string = "Kimani, Aysha";
  public timeData: string = "";
  public currentTime: any = this.datePipe.transform(new Date(), "hh:mm:ss");

  public transactionsForm = new UntypedFormGroup({
    name: new UntypedFormControl(),
    serviceType: new UntypedFormControl(),
    paymentStatus: new UntypedFormControl(),
    transactionStatus: new UntypedFormControl(),
    cleaningStatus: new UntypedFormControl(),
    sortingValue: new UntypedFormControl(),
  });

  private filterValues = {
    name: "",
    serviceType: "",
    paymentStatus: "",
    transactionStatus: "",
    sortingValue: "",
    cleaningStatus: "",
  };

  public get serviceType() {
    return this.transactionsForm.get("serviceType");
  }

  public get paymentStatus() {
    return this.transactionsForm.get("paymentStatus");
  }

  public get transactionStatus() {
    return this.transactionsForm.get("transactionStatus");
  }

  public get sortingValue() {
    return this.transactionsForm.get("sortingValue");
  }

  constructor(
    public globalService: GlobalServices,
    private dialog: MatDialog,
    private restfullServices: RestfullServices,
    public _overlay: Overlay,
    public _viewContainerRef: ViewContainerRef,
    public _changeDetectorRef: ChangeDetectorRef,
    public datePipe: DatePipe,
    public router: Router,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) { }
  public initalEvent = [
    // { title: 'EventName', start: '2023-11-14T10:30:00', end: '2023-11-14T11:30:00', guestName: 'kumar', services: 'spa', assign: 'kimani1', note: 'not12' },
    //{ title: 'EventName1', start: '2023-11-15T10:30:00', end: '2023-11-15T10:30:00', guestName: 'sara', services: 'spa', assign: 'kimani1', note: 'not12' }
  ];
  ngOnInit(): void {
    this.globalService.headerData$.next({
      headerTitle: "Spa & Wellness",
      showBackButton: false,
    });
    this.globalService.tabIndexSet.subscribe((res: any) => {
      if (res) {
        this.activeTableIndex = Number(res);
      }
    });
    this.currentTime = this.datePipe.transform(new Date(), "hh:mm:ss");
    this.globalService.spaAndWellnessCount = 0;
    this.getSpaAndWellness();
    this.formSubscribe();
    this.getFormsValue();
    this.transactionsForm.valueChanges.subscribe(
      (status) => (this.dataSource.data = this.spaList)
    );
    this.sortingList = [
      {
        key: "Date (Ascending)",
        value: {
          field: "appointmentDate",
          start: "asc",
        },
      },
      {
        key: "Date (Descending)",
        value: {
          field: "appointmentDate",
          start: "desc",
        },
      },
      {
        key: "Name A-Z",
        value: {
          field: "guestName",
          start: "asc",
        },
      },
      {
        key: "Name Z-A",
        value: {
          field: "guestName",
          start: "desc",
        },
      },
    ];
    this.restfullServices.getSpaCalendar().subscribe(
      (response) => {
        if (response) {
          //this.initalEvent.push(response);
          this.calendarOptions.events = response;
        }
      },
      () => this.globalService.showAlert(this.globalService.errorMsg)
    );
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          //step 15
          tooltipClass: "intro-step-15",
          title: "SPA & WELLNESS",
          element: document.getElementById("step-5_Spa & Wellness"),
          intro:
            "Here, you will find the detailed overview of all spa & wellness transactions. Experience the convenience of seamless scheduling and effective guest management.",
          position: "right",
        },
        {
          //step 16
          tooltipClass: "intro-step-16",
          title: "MANAGE ALL TRANSACTIONS",
          element: document.getElementById("all_transactions"),
          intro:
            "Efficiently manage all spa transactions, from receiving payments to updating statuses.",
          //position: 'bottom'
        },
      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: "tooltip",
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });

    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener("click", this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener("click", this.closeStep.bind(this));
      }
    }, 100);
  }
  toNumber(input: string) {
    if (input != undefined && input != "")
      return parseFloat(input.replace(/,/g, ""));
  }

  startTourCalendar() {
    this.introJsCalendar.setOptions({
      steps: [
        {
          //step 17
          tooltipClass: "intro-step-17",
          title: "CALENDAR",
          element: document.getElementById("calendar_view"),
          intro:
            "This is where you can manage and create new appointments while maintaining an overview of all scheduled engagements.",
          //position: 'right'
        },
        {
          //step 18
          tooltipClass: "intro-step-18",
          title: "CREATE NEW APPOINTMENT",
          element: document.getElementById("calendar_event"),
          intro:
            "Pick your preferred date and time by selecting the corresponding slots on the calendar.",
          // position: 'top'
        },
      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: "tooltip",
    });
    this.introJsCalendar.start();
    this.introJsCalendar.onbeforechange((targetElement) => {
      this.introJsCalendar.refresh();
    });

    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener("click", this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener("click", this.closeStep.bind(this));
      }
    }, 100);
  }

  skipTour(): void {
    this.introJS.exit();
    this.introJsCalendar.exit();
    this.restfullServices.removeLocalStorage("isTourRunning");
    window.location.href = "#/pages/spa-wellness";
  }

  closeStep(): void {
    window.location.href = "#/pages/spa-wellness";
  }

  ngAfterViewInit() {
    this._fullCalendarApi = this._fullCalendar.getApi();
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.spaListDataPaginator;
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning =
          this.restfullServices.getLocalStorage("isTourRunning");
        const isFirstLogin =
          this.restfullServices.getLocalStorage("IsFirstLogin");
        const guidedTourSpaFlag =
          this.restfullServices.getLocalStorage("guidedTourSpaFlag");
        if (
          isTourRunning == "true" &&
          isFirstLogin == "false" &&
          guidedTourSpaFlag == "false"
        ) {
          this.restfullServices.setLocalStorage("guidedTourSpaFlag", "true");
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }

  private formSubscribe() {
    fillFilterValues.bind(this)(
      "transactionsForm",
      "dataSource",
      "filterValues"
    );
  }

  public get isValueInFilterForm(): boolean {
    return !!Object.keys(this.transactionsForm.value).find(
      (item) => !!this.transactionsForm.value[item]
    );
  }

  public setTableTools(index: number) {
    this.activeTableIndex = index;
    window.dispatchEvent(new Event("resize"));
    if (this.activeTableIndex == 1) {
      setTimeout(() => {
        console.log(this.activeTableIndex, "active");
        if (!this.globalService.isMobile$.getValue()) {
          const isTourRunning =
            this.restfullServices.getLocalStorage("isTourRunning");
          const isFirstLogin =
            this.restfullServices.getLocalStorage("IsFirstLogin");
          const guidedTourSpaCalendarFlag =
            this.restfullServices.getLocalStorage("guidedTourSpaCalendarFlag");
          if (
            isTourRunning == "true" &&
            isFirstLogin == "false" &&
            guidedTourSpaCalendarFlag == "false"
          ) {
            this.restfullServices.setLocalStorage(
              "guidedTourSpaCalendarFlag",
              "true"
            );
            this.startTourCalendar();
            this.cdr.detectChanges();
          }
        }
      }, 500);
    }
  }

  searchFilter(value) {
    if (value) {
      this.searchValue = true;
    } else {
      this.searchValue = false;
    }
  }
  public addPayment(order) {
    this.dialog
      .open(SpaAddPaymentComponent, {
        panelClass: ["share-link_container", "border-top"],
        width: "440px",
        autoFocus: false,
        data: {
          order,
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        this.globalService.headerClickAction$.next(null);
        if (resp == true) {
          this.openSnackBar("Payment added successfully.");
          this.getSpaAndWellness();
          // window.location.reload();
        }
      });
  }

  resetFilter() {
    this.sortSpaList()
    this.dataSource.data = this.spaList;
    this.transactionsForm.reset();
    this.searchValue = false;
    // this.dataSource.data = this.dataSource.data.filter(
    //   (x) => x.transactionStatus == "Open"
    // );
    this.sort.sort({ id: 'appointmentDate', start: 'desc', disableClear: true });
    this.sort.sort({ id: 'guestName', start: 'asc', disableClear: true });
    this.sort.sort({ id: 'appointmentDate', start: 'asc', disableClear: true });
    // this.sort.direction="asc";
    //(this.sort.sortables.get('appointmentDate') as MatSortHeader)._setAnimationTransitionState({fromState: 'desc', toState: 'desc' });
  }

  private getFormsValue() {
    this.dataSource.filterPredicate = (
      data: any,
      filterPhrase: string
    ): boolean => {
      const searchString = JSON.parse(filterPhrase);
      let isNameMatch = true;
      let isServiceMatch = true;
      let isPaymentMatch = true;
      let isTransactionMatch = true;

      if (searchString.name) {
        this.searchValue = true;
        isNameMatch =
          transformString(data.invoiceNumber).indexOf(
            searchString.name.toLowerCase()
          ) !== -1 ||
          transformString(data.guestName).indexOf(
            searchString.name.toLowerCase()
          ) !== -1 ||
          transformString(data.room).indexOf(
            searchString.name.toLowerCase()
          ) !== -1;
      }

      if (searchString.serviceType) {
        isServiceMatch =
          data.serviceType
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(searchString.serviceType.toLowerCase()) !== -1;
      }

      if (searchString.paymentStatus) {
        isPaymentMatch =
          data.paymentStatus?.toString().trim().toLowerCase() ==
          searchString.paymentStatus.toLowerCase();
      }

      if (searchString.transactionStatus) {
        isTransactionMatch =
          data.transactionStatus
            ?.toString()
            .trim()
            .toLowerCase()
            .indexOf(searchString.transactionStatus.toLowerCase()) !== -1;
      }

      return (
        isNameMatch && isServiceMatch && isPaymentMatch && isTransactionMatch
      );
    };

    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  private getSpaAndWellness() {
    this.spaList = [];
    this.paymentList = [];
    this.serviceList = [];
    this.transactionList = [];
    this.globalService.showLoader$.next(true);
    this.restfullServices.getSpaAndWellnessData().subscribe(
      (response) => {
        this.mapStatusActivity(response);
      },
      () => this.globalService.showAlert(this.globalService.errorMsg)
    );
  }

  private mapStatusActivity(response) {
    this.paymentList = response.paymentStatus;
    this.serviceList = response.serviceType;
    this.transactionList = response.transactionStatus;
    response.spaList.forEach((element, index: number) => {
      if (element.transactionStatus === "open") {
        this.globalService.spaAndWellnessCount++;
        this.cdr.detectChanges()
      }

      response.paymentStatus.map((psValue) => {
        if (psValue.code === element.paymentStatus) {
          element.paymentStatus = psValue.name;
        }
      });
      response.transactionStatus.map((tsValue) => {
        if (tsValue.code === element.transactionStatus) {
          element.transactionStatus = tsValue.name;
        }
      });
    });
    this.spaList = response.spaList;
    this.sortSpaList()
    // Update the data source
    this.globalService.showLoader$.next(false);

    this.cdr.detectChanges()
    this.dataSource.data = this.spaList;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "appointmentDate":
          return new Date(item.appointmentDateTime);
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  onCustomeSorting(event) {
    this.sort.sort({
      id: event?.value?.field,
      start: event?.value?.start,
    } as MatSortable);
  }
  sortSpaList(){
    this.spaList.forEach((item: any) => {
      const [dayName, dayNum, month, year] = item.appointmentDate.split(' ');
      const [time, period] = item.appointmentTime.split(' ');
      const [hours, minutes] = time.split(':').map(Number);

      let appointmentDateTime = new Date(`${month} ${dayNum} ${year}`);
      appointmentDateTime.setHours(
        period.toLowerCase() === 'pm' && hours !== 12 ? hours + 12 : hours,
        minutes,
        0,
        0
      );
      item.appointmentDateTime = appointmentDateTime;
    });

    // Sort spaList by appointmentDateTime
    this.spaList.sort((a: any, b: any) => {
      return a.appointmentDateTime.getTime() - b.appointmentDateTime.getTime();
    });

    // Separate into todayAndNextDatesList and previousDatesList
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    let todayAndNextDatesList = [];
    let previousDatesList = [];

    this.spaList.forEach((item: any) => {
      if (item.appointmentDateTime < currentDate) {
        previousDatesList.push(item);
      } else {
        todayAndNextDatesList.push(item);
      }
    });

    // Concatenate sorted lists
    this.spaList = [...todayAndNextDatesList, ...previousDatesList];
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // public initalEvent = [
  //   // { title: 'EventName', start: '2023-11-14T10:30:00', end: '2023-11-14T11:30:00', guestName: 'kumar', services: 'spa', assign: 'kimani1', note: 'not12' },
  //    //{ title: 'EventName1', start: '2023-11-15T10:30:00', end: '2023-11-15T10:30:00', guestName: 'sara', services: 'spa', assign: 'kimani1', note: 'not12' }
  // ];

  public initalEvent1 = [
    {
      title: "EventName1",
      start: "2023-11-05T10:30:00",
      end: "2023-11-05T12:30:00",
      guestName: "sara",
      services: "spa",
      assign: "kimani1",
      note: "not12",
    },
  ];

  public calendarOptions: CalendarOptions = {
    plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin, listPlugin],
    locale: "en-GB",
    initialView: "timeGridWeek",
    headerToolbar: {
      left: "title",
      center: "",
      right: "",
    },
    eventContent: function (arg) {
      let eventData = document.createElement("i");
      let appendData = "";
      eventData.innerHTML =
        "<div style='font-weight:bold;margin:5px;font-size:12px'>" +
        arg.event._def.title +
        "</div>" +
        "<div style='margin:5px'>" +
        "<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'>" +
        "<path id='clock-outline' d='M7,10.2A3.2,3.2,0,1,0,3.8,7,3.2,3.2,0,0,0,7,10.2M7,3A4,4,0,1,1,3,7,4,4,0,0,1,7,3m.2,2V7.1L9,8.168l-.3.492L6.6,7.4V5Z' transform='translate(-3 -3)' fill='#495057'/>" +
        "</svg>" +
        "<span style='margin:5px;font-size:12px'>" +
        arg.timeText +
        "</span>" +
        "</div>";
      if (arg.event.extendedProps.assign != null) {
        appendData =
          "<div style='margin:5px'><svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'>" +
          "<path id='person-outline' d='M6.667,2.667a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0,1a1,1,0,1,0,1,1,1,1,0,0,0-1-1m0,3.5c1.335,0,4,.665,4,2v1.5h-8v-1.5c0-1.335,2.665-2,4-2m0,.95c-1.485,0-3.05.73-3.05,1.05v.55h6.1v-.55c0-.32-1.565-1.05-3.05-1.05Z' transform='translate(-2.667 -2.667)' fill='#495057'/>" +
          "</svg>" +
          "<span style='margin:5px;font-size:12px'>" +
          arg.event.extendedProps.assign +
          "</span>" +
          "</div>" +
          "<br/>";
      }
      eventData.innerHTML.concat(appendData);
      let arrayOfDomNodes = [eventData];
      return { domNodes: arrayOfDomNodes };
    },
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      meridiem: false,
    },
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    slotDuration: "00:60:00",
    scrollTime: this.currentTime,
    dayHeaderFormat: { weekday: "long", day: "numeric" },
    slotLabelInterval: "00:60:00",
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
  };

  handleDateClick(arg) {
    //Open Event Add Dialog
    this.dialog
      .open(AddEventHandlerComponent, {
        panelClass: ["share-link_container", "border-top"],
        width: "440px",
        data: {
          eventType: "isAdd",
          event: arg,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.data?.length != 0) {
          this.globalService.showLoader$.next(true);
          this.restfullServices.getSpaCalendar().subscribe((response) => {
            if (response) {
              this.calendarOptions.events = response;
              this.openSnackBar("Appointment successfully added");
              this.globalService.showLoader$.next(false);
            }
          });
          //# - Further Code to add new event
        }
      });
  }

  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ["custom-snackbar"],
      horizontalPosition: "right",
      verticalPosition: "top",
      data: {
        svgIcon: "check-circle",
        message,
      },
    });
  }

  handleEventClick(clickInfo) {
    this.currentEvent = clickInfo?.event?._def;
    this.eventTitle = clickInfo?.event?._def?.title;
    this.datetAndTime =
      this.datePipe.transform(
        clickInfo?.event?._def?.extendedProps.dateAndTime,
        "MMMM d, y, HH:mm"
      ) + this.timeData;
    this.Service = clickInfo?.event?._def?.extendedProps.services;
    this.assignee = clickInfo?.event?._def?.extendedProps.assign;
    this.guestName = clickInfo?.event?._def?.extendedProps.guestName.guestName;
    this.note = clickInfo?.event?._def?.extendedProps.note;

    this._openEventPanel(clickInfo);
  }

  onEventRender(info) {
    const fcListItemDate1 = `<span>Date</span> : <span>VALUE</span>`;
    console.log("calendarEvent", info);
    //document.getElementById(calendarEvent).innerHTML = calendarEvent.event._def.title + "<br/>" + calendarEvent.timeText
    // return info.event._def.title + "<br/>" + info.timeText;
  }

  public _openEventPanel(calendarEvent): void {
    this.currentEvent = calendarEvent;
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(calendarEvent.el)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: "start",
          originY: "bottom",
          overlayX: "end",
          overlayY: "bottom",
          offsetX: -8,
        },
        {
          originX: "end",
          originY: "bottom",
          overlayX: "start",
          overlayY: "bottom",
          offsetX: 8,
        },
        {
          originX: "end",
          originY: "top",
          overlayX: "start",
          overlayY: "top",
          offsetX: 8,
        },
        {
          originX: "start",
          originY: "top",
          overlayX: "end",
          overlayY: "top",
          offsetX: -8,
        },
      ]);

    // Create the overlay if it doesn't exist
    if (!this._eventPanelOverlayRef) {
      this._createEventPanelOverlay(positionStrategy);
    }
    // Otherwise, just update the position
    else {
      this._eventPanelOverlayRef.updatePositionStrategy(positionStrategy);
    }

    // Attach the portal to the overlay
    this._eventPanelOverlayRef.attach(
      new TemplatePortal(this._eventPanel, this._viewContainerRef)
    );

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Create the event panel overlay
   *
   * @public
   */
  public _createEventPanelOverlay(positionStrategy): void {
    // Create the overlay
    this._eventPanelOverlayRef = this._overlay.create({
      panelClass: ["calendar-event-panel"],
      backdropClass: "",
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      positionStrategy,
    });

    // Detach the overlay from the portal on backdrop click
    this._eventPanelOverlayRef.backdropClick().subscribe(() => {
      this._closeEventPanel();
    });
  }

  /**
   * Close the event panel
   *
   * @public
   */
  public _closeEventPanel(): void {
    // Detach the overlay from the portal
    this._eventPanelOverlayRef.detach();

    // Reset the panel and event edit modes
    this.panelMode = "view";

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  onPrevious() {
    let element = document.getElementById("current");
    element.classList.remove("currentWeek");
    this._fullCalendarApi.prev();
    // this.initalEvent.splice(0, this.initalEvent.length);
    // setTimeout(() => {
    //   this.initalEvent.push({ title: 'onPrevious', start: '2023-11-17T10:30:00', end: '2023-11-17T12:30:00', guestName: 'amit', services: 'spa', assign: 'kimani1', note: 'not12' })
    // }, 500);
  }

  onNext() {
    let element = document.getElementById("current");
    element.classList.remove("currentWeek");
    this._fullCalendarApi.next();
    // this.initalEvent.splice(0, this.initalEvent.length);
    // setTimeout(() => {
    //   this.initalEvent.push({ title: 'onNext', start: '2023-11-29T10:30:00', end: '2023-11-29T12:30:00', guestName: 'amit', services: 'spa', assign: 'kimani1', note: 'not12' })
    // }, 500);
  }

  onToday() {
    let element = document.getElementById("current");
    element.classList.add("currentWeek");
    this._fullCalendarApi.today();
    // this.initalEvent.splice(0, this.initalEvent.length);
    // setTimeout(() => {
    //   this.initalEvent.push({ title: 'onToday', start: '2023-11-21T10:30:00', end: '2023-11-21T12:30:00', guestName: 'amit', services: 'spa', assign: 'kimani1', note: 'not12' })
    // }, 500);
  }

  openSpaDetail(element: any) {
    this.router.navigate(["/pages/spa-wellness/spa-details"], {
      queryParams: {
        spaId: element,
      },
    });
  }

  onDeleteEvent() {
    this._closeEventPanel();
    //# - Furture Code to remove an event
    this.confirmDeleteAppointment(this.currentEvent);
  }

  confirmDeleteAppointment(currentEvent) {
    const str =
      "Are you sure you want to delete this appointment? Once deleted, it cannot be undone.";
    const alertTitle = "Delete Appointment?";
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      panelClass: "custom_dialog_container",
      width: "440px",
      data: {
        title: alertTitle,
        message: str,
        type: "confirmDelete",
      },
    });
    dialogRef
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe(() => {
        let payload = {
          spaId: currentEvent?.event?._def?.extendedProps?.itemId,
          guestId: currentEvent?.event?._def?.extendedProps?.guestName.id,
        };
        this.restfullServices.cancelSpa(payload).subscribe(
          (response) => {
            if (response === 1) {
              this.restfullServices.getSpaCalendar().subscribe(
                (response) => {
                  if (response) {
                    this.calendarOptions.events = response;
                    this.snackBar.openFromComponent(SnackBarComponent, {
                      panelClass: ["custom-snackbar"],
                      horizontalPosition: "right",
                      verticalPosition: "top",
                      data: {
                        svgIcon: "check-circle",
                        message: "Appointment successfully deleted!",
                      },
                    });
                  }
                },
                () => this.globalService.showAlert(this.globalService.errorMsg)
              );
            }
          },
          () => this.globalService.showAlert(this.globalService.errorMsg)
        );
      });
  }

  onClose() {
    this._closeEventPanel();
  }

  onEditEvent() {
    this.dialog
      .open(AddEventHandlerComponent, {
        panelClass: ["share-link_container", "border-top"],
        width: "440px",
        data: {
          eventType: "isEdit",
          event: this.currentEvent,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.data?.count != 0) {
          let event = res?.data?.eventObj?.value;
          let obj = {
            title: event?.appointmentTitle,
            start: "2023-08-17T10:30:00",
            end: "2023-08-17T12:30:00",
            guestName: event?.guestName,
            services: event?.serviceType,
            assign: event?.assignee,
            note: event?.note,
          };
          this.globalService.showLoader$.next(true);
          this.restfullServices.getSpaCalendar().subscribe((response) => {
            if (response) {
              this.calendarOptions.events = response;
              this.globalService.showLoader$.next(false);
            }
          });
          //# - Further Code to edit event
        }
        this._closeEventPanel();
      });
  }
  getInvoiceNo(invoiceNo: any) {
    let formattedInvoice = "00000000";
    invoiceNo = invoiceNo.toString();
    return `${formattedInvoice.slice(
      0,
      formattedInvoice.length - invoiceNo.length
    )}${invoiceNo}`;
  }
  getPaymentStatus(status: string) {
    if (status == "paid") return "Paid";
    if (status == "unpaid") return "Unpaid";
    if (status == "billing") return "Direct Billing";
    if (status == "cancelled") return "Cancelled";
    return status
  }
  addSpaAppointment() {
    this.dialog
      .open(AddEventHandlerComponent, {
        panelClass: ["share-link_container", "border-top"],
        width: "440px",
        data: {

        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res?.data?.count != 0) {
          let event = res?.data?.eventObj?.value;
          let obj = {
            title: event?.appointmentTitle,
            start: "2023-08-17T10:30:00",
            end: "2023-08-17T12:30:00",
            guestName: event?.guestName,
            services: event?.serviceType,
            assign: event?.assignee,
            note: event?.note,
          };
          this.globalService.showLoader$.next(true);
          this.restfullServices.getSpaCalendar().subscribe((response) => {
            if (response) {
              this.getSpaAndWellness();
              this.calendarOptions.events = response;
              this.globalService.showLoader$.next(false);
            }
          });
          //# - Further Code to edit event
        }
        this._closeEventPanel();
      });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}
