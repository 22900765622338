import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectOptionsModel, SortFilterConfigModel, WakeUpCallModel } from "src/app/shared/models/shared.model";
import { GlobalServices } from "src/app/shared/services/global.services";
import { sortFilterConfig } from "../housekeeping/const";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { RestfullServices } from "src/app/shared/services/restfull.services";
import { AddWakeupCallComponent } from "./add-wakeup-call/add-wakeup-call.component";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackBarComponent } from "src/app/shared/components/snack-bar/snack-bar.component";
import { MatPaginator } from "@angular/material/paginator";
import { ViewWakeUpCallComponent } from "./view-wake-up-call/view-wake-up-call.component";
import { MatSort, MatSortable } from "@angular/material/sort";
import { fillFilterValues, transformString } from "src/app/shared/functions/helpers";
import * as introJs from 'intro.js';

@Component({
  selector: "app-wake-up-call",
  templateUrl: "./wake-up-call.component.html",
  styleUrls: ["./wake-up-call.component.scss"],
})
export class WakeUpCallComponent implements OnInit, AfterViewInit {
  introJS = introJs.default();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('wakeUpCallDataPaginator') set wakeUpCallDataPaginator(pager: MatPaginator) {
    if (pager) this.requestDataSource.paginator = pager;
  }
  searchValue: boolean = false;
  hideNote: boolean = true;
  wakeUpList: any[];
  public activeTableIndex = 0;
  displayedColumns: string[] = [
    "bookingId",
    "guestName",
    "room",
    "date",
    "time",
    "status",
  ];
  public selectOptions: SelectOptionsModel[] = [
    {
      label: 'Upcoming',
      value: 1
    },
    {
      label: 'Completed',
      value: 2
    },
    {
      label: 'Missed',
      value: 3
    },
    {
      label: 'Failed',
      value: 4
    },
    {
      label: 'Cancelled',
      value: 5
    }

  ];
  public requestDataSource = new MatTableDataSource<any>();
  public sortFilterConfig: SortFilterConfigModel[] = sortFilterConfig;

  public wakeupCallForm = new UntypedFormGroup({
    search: new UntypedFormControl(),
    sortBy: new UntypedFormControl(),
    status: new UntypedFormControl(),
  });
  public sortingList = [
    {
      key: 'Date (Ascending)',
      value: {
        'field': 'date',
        'start': 'asc'
      }
    },
    {
      key: 'Date (Descending)',
      value: {
        'field': 'date',
        'start': 'desc'
      }
    },
    {
      key: 'Name A-Z',
      value: {
        'field': 'guestName',
        'start': 'asc'
      }
    },
    {
      key: 'Name Z-A',
      value: {
        'field': 'guestName',
        'start': 'desc'
      }
    }
  ]
  private filterValues = {
    status: '',
    sortBy: '',
    search: ''
  };


  constructor(public globalService: GlobalServices, public restfullServices: RestfullServices, private dialog: MatDialog, private snackBar: MatSnackBar, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.globalService.headerData$.next({
      headerTitle: "Wake-Up Call",
      showBackButton: false,
    });
    this.globalService.wakeupcallCount = 0;
    this.initializeData();
    this.formSubscribe();
    this.getFormsValue();
    this.wakeupCallForm.valueChanges.subscribe(status => this.requestDataSource.data = this.wakeUpList);
  }

  startTour() {
    this.introJS.setOptions({
      steps: [
        {
          //step 20
          tooltipClass: "intro-step-20",
          title: "WAKE-UP CALL",
          element: document.getElementById('step-5_Wake-Up call'),
          intro: "Easily handle and schedule wakeup calls, and manage guest wakeup call requests from the guest app to ensure guests never miss their plans.",
          position: 'right'
        },
        {
          //step 21
          tooltipClass: "intro-step-21",
          title: "ADDING NEW REQUEST",
          element: document.getElementById('add_wakeup_call'),
          intro: "Click ‘+ Add Wake-Up Call’ to manually add a new request from your guests.",
          // position: 'right'
        },
      ],
      disableInteraction: true,
      showBullets: false,
      showButtons: true,
      exitOnOverlayClick: false,
      keyboardNavigation: true,
      scrollToElement: true,
      scrollTo: 'tooltip',
    });
    this.introJS.start();
    this.introJS.onbeforechange((targetElement) => {
      this.introJS.refresh();
    });
    setTimeout(() => {
      if (document.querySelector(".intro-skip")) {
        let skipButton = document.querySelector(".intro-skip");
        skipButton.addEventListener('click', this.skipTour.bind(this));
      }

      if (document.querySelector(".introjs-skipbutton")) {
        let closeButton = document.querySelector(".introjs-skipbutton");
        closeButton.addEventListener('click', this.closeStep.bind(this));
      }
    }, 100);
  }

  skipTour(): void {
    this.introJS.exit();
    this.restfullServices.removeLocalStorage('isTourRunning');
    window.location.href = '#/pages/wake-up-call';
  }

  closeStep(): void {
    window.location.href = '#/pages/wake-up-call';
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.setupSorting();
      this.requestDataSource.sort = this.sort;
      this.requestDataSource.paginator = this.wakeUpCallDataPaginator;
      if (!this.globalService.isMobile$.getValue()) {
        const isTourRunning = this.restfullServices.getLocalStorage('isTourRunning');
        const isFirstLogin = this.restfullServices.getLocalStorage('IsFirstLogin');
        const guidedTourWakeUpFlag = this.restfullServices.getLocalStorage('guidedTourWakeUpFlag');
        if (isTourRunning == 'true' && isFirstLogin == 'false' && guidedTourWakeUpFlag == 'false') {
          this.restfullServices.setLocalStorage('guidedTourWakeUpFlag', 'true');
          this.startTour();
          this.cdr.detectChanges();
        }
      }
    }, 500);
  }

  public get status() {
    return this.wakeupCallForm.get('status');
  }

  private formSubscribe() {
    fillFilterValues.bind(this)('wakeupCallForm', 'requestDataSource', 'filterValues');
  }

  initializeData() {
    this.globalService.showLoader$.next(true);
    this.restfullServices.getWakeUpCall()
      .subscribe(
        response => {
          if (response) {
            this.wakeUpList = response;
            console.log(this.wakeUpList)
            this.wakeUpList.forEach((item: any) => {
              item.dateObj = this.parseDateTime(item.date, item.time);
            });

            // Sort wakeupList by dateObj
            this.wakeUpList.sort((a: any, b: any) => a.dateObj.getTime() - b.dateObj.getTime());

            // Separate into todayAndNextDatesList and previousDatesList
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            let todayAndNextDatesList = this.wakeUpList.filter(item => item.dateObj >= currentDate);
            let previousDatesList = this.wakeUpList.filter(item => item.dateObj < currentDate);

            // Concatenate sorted lists
            this.wakeUpList = [...todayAndNextDatesList, ...previousDatesList];
            console.log(this.wakeUpList)
            this.requestDataSource.data = this.wakeUpList;
            this.globalService.wakeupcallCount = this.requestDataSource.data.filter(x => x.status == "Upcoming").length;
            this.cdr.detectChanges();
            this.setupSorting();
            this.globalService.showLoader$.next(false);
          }
        },
        () => {
          this.globalService.showLoader$.next(false);
          this.globalService.showAlert(this.globalService.errorMsg);
        });
  }
  setupSorting() {
    this.requestDataSource.sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        case 'date':
          return item.dateObj;
        default:
          return item[property];
      }
    };
    this.requestDataSource.sort = this.sort;
  }
  parseDateTime(dateStr, timeStr) {
    const [month, day, year] = dateStr.split('/');
    let [time, period] = timeStr.split(' ').slice(-2);

    if (timeStr.includes('Today')) {
      // Use today's date
      const today = new Date();
      return new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${time} ${period}`);
    } else if (timeStr.includes('Tomorrow')) {
      // Use tomorrow's date
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return new Date(`${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()} ${time} ${period}`);
    } else {
      return new Date(`${year}-${month}-${day} ${time} ${period}`);
    }
  }
  addWakeUpCall() {
    this.dialog.open(AddWakeupCallComponent, {
      panelClass: ['share-link_container', 'border-top'],
      width: '440px',
      autoFocus: true
    }).afterClosed()
      .subscribe((resp) => {
        this.globalService.headerClickAction$.next(null);
        if (resp) {
          this.initializeData();
          this.openSnackBar('Wake-up call successfully booked');
        }
      });

  }

  openWakeUpDetail(request: any) {
    if (request.status != "Deleted") {
      const dialogRef = this.dialog.open(ViewWakeUpCallComponent, {
        panelClass: ['share-link_container', 'border-top'],
        width: '490px',
        data: {
          title: 'NEW REQUEST!',
          requestDetails: request,
        }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        //Update status
        if (result && result != "Success" && request.status !== result.status) {
          console.log(result)
          this.globalService.showLoader$.next(true);
          let obj = {
            id: request.id,
            status: result.status
          }
          this.restfullServices.updatewakeUpCallStatus(obj)
            .subscribe((response) => {
              this.initializeData();
              this.globalService.showLoader$.next(false);
            }, () => {
              this.globalService.showLoader$.next(false);
              this.globalService.showAlert(this.globalService.errorMsg);
            });

        }
        //Delete wake-up call
        if (result && result == true) {
          this.globalService.showLoader$.next(true);
          let status = "Deleted";
          let obj = {
            id: request.id,
            status: status
          }
          this.restfullServices.updatewakeUpCallStatus(obj)
            .subscribe((response) => {
              this.initializeData();
              this.globalService.showLoader$.next(false);
            }, () => {
              this.globalService.showLoader$.next(false);
              this.globalService.showAlert(this.globalService.errorMsg);
            });
        }
        //update wake up call
        if (result && result == "Success") {
          this.initializeData();
          this.formSubscribe();
          this.getFormsValue();
        }
      });
    }
  }

  public setTableTools(index: number) {

    if (!this.requestDataSource.sort) {
      this.requestDataSource.sort = this.sort;
    }

    if (!this.requestDataSource.paginator) {
      this.requestDataSource.paginator = this.wakeUpCallDataPaginator;
    }
  }

  openSortFilter() { }
  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ['custom-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: {
        svgIcon: 'check-circle',
        message
      }
    });
  }

  searchFilter(value) {
    if (value) {
      this.searchValue = true;
    } else {
      this.searchValue = false;
    }
  }

  resetFilter() {
    this.wakeupCallForm.reset();
    this.searchValue = false;
    // this.requestDataSource.data = this.requestDataSource.data.filter(x => x.status == "Upcoming")
    this.sort.sort({ id: null, start: 'desc', disableClear: false });
  }

  public get isValueInFilterForm(): boolean {
    return !!Object.keys(this.wakeupCallForm.value).find(item => !!this.wakeupCallForm.value[item]);
  }

  private getFormsValue() {
    this.requestDataSource.filterPredicate = (data: any, filterPhrase: string): boolean => {
      const searchString = JSON.parse(filterPhrase);
      let isStatusMatch = true;
      let isNameMatch = true;
      if (searchString.search) {
        this.searchValue = true;
        isNameMatch = (transformString(data.bookingId).indexOf(searchString.search.toLowerCase()) !== -1 ||
          transformString(data.guestName).indexOf(searchString.search.toLowerCase()) !== -1 ||
          transformString(data.room).indexOf(searchString.search.toLowerCase()) !== -1);
      }
      if (searchString.status) {
        var searchLabel = this.selectOptions.find(x => x.value == searchString.status).label;
        isStatusMatch = data.status?.toString().trim().toLowerCase().indexOf(searchLabel.toLowerCase()) !== -1;
      }

      return isStatusMatch && isNameMatch;
    }
    this.requestDataSource.filter = JSON.stringify(this.filterValues);
  }

  onCustomeSorting(event) {
    this.sort.sort(({ id: event?.value?.field, start: event?.value?.start }) as MatSortable);
  }
}
