<div class="edit-guest-page" fxLayout="column" fxFlex="100" fxLayoutAlign="start start">
    <form [formGroup]="guestForm" *ngIf="guestForm" class="container" fxLayout="column" fxLayoutGap="20px"
        fxLayoutAlign="start center" id="guest_info">
        <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">PERSONAL
            INFO
        </div>
        <!--    desktop-->
        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="!globalService.isMobile$.getValue()">
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                <!-- <div class="width_30" fxLayout="column" fxLayoutAlign="start start" *ngIf="guestType!='Discover & Stay'">
                    <mat-label>Guest Type<span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="guestType">
                    </mat-form-field>                    
                </div> -->
                <div class="width_30 filter_Box addGuestBox">
                    <div class="width_30" fxLayoutGap="5px" class="filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <!-- <app-select label="Guest Type" [customControl]="getFormControl('guestType')"
                            [value]="getFormControl('guestType').value"
                            (selectionChange)="getFormControl('guestType').setValue($event);"
                            [selectOptions]="guestTypeOpt"></app-select> -->
                        <app-select label="Guest Type" [customControl]="getFormControl('guestType')"
                            [value]="getFormControl('guestType').value" (selectionChange)="guestTypeChange($event)"
                            [selectOptions]="guestTypeOpt"></app-select>
                    </div>
                </div>
                <!-- <div class="width_30" fxLayout="column" fxLayoutAlign="start start" *ngIf="guestType=='In-House'"> -->
                <div class="width_30" fxLayout="column" fxLayoutAlign="start start" *ngIf="isBookingInfoVisible">
                    <mat-label>Booking ID<span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="bookingNumber" placeholder="SOPddmmyyxx">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['bookingNumber'].invalid && (guestForm.controls['bookingNumber'].dirty || guestForm.controls['bookingNumber'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('bookingNumber')).errors?.required">Booking ID is required
                        </mat-error>
                    </div>
                </div>
                <div class="width_30">
                </div>
            </div>
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Last Name <span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="lastName">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['lastName'].invalid && (guestForm.controls['lastName'].dirty || guestForm.controls['lastName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('lastName')).errors?.required">Last name is required
                        </mat-error>
                    </div>
                </div>
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>First Name <span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="firstName">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['firstName'].invalid && (guestForm.controls['firstName'].dirty || guestForm.controls['firstName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('firstName')).errors?.required">First name is required
                        </mat-error>
                    </div>
                </div>
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Date of Birth</mat-label>
                    <!-- <span class="color_red">*</span></mat-label> -->
                    <mat-form-field class="filter_1 width_100" appearance="outline" fxLayoutAlign="start center">
                        <mat-datepicker-toggle matPrefix [for]="dob"></mat-datepicker-toggle>
                        <input formControlName="dob" matInput [matDatepicker]="dob" placeholder="DD/MM/YYYY"
                            (dateChange)="getAge($event)" (blur)="onDateBlur($event,'dob')">
                        <mat-datepicker #dob></mat-datepicker>
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['dob'].invalid"
                        class="alert"> -->
                    <!-- <mat-error *ngIf="(guestForm.get('dob')).errors?.required">Date of Birth is required</mat-error> -->
                    <!-- <mat-error *ngIf="(guestForm.get('dob')).errors?.dateVaidator">Date of Birth is invalid
                        </mat-error>
                    </div> -->
                </div>
            </div>

            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayoutGap="5px" class="width_15 margin_right_15" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Age</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="age" [value]="age" readonly>
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['age'].invalid && (guestForm.controls['age'].dirty || guestForm.controls['age'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('age')).errors?.required">Age is required</mat-error>
                    </div> -->
                </div>
                <div class="width_30 filter_Box addGuestBox">
                    <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Gender" [customControl]="getFormControl('gender')"
                            [value]="getFormControl('gender').value"
                            (selectionChange)="getFormControl('gender').setValue($event)"
                            [selectOptions]="genderOpt"></app-select>
                    </div>
                </div>
                <div class="width_30 filter_Box addGuestBox">
                    <div fxLayoutGap="5px" class="filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Nationality" [customControl]="getFormControl('nationality')"
                            [value]="getFormControl('nationality').value"
                            (selectionChange)="getFormControl('nationality').setValue($event)"
                            [selectOptions]="countryList" [valueField]="'name'" [labelField]="'name'"></app-select>
                    </div>
                </div>
            </div>
        </div>

        <!--    mobile-->
        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="globalService.isMobile$.getValue()">
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="width_30 filter_Box addGuestBox">
                    <div class="width_30" fxLayoutGap="5px" class="filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Guest Type" [customControl]="getFormControl('guestType')"
                            [value]="getFormControl('guestType').value" (selectionChange)="guestTypeChange($event)"
                            [selectOptions]="guestTypeOpt"></app-select>
                    </div>
                </div>
                <div class="width_30" fxLayout="column" fxLayoutAlign="start start" *ngIf="isBookingInfoVisible">
                    <mat-label>Booking ID<span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="bookingNumber" placeholder="SOPddmmyyxx">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['bookingNumber'].invalid && (guestForm.controls['bookingNumber'].dirty || guestForm.controls['bookingNumber'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('bookingNumber')).errors?.required">Booking ID is required
                        </mat-error>
                    </div>
                </div>
                <div class="width_30">
                </div>
            </div>
            <div class="container_body width_100" fxLayout="column" fxLayoutGap="10px">
                <div fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Last Name <span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="lastName">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['lastName'].invalid && (guestForm.controls['lastName'].dirty || guestForm.controls['lastName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('lastName')).errors?.required">Last name is required
                        </mat-error>
                    </div>
                </div>

                <div fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>First Name <span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="firstName">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['firstName'].invalid && (guestForm.controls['firstName'].dirty || guestForm.controls['firstName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('firstName')).errors?.required">First name is required
                        </mat-error>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Date of Birth</mat-label>
                        <mat-form-field class="filter_1 width_100" appearance="outline" fxLayoutAlign="start center">
                            <mat-datepicker-toggle matPrefix [for]="dob"></mat-datepicker-toggle>
                            <input formControlName="dob" matInput [matDatepicker]="dob" placeholder="DD/MM/YYYY"
                                (dateChange)="getAge($event)" (blur)="onDateBlur($event,'dob')">
                            <mat-datepicker #dob></mat-datepicker>
                        </mat-form-field>
                        <!-- <div *ngIf="guestForm.controls['dob'].invalid"
                            class="alert"> -->
                        <!-- <div *ngIf="guestForm.controls['dob'].invalid && (guestForm.controls['dob'].dirty || guestForm.controls['dob'].touched)"
                            class="alert"> -->
                        <!-- <mat-error *ngIf="(guestForm.get('dob')).errors?.required">Date of Birth is required
                            </mat-error> -->
                        <!-- <mat-error *ngIf="(guestForm.get('dob')).errors?.dateVaidator">Date of Birth is invalid
                            </mat-error>
                        </div> -->
                    </div>

                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <app-select label="Nationality" [customControl]="getFormControl('nationality')"
                            [value]="getFormControl('nationality').value"
                            (selectionChange)="getFormControl('nationality').setValue($event)"
                            [selectOptions]="countryList" [valueField]="'name'" [labelField]="'name'"></app-select>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Age</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="age" [value]="age" readonly>
                        </mat-form-field>
                        <!-- <div *ngIf="guestForm.controls['age'].invalid && (guestForm.controls['age'].dirty || guestForm.controls['age'].touched)" class="alert">
                          <mat-error *ngIf="(guestForm.get('age')).errors?.required">Age is required</mat-error>
                        </div> -->
                    </div>

                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <app-select label="Gender" [customControl]="getFormControl('gender')"
                            [value]="getFormControl('gender').value"
                            (selectionChange)="getFormControl('gender').setValue($event)"
                            [selectOptions]="genderOpt"></app-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">ADDRESS
        </div>
        <!--    desktop-->
        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="!globalService.isMobile$.getValue()">
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayoutGap="5px" class="width_100" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Company Name</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="companyName">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['companyName'].invalid && (guestForm.controls['companyName'].dirty || guestForm.controls['companyName'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('companyName')).errors?.required">Company name is required</mat-error>
                    </div> -->
                </div>
            </div>

            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Street Address</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="streetName">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['streetName'].invalid && (guestForm.controls['streetName'].dirty || guestForm.controls['streetName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('streetName')).errors?.required">Street name is required
                        </mat-error>
                    </div> -->
                </div>

                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>City</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="town">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['town'].invalid && (guestForm.controls['town'].dirty || guestForm.controls['town'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('town')).errors?.required">Town is required</mat-error>
                    </div> -->
                </div>

                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>State/Province</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="state">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['state'].invalid && (guestForm.controls['state'].dirty || guestForm.controls['state'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('state')).errors?.required">State is required</mat-error>
                    </div> -->
                </div>

                <!-- <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>House/Building No.</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="houseNumber">
                    </mat-form-field> -->
                <!-- <div *ngIf="guestForm.controls['houseNumber'].invalid && (guestForm.controls['houseNumber'].dirty || guestForm.controls['houseNumber'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('houseNumber')).errors?.required">House/Building No. is
                            required
                        </mat-error>
                    </div> -->
                <!-- </div> -->

            </div>

            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Zip Code</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="zipCode">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['zipCode'].invalid && (guestForm.controls['zipCode'].dirty || guestForm.controls['zipCode'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('zipCode')).errors?.required">Zip code is required</mat-error>
                    </div> -->
                </div>
                <div class="width_30 filter_Box addGuestBox">
                    <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Country" [customControl]="getFormControl('country')"
                            [value]="getFormControl('country').value"
                            (selectionChange)="getFormControl('country').setValue($event)" [selectOptions]="countryList"
                            [valueField]="'name'" [labelField]="'name'"></app-select>
                    </div>
                </div>
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start"
                    style="opacity: 0;">
                    <mat-label>City</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="town">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['town'].invalid && (guestForm.controls['town'].dirty || guestForm.controls['town'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('town')).errors?.required">Town is required</mat-error>
                    </div> -->
                </div>

                <!-- <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>State</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="state">
                    </mat-form-field> -->
                <!-- <div *ngIf="guestForm.controls['state'].invalid && (guestForm.controls['state'].dirty || guestForm.controls['state'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('state')).errors?.required">State is required</mat-error>
                    </div> -->
                <!-- </div> -->
            </div>
        </div>

        <!--    mobile-->
        <div class="container_section width_100" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center"
            *ngIf="globalService.isMobile$.getValue()">
            <div class="container_body width_100" fxLayoutAlign="start center">
                <div fxLayoutGap="5px" class="width_100" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Company Name</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="companyName">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['companyName'].invalid && (guestForm.controls['companyName'].dirty || guestForm.controls['companyName'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('companyName')).errors?.required">Company name is required</mat-error>
                    </div> -->
                </div>
            </div>

            <div class="container_body width_100" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Street Name <span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="streetName">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['streetName'].invalid && (guestForm.controls['streetName'].dirty || guestForm.controls['streetName'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('streetName')).errors?.required">Street name is required
                        </mat-error>
                    </div> -->
                </div>

                <div fxLayoutGap="5px" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>House/Building No.</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="houseNumber">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['houseNumber'].invalid && (guestForm.controls['houseNumber'].dirty || guestForm.controls['houseNumber'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('houseNumber')).errors?.required">House/Building No. is
                            required
                        </mat-error>
                    </div> -->
                </div>

            </div>

            <div class="container_body width_100" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Zip Code</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="zipCode">
                        </mat-form-field>
                        <!-- <div *ngIf="guestForm.controls['zipCode'].invalid && (guestForm.controls['zipCode'].dirty || guestForm.controls['zipCode'].touched)"
                            class="alert">
                            <mat-error *ngIf="(guestForm.get('zipCode')).errors?.required">Zip code is required
                            </mat-error>
                        </div> -->
                    </div>
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Town</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="town">
                        </mat-form-field>
                        <!-- <div *ngIf="guestForm.controls['town'].invalid && (guestForm.controls['town'].dirty || guestForm.controls['town'].touched)"
                            class="alert">
                            <mat-error *ngIf="(guestForm.get('town')).errors?.required">Town is required</mat-error>
                        </div> -->
                    </div>
                </div>


                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>State</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="state">
                        </mat-form-field>
                        <!-- <div *ngIf="guestForm.controls['state'].invalid && (guestForm.controls['state'].dirty || guestForm.controls['state'].touched)" class="alert">
                          <mat-error *ngIf="(guestForm.get('state')).errors?.required">State is required</mat-error>
                        </div> -->
                    </div>

                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <app-select label="Country" [customControl]="getFormControl('country')"
                            [value]="getFormControl('country').value"
                            (selectionChange)="getFormControl('country').setValue($event)" [selectOptions]="countryList"
                            [valueField]="'name'" [labelField]="'name'"></app-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center">CONTACT
        </div>

        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayoutGap="5px" class="width_30 margin_right_5" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Contact No.
                        <!-- <span class="color_red">*</span> -->
                    </mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="mobileNumber">
                    </mat-form-field>
                    <!-- <div *ngIf="guestForm.controls['mobileNumber'].invalid && (guestForm.controls['mobileNumber'].dirty || guestForm.controls['mobileNumber'].touched)"
                        class="alert"> -->
                    <div>
                        <!-- <mat-error *ngIf="(guestForm.get('mobileNumber')).errors?.required">Contact No. is required
                        </mat-error> -->
                        <!-- <mat-error *ngIf="(guestForm.get('mobileNumber')).errors?.pattern">Invalid contact number
                        </mat-error> -->
                    </div>
                </div>

                <div fxLayoutGap="5px" class="width_65" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Email <span class="color_red">* </span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="email">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['email'].invalid && (guestForm.controls['email'].dirty || guestForm.controls['email'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('email')).errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="(guestForm.get('email')).errors?.pattern">Please enter a valid email address
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center"
            *ngIf="guestDetail?.guestType=='In-House'"> -->
        <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center"
            *ngIf="isBookingInfoVisible">
            BOOKING INFO
        </div>

        <!--        desktop-->
        <!-- <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="guestDetail?.guestType=='In-House'"> -->
        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="isBookingInfoVisible">
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="!(globalService.isMobile$.getValue())" [ngClass]="guestDetail?.guestType=='Discover & Stay' || guestDetail?.guestType == 'Day Guest'?'disabled-color':''">

                <div fxLayoutGap="5px" class="width_30 margin_right_5" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Arrival <span class="color_red">*</span></mat-label>
                    <mat-form-field class="filter_1 width_100" appearance="outline" fxLayoutAlign="start center" [ngClass]="guestDetail?.guestType=='Discover & Stay' || guestDetail?.guestType == 'Day Guest'?'opacity_down':''">
                        <mat-datepicker-toggle matPrefix [for]="checkInDate"></mat-datepicker-toggle>
                        <!-- <input formControlName="checkInDate" matInput [matDatepicker]="checkInDate" [min]="minDate"
                            placeholder="DD/MM/YYYY" (dateChange)="setDates()" (blur)="onDateBlur($event,'checkIn')"> -->
                        <input formControlName="checkInDate" matInput [matDatepicker]="checkInDate"
                            placeholder="DD/MM/YYYY" (dateChange)="setDates()" (blur)="onDateBlur($event,'checkIn')">
                        <mat-datepicker #checkInDate></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['checkInDate'].invalid && (guestForm.controls['checkInDate'].dirty || guestForm.controls['checkInDate'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('checkInDate')).errors?.required">
                            Arrival Date is required
                        </mat-error>
                    </div>
                </div>

                <div fxLayoutGap="5px" class="width_30 margin_right_5" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Departure <span class="color_red">*</span></mat-label>
                    <mat-form-field class="filter_1 width_100" appearance="outline" fxLayoutAlign="start center" [ngClass]="guestDetail?.guestType=='Discover & Stay' || guestDetail?.guestType == 'Day Guest'?'opacity_down':''">
                        <mat-datepicker-toggle matPrefix [for]="checkOutDate"></mat-datepicker-toggle>
                        <input formControlName="checkOutDate" matInput [matDatepicker]="checkOutDate"
                            placeholder="DD/MM/YYYY" (dateChange)="setDates()" (blur)="onDateBlur($event,'checkOut')">
                        <mat-datepicker #checkOutDate></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['checkOutDate'].invalid && (guestForm.controls['checkOutDate'].dirty || guestForm.controls['checkOutDate'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('checkOutDate')).errors?.required">Departure Date is required
                        </mat-error>
                        <mat-error
                            *ngIf="!((guestForm.get('checkOutDate')).errors?.required)
                            && checkOutDateError">Departure
                            Date must be later than the arrival date.
                        </mat-error>
                    </div>                  
                </div>               
                <div fxLayoutGap="5px" class="width_15" fxLayout="column" fxLayoutAlign="start start" *ngIf="guestDetail?.guestType!='Day Guest' && guestDetail?.guestType!='Discover & Stay'">
                    <mat-label>Nights</mat-label>
                    <mat-form-field class="width_100 disabled-color" appearance="outline">
                        <input matInput formControlName="nights">
                    </mat-form-field>
                </div>

            </div>         
            <!--            mobile-->
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="globalService.isMobile$.getValue()">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Arrival <span class="color_red">*</span></mat-label>
                        <mat-form-field class="filter_1 width_100" appearance="outline" fxLayoutAlign="start center">
                            <mat-datepicker-toggle matPrefix [for]="checkInDate"></mat-datepicker-toggle>
                            <input formControlName="checkInDate" matInput [matDatepicker]="checkInDate" [min]="minDate"
                                placeholder="DD/MM/YYYY" (dateChange)="setDates()"
                                (blur)="onDateBlur($event,'checkIn')">
                            <mat-datepicker #checkInDate></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="guestForm.controls['checkInDate'].invalid && (guestForm.controls['checkInDate'].dirty || guestForm.controls['checkInDate'].touched)"
                            class="alert">
                            <mat-error *ngIf="(guestForm.get('checkInDate')).errors?.required">Arrival Date is
                                required
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Departure <span class="color_red">*</span></mat-label>
                        <mat-form-field class="filter_1 width_100" appearance="outline" fxLayoutAlign="start center">
                            <mat-datepicker-toggle matPrefix [for]="checkOutDate"></mat-datepicker-toggle>
                            <input formControlName="checkOutDate" matInput [matDatepicker]="checkOutDate"
                                [min]="guestForm.get('checkInDate').value" placeholder="DD/MM/YYYY"
                                (dateChange)="setDates()" (blur)="onDateBlur($event,'checkOut')">
                            <mat-datepicker #checkOutDate></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="guestForm.controls['checkOutDate'].invalid && (guestForm.controls['checkOutDate'].dirty || guestForm.controls['checkOutDate'].touched)"
                            class="alert">
                            <mat-error *ngIf="(guestForm.get('checkOutDate')).errors?.required">Departure Date is
                                required
                            </mat-error>
                            <mat-error
                            *ngIf="!((guestForm.get('checkOutDate')).errors?.required)
                            && checkOutDateError">Departure
                            Date must be later than the check-in date.
                        </mat-error>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 10px" *ngIf="guestDetail?.guestType!='Day Guest' && guestDetail?.guestType!='Discover & Stay'">
                    <div fxLayoutGap="5px" class="width_45 disabled-color" fxLayout="column"
                        fxLayoutAlign="start start">
                        <mat-label>Nights</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="nights">
                        </mat-form-field>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center"
            *ngIf="guestDetail?.guestType=='In-House'"> -->
        <div class="container_title width_100 extra_margin_top" fxLayout="row" fxLayoutAlign="start center"
            *ngIf="guestDetail?.guestType!='Discover & Stay' && guestDetail?.guestType != 'Day Guest' && getFormControl('guestType').value =='In-House'">
            ROOM INFO
        </div>
        <!--    desktop-->
        <!-- <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="!globalService.isMobile$.getValue() && guestDetail?.guestType=='In-House'"> -->
        <div class="container_section width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center"
            *ngIf="!globalService.isMobile$.getValue() && guestDetail?.guestType!='Discover & Stay' && guestDetail?.guestType != 'Day Guest' && getFormControl('guestType').value =='In-House'">
            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayoutGap="5px" class="width_30 margin_right_5" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>No. of Rooms <span class="color_red">*</span></mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="numberOfRooms">
                    </mat-form-field>
                    <div *ngIf="guestForm.controls['numberOfRooms'].invalid && (guestForm.controls['numberOfRooms'].dirty || guestForm.controls['numberOfRooms'].touched)"
                        class="alert">
                        <mat-error *ngIf="(guestForm.get('numberOfRooms')).errors?.required">No. of Rooms is required
                        </mat-error>
                        <mat-error *ngIf="(guestForm.get('numberOfRooms')).errors?.pattern">Invalid room number
                        </mat-error>
                    </div>
                </div>

                <div class="width_30 filter_Box addGuestBox margin_right_5">
                    <div fxLayoutGap="5px" class="filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Room Type" [customControl]="getFormControl('roomType')"
                            [value]="getFormControl('roomType').value" (selectionChange)="selectionChangeEvent($event)"
                            [selectOptions]="roomTypeOpt"></app-select>
                    </div>
                </div>

                <div fxLayoutGap="5px" class="width_15" fxLayout="column" fxLayoutAlign="start start">
                    <mat-label>Room No.</mat-label>
                    <mat-form-field class="width_100" appearance="outline">
                        <input matInput formControlName="roomNumber" [value]="getFormControl('roomNumber').value">
                    </mat-form-field>
                </div>
            </div>

            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                <div class="width_30 filter_Box addGuestBox margin_right_5">
                    <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Board" [value]="getFormControl('board').value"
                            (selectionChange)="getFormControl('board').setValue($event)"
                            [selectOptions]="boardOpt"></app-select>
                        <!-- <div *ngIf="guestForm.controls['board'].invalid && (guestForm.controls['board'].dirty || guestForm.controls['board'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('board')).errors?.required">Board is required</mat-error>
                    </div> -->
                    </div>
                </div>
                <div class="width_30 filter_Box addGuestBox margin_right_5">
                    <div fxLayoutGap="5px" class=" filter_section dropdownCustom" fxLayout="column"
                        fxLayoutAlign="start start">
                        <app-select label="Cancellation Policy" [value]="getFormControl('cancellationPolicy').value"
                            (selectionChange)="getFormControl('cancellationPolicy').setValue($event)"
                            [selectOptions]="cancellationPolicyOpt"></app-select>
                        <!-- <div *ngIf="guestForm.controls['cancellationPolicy'].invalid && (guestForm.controls['cancellationPolicy'].dirty || guestForm.controls['cancellationPolicy'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('cancellationPolicy')).errors?.required">Cancellation Policy is required</mat-error>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!--    mobile-->
        <div class="container_section width_100" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center"
            *ngIf="globalService.isMobile$.getValue() && isBookingInfoVisible">
            <div class="container_body width_100" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>No. of Rooms <span class="color_red">*</span></mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="numberOfRooms">
                        </mat-form-field>
                        <div *ngIf="guestForm.controls['numberOfRooms'].invalid && (guestForm.controls['numberOfRooms'].dirty || guestForm.controls['numberOfRooms'].touched)"
                            class="alert">
                            <mat-error *ngIf="(guestForm.get('numberOfRooms')).errors?.required">No. of Rooms is
                                required
                            </mat-error>
                            <mat-error *ngIf="(guestForm.get('numberOfRooms')).errors?.pattern">Invalid room number
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <app-select label="Room Type" [customControl]="getFormControl('roomType')"
                            [value]="getFormControl('roomType').value"
                            (selectionChange)="getFormControl('roomType').setValue($event)"
                            [selectOptions]="roomTypeOpt"></app-select>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Room No.</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="roomNumber" [value]="getFormControl('roomNumber').value">
                        </mat-form-field>
                        <!-- <app-select label="Room No." [customControl]="getFormControl('roomNumber')"
                            [value]="getFormControl('roomNumber').value"
                            (selectionChange)="getFormControl('roomNumber').setValue($event)"
                            [selectOptions]="roomNumberOpt"></app-select> -->
                    </div>

                    <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                        <app-select label="Board" [value]="getFormControl('board').value"
                            (selectionChange)="getFormControl('board').setValue($event)"
                            [selectOptions]="boardOpt"></app-select>
                        <!-- <div *ngIf="guestForm.controls['board'].invalid && (guestForm.controls['board'].dirty || guestForm.controls['board'].touched)" class="alert">
                          <mat-error *ngIf="(guestForm.get('board')).errors?.required">Board is required</mat-error>
                        </div> -->
                    </div>
                </div>

            </div>

            <div class="container_body width_100" fxLayout="row" fxLayoutAlign="start center">
                <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                    <app-select label="Cancellation Policy" [value]="getFormControl('cancellationPolicy').value"
                        (selectionChange)="getFormControl('cancellationPolicy').setValue($event)"
                        [selectOptions]="cancellationPolicyOpt"></app-select>
                    <!-- <div *ngIf="guestForm.controls['cancellationPolicy'].invalid && (guestForm.controls['cancellationPolicy'].dirty || guestForm.controls['cancellationPolicy'].touched)" class="alert">
                      <mat-error *ngIf="(guestForm.get('cancellationPolicy')).errors?.required">Cancellation Policy is required</mat-error>
                    </div> -->
                </div>
            </div>
        </div>


        <!--        GUESTS-->
        <div *ngFor="let guestItem of guestsArray" class="width_100" fxLayout="column" fxLayoutGap="10px"
            fxLayoutAlign="start center">
            <div class="container_title width_100 extra_margin_top uppercase" fxLayout="row"
                fxLayoutAlign="space-between center">
                <span>{{guestItem.label}} GUEST</span>
                <mat-icon class="cursor_pointer" (click)="removeGuestToForm()">remove</mat-icon>
            </div>

            <div class="container_section width_100" fxLayout="column" fxLayoutAlign="start center">
                <!--      desktop-->
                <div class="container_body width_100" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center start"
                    *ngIf="!globalService.isMobile$.getValue()">

                    <div class="width_100" fxLayoutAlign="space-between center">
                        <div fxLayoutGap="5px" fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>Last Name <span class="color_red">*</span></mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <input matInput [formControl]="guestForm.get(guestItem?.formName).get('lastName')">
                            </mat-form-field>
                            <div *ngIf="guestForm.get(guestItem?.formName).get('lastName').invalid
                        && (guestForm.get(guestItem?.formName).get('lastName').dirty || guestForm.get(guestItem?.formName).get('lastName').touched)"
                                class="alert">
                                <mat-error
                                    *ngIf="(guestForm.get(guestItem?.formName).get('lastName')).errors?.required">
                                    Last Name is required
                                </mat-error>
                            </div>
                        </div>

                        <div fxLayoutGap="5px" fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>First Name <span class="color_red">*</span></mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <input matInput [formControl]="guestForm.get(guestItem?.formName).get('firstName')">
                            </mat-form-field>
                            <div *ngIf="guestForm.get(guestItem?.formName).get('firstName').invalid
                        && (guestForm.get(guestItem?.formName).get('firstName').dirty || guestForm.get(guestItem?.formName).get('firstName').touched)"
                                class="alert">
                                <mat-error
                                    *ngIf="(guestForm.get(guestItem?.formName).get('firstName')).errors?.required">
                                    First Name is required
                                </mat-error>
                            </div>
                        </div>

                        <div fxLayoutGap="5px" fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>Date of Birth</mat-label>
                            <mat-form-field class="filter_1 width_100" appearance="outline"
                                fxLayoutAlign="start center">
                                <mat-datepicker-toggle matPrefix [for]="checkInDate"></mat-datepicker-toggle>
                                <input [formControl]="guestForm.get(guestItem?.formName).get('birthDate')" matInput
                                    [matDatepicker]="checkInDate" placeholder="DD/MM/YYYY">
                                <mat-datepicker #checkInDate></mat-datepicker>
                            </mat-form-field>
                            <!-- <div *ngIf="guestForm.get(guestItem?.formName).get('birthDate').invalid
                            && (guestForm.get(guestItem?.formName).get('birthDate').dirty
                            || guestForm.get(guestItem?.formName).get('birthDate').touched)" class="alert">
                                <mat-error
                                    *ngIf="(guestForm.get(guestItem?.formName).get('birthDate')).errors?.required">
                                    Date of Birth is required
                                </mat-error>
                            </div> -->
                        </div>
                    </div>

                    <div class="width_100" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <div fxLayoutGap="5px" fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>Contact No.</mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <input matInput [formControl]="guestForm.get(guestItem?.formName).get('contactNo')">
                            </mat-form-field>
                        </div>

                        <div fxLayoutGap="5px" fxFlex="65" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>Email</mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <input matInput [formControl]="guestForm.get(guestItem?.formName).get('email')">
                            </mat-form-field>
                            <div *ngIf="guestForm.controls['email'].invalid && (guestForm.controls['email'].dirty || guestForm.controls['email'].touched)"
                                class="alert">
                                <mat-error *ngIf="(guestForm.get('email')).errors?.required">Email is
                                    required</mat-error>
                                <mat-error *ngIf="(guestForm.get('email')).errors?.pattern">Please enter a valid email
                                    address
                                </mat-error>
                            </div>
                        </div>
                    </div>


                </div>

                <!--      mobile-->
                <div class="container_body width_100" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center"
                    *ngIf="globalService.isMobile$.getValue()">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>No. of Adults <span class="color_red">*</span></mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <input matInput formControlName="adults">
                            </mat-form-field>
                            <div *ngIf="guestForm.controls['adults'].invalid && (guestForm.controls['adults'].dirty || guestForm.controls['adults'].touched)"
                                class="alert">
                                <mat-error *ngIf="(guestForm.get('adults')).errors?.required">No. of Adults is required
                                </mat-error>
                                <mat-error *ngIf="(guestForm.get('adults')).errors?.pattern">Invalid no. of adults
                                </mat-error>
                            </div>
                        </div>

                        <div fxLayoutGap="5px" class="width_45" fxLayout="column" fxLayoutAlign="start start">
                            <mat-label>No. of Children (0-17 y.o)</mat-label>
                            <mat-form-field class="width_100" appearance="outline">
                                <input matInput formControlName="children">
                            </mat-form-field>
                            <!-- <div *ngIf="guestForm.controls['children'].invalid && (guestForm.controls['children'].dirty || guestForm.controls['children'].touched)" class="alert">
                              <mat-error *ngIf="(guestForm.get('children')).errors?.required">Children is required</mat-error>
                            </div> -->
                        </div>
                    </div>


                    <div fxLayoutGap="5px" class="width_30" fxLayout="column" fxLayoutAlign="start start">
                        <mat-label>Note/Remarks</mat-label>
                        <mat-form-field class="width_100" appearance="outline">
                            <input matInput formControlName="extras">
                        </mat-form-field>
                        <!-- <div *ngIf="guestForm.controls['extras'].invalid && (guestForm.controls['extras'].dirty || guestForm.controls['extras'].touched)" class="alert">
                          <mat-error *ngIf="(guestForm.get('extras')).errors?.required">Extras is required</mat-error>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="container_title-add width_100 extra_margin_top"
            *ngIf="guestsArray.length !== availableGuestsArrayLabels.length && guestDetail?.guestType=='In-House'"
            fxLayout="row" fxLayoutAlign="start end" (click)="addGuestToForm()"> -->
        <div class="container_title-add width_100 extra_margin_top"
            *ngIf="guestsArray.length !== availableGuestsArrayLabels.length && isBookingInfoVisible" fxLayout="row"
            fxLayoutAlign="start end" (click)="addGuestToForm()">
            <div>
                <mat-icon class="cursor_pointer">add</mat-icon>
            </div>
            <span class="cursor_pointer">Add more guest</span>
        </div>

    </form>
    <div *ngIf="!hidden" class="container_footer width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="end center">
        <!-- <div class="buttonSection buttonSection_1" fxLayout="row" fxLayoutAlign="center center">
          <div fxLayoutAlign="center center" (click)="confirmNavigate()">Cancel</div>
        </div> -->
        <div (click)="isEditMode ? editGuest('save') : addGuest()" class="buttonSection" fxLayout="row" fxFlex="100"
            fxLayoutAlign="center center" [ngClass]="guestDetail?.guestApproval==1?'buttonSection_4':'buttonSection_2'">
            <div fxLayoutAlign="center center">{{guestDetail?.guestApproval==1?'Save & Approve':'Save'}}</div>
        </div>
    </div>
    <div *ngIf="!hidden" class="container_footer width_100" fxLayoutGap="20px" fxLayout="row"
        fxLayoutAlign="end center">
        <div (click)="DenyRequest()" class="buttonSection buttonSection_3" fxLayout="row" fxFlex="100"
            fxLayoutAlign="center center" *ngIf="guestDetail?.guestApproval==1">
            <div fxLayoutAlign="center center">Deny Request</div>
        </div>
    </div>
</div>