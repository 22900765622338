import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import firebase from 'firebase/compat/app';

import { filter, takeUntil } from 'rxjs/operators';

import { CreateRequestComponent } from '../create-request/create-request.component';
import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';
import { ViewRequestComponent } from '../../housekeeping/view-request/view-request.component';

import { GlobalServices } from '../../../shared/services/global.services';
import { RestfullServices } from '../../../shared/services/restfull.services';

import {
    ChatGuestListModel, HouseKeepingCategoryModel,
    HouseKeepingRequestModel, SelectOptionsModel
} from '../../../shared/models/shared.model';
import { DeviceDetectorService } from 'ngx-device-detector';

import { snapshotToArray } from '../../../shared/functions/helpers';
import { AutoUnsubscribe } from '../../../shared/functions/autounsubscribe';

@Component({
    selector: 'app-messages-side',
    templateUrl: './messages-side.component.html',
    styleUrls: ['./messages-side.component.scss']
})
@AutoUnsubscribe()
export class MessagesSideComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('chatcontent') public chatContent: ElementRef;
    @Input() public selectedChat;
    @Input() guestDetailChat = false;

    @Input() guestList:ChatGuestListModel[];

    get data() {
        return this._data;
    }

    @Input() set data(data) {
        if (!data || !data?.roomname) {
            return;
        }
        this._data = data;
        this.fetchChat();
        if (this.guestList?.length) {
            this.fetchGuestDetails(data?.roomname?.split('_')?.[1] as unknown as number);
        }
    }

    @Output() closeMessaging = new EventEmitter();

    public activeUserInfo = false;

    public get isMobileDisplay() {
        return !this.deviceDetectorService.isDesktop();
    }

    public selectedGuest: ChatGuestListModel;
    public chats = [];
    public selectOptions: SelectOptionsModel[] = [
        {
            label: 'Open',
            value: 'open'
        },
        {
            label: 'Close',
            value: 'close'
        }
    ];

    private _data = {
        roomname: '',
        message: '',
        type: '',
        guestname: '',
        key: '',
        messageCount: 0,
        chatStatus: ''
    };

    private requestListItems: HouseKeepingRequestModel[] = [];
    private houseKeepingCategory: HouseKeepingCategoryModel[] = [];

    constructor(
        public datepipe: DatePipe,
        public dialog: MatDialog,
        public globalService: GlobalServices,
        public restfullServices: RestfullServices,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private deviceDetectorService: DeviceDetectorService,
    ) {
    }

    public getContentEvent(value: string) {
        const stringArr = value.split(' ');
        stringArr.pop();
        return stringArr.join(' ');
    }

    public getDotFormat(value: string) {
        return value?.replace(/,/g, '.');
    }

    ngOnInit() {
        // this.fetchChat();
        if (this.guestList?.length) {
            this.fetchGuestDetails(this.data?.roomname?.split('_')?.[1] as unknown as number);
        }
        if (!this.activatedRoute.snapshot.queryParams.id && this.globalService.isMobile$.getValue()) {
            const headerData = this.globalService.headerData$.getValue();
            headerData.headerTitle = '';
            headerData.info1 = this.selectedGuest.lastName;
            headerData.info2 = this.selectedGuest.firstName;
            this.globalService.headerData$.next(headerData);
            this.globalService.headerBackAction$
                .pipe(filter(item => !!item), takeUntil(this['destroy$']))
                .subscribe(() => {
                    if (this.activeUserInfo) {
                        this.activeUserInfo = false;
                        setTimeout(() => this.scrollChat(), 0);
                        this.globalService.headerData$.next({
                            headerTitle: 'Chat',
                            showBackButton: true
                        });
                    } else {
                        this.closeMessaging.emit();
                    }
                    this.globalService.headerBackAction$.next(null);
                });
        }

        this.getHouseKeepingRequest();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const txHeight = 16;
            const tx = document.getElementsByTagName('textarea');
            const chatSection = document.querySelector('.chat_section');
            const footer = document.querySelector('.sticky-footer');

            for (let i = 0; i < tx.length; i++) {
                if (tx[i].value === '') {
                    tx[i].setAttribute('style', 'height:' + txHeight + 'px;overflow-y:hidden;resize: none');
                    chatSection.setAttribute('style', `height: calc(${this.guestDetailChat ? '75' : '90'}vh - ${footer.clientHeight}px)`);
                } else {
                    tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;resize: none');
                    chatSection.setAttribute('style', `height: calc(${this.guestDetailChat ? '75' : '90'}vh - ${footer.clientHeight}px)`);
                }
                tx[i].addEventListener('input', OnInput, false);
            }
            const state = this.guestDetailChat;

            function OnInput() {
                this.style.height = 0;
                this.style.height = (this.scrollHeight) + 'px';
                chatSection.setAttribute('style', `height: calc(${state ? '75' : '90'}vh - ${footer.clientHeight}px)`);
            }
        }, 200);
    }

    ngOnDestroy() {}

    openRequest() {
        this.dialog.open(CreateRequestComponent, {
            panelClass: 'percent90-modal-component',
            width: '550px',
            data: {
                title: 'CREATE NEW TASK',
                bookingId: this.selectedGuest.bookingNumber,
                roomNumber: this.selectedGuest.roomNumber,
            }
        })
            .afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe(this.createRequest.bind(this));
    }

    createRequest(request) {
        const guestId = this.selectedChat.split('_')[1];
        const requestObj = this.prepareRequestObj(request);

        this.globalService.showLoader$.next(true);
        this.restfullServices.saveHouseKeepingRequest(requestObj, Number(guestId))
            .pipe(takeUntil(this['destroy$']))
            .subscribe(response => {
                this.globalService.showLoader$.next(false);
                if (!isNaN(response)) {
                    if (request.requestType === '1') {
                        this.globalService.houseKeepingRequestCount++;
                    }
                    this.openSnackBar();
                    this.sendMessage(response);
                } else {
                    this.globalService.showAlert('Error while saving Housekeeping Request. Please try again!');
                }
            }, () => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }

    openSnackBar() {
        this.snackBar.openFromComponent(SnackBarComponent, {
            panelClass: ['custom-snackbar'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
            data: {
                svgIcon: 'check-circle',
                message: 'Task successfully created!'
            }
        });
    }

    prepareRequestObj(request) {
        const reqObj = {
            cleaning: {},
            request: [],
            note: '',
            dateTime: '',
            source: 'hotelAdmin',
            // taskReference: this.selectedGuest.id.toString()
            //     + (this.requestListItems[this.requestListItems.length - 1].houseKeepingCallId + 1).toString().padStart(6, '0')
        };

        if (request.requestType === 2) {
            reqObj.cleaning = {
                status: 1,
                cleaningDate: this.datepipe.transform(new Date(request.startDate),'MM/dd/YYYY'),
                cleaningTime: request.cleaningTime,
            };
        }
        if (request.requestType === 1) {
                reqObj.request.push({
                  'amenityName': request.requestAmenity,
                  'amenityCategoryId': request.requestCategory,
                  'status': 1,  // open - 1, delivered - 2
                  'quantity': request.requestQuantity==""?"1":request.requestQuantity,
                });
        }
        reqObj.note = request.addedNote ? btoa(request.addedNote) : '';
        reqObj.dateTime =new Date().toUTCString();

        return reqObj;
    }

    getDate(toDate: Date) {
        return (toDate.getMonth() + 1) + '/' + toDate.getDate() + '/' + toDate.getFullYear();
    }

    updateStatus(status: string) {
        const newAdminMessage = firebase.database().ref('chatrooms/' + this.data.key + '/status').set(status);
        this.selectedChat = null;
        this.chats = [];
        this.closeMessaging.emit();
    }

    fetchChat() {
        firebase
            .database()
            .ref(`chatrooms/${this.data.key}/chats`)
            .on('value', response => {
                this.chats = [];
                this.chats = this.globalService.bindDate(
                    snapshotToArray(response as unknown as firebase.database.DataSnapshot[])
                );
                setTimeout(() => this.scrollChat(), 0);
            });
    }

    scrollChat() {
        if (this.chatContent?.nativeElement) {
            this.chatContent.nativeElement.scroll({
                top: this.chatContent.nativeElement.scrollHeight,
                behavior: 'auto'
            });
        }
    }

    fetchGuestDetails(guestId?: number | string) {
        this.selectedGuest = this.guestList.find(key => key.id === Number(guestId));
    }

    sendMessage(statusNumber?: number) {
        if (statusNumber) {
            const statusMessage = firebase.database().ref('chatrooms/' + this.data.key + '/chats').push();
            statusMessage.set({
                message: `${localStorage.getItem('email')} created task ${statusNumber}`,
                messageGuest:` Great news, ${this.selectedGuest.firstName}! I'm delighted to inform you that your request is underway.<br><br>
                Request ID: ${statusNumber}<br><br>
                Your satisfaction is our top priority, If you have any further inquiries or need additional assistance, feel free to reply to this message.`,
                date: this.datepipe.transform(new Date(), 'dd-MM-yyyy'),
                time: this.datepipe.transform(new Date(), 'HH:mm:ss'),
                type: 'taskEvent',
                user: this.globalService.HOTELID,
            });
        } else if (this.data.message && this.data.message.length > 0) {
            const newMessage = firebase.database().ref('chatrooms/' + this.data.key + '/chats').push();
            newMessage.set({
                message: this.data.message,
                // roomname: this.data.roomname,
                date: this.datepipe.transform(new Date(), 'dd-MM-yyyy'),
                time: this.datepipe.transform(new Date(), 'HH:mm:ss'),
                type: this.data.type,
                user: this.globalService.HOTELID
            });
            this.data.messageCount++;
            firebase.database().ref('chatrooms/' + this.data.key + '/unreadGuestMessages').set(this.data.messageCount);
            this.data.message = null;
        }
    }

    viewRequest(id: number) {
        const requestListItems: HouseKeepingRequestModel[] =
            this.requestListItems.filter(value => value.houseKeepingCallId === +id);
        console.log(this.selectedGuest.id.toString() + requestListItems[0].houseKeepingCallId.toString().padStart(6, '0'));

        this.dialog.open(ViewRequestComponent, {
            panelClass: 'custom_dialog_container_',
            width: '100%',
            height: '96%',
            data: {
                title: 'NEW REQUEST!',
                requestDetails: requestListItems,
                houseKeepingCategory: this.houseKeepingCategory,
                referenceNumber: this.selectedGuest.id.toString() + requestListItems[0].houseKeepingCallId.toString().padStart(6, '0')
            }
        }).afterClosed()
            .pipe(takeUntil(this['destroy$']))
            .subscribe((result: number) => {
                if (result && requestListItems[0].requestStatus !== result) {
                    this.updateRequestStatus(requestListItems[0], Number(result));
                }
            });
    }

    private updateRequestStatus(request: HouseKeepingRequestModel, requestStatus: number) {
        this.globalService.showLoader$.next(true);
        this.restfullServices.updateHouseKeepingRequestStatus(request.houseKeepingCallId,"request", requestStatus)
            .pipe(takeUntil(this['destroy$']))
            .subscribe(() => {
                request.requestStatus = requestStatus;
                this.globalService.houseKeepingRequestCount--;
                this.globalService.showLoader$.next(false);
            }, () => {
                this.globalService.showLoader$.next(false);
                this.globalService.showAlert(this.globalService.errorMsg);
            });
    }

    private getHouseKeepingRequest() {
        this.globalService.showLoader$.next(true);
        this.requestListItems = [];
        this.restfullServices.getHouseKeepingRequest()
            .toPromise()
            .then(response => {
                // console.log(response);
                this.houseKeepingCategory = response.houseKeepingCategory;
                response.houseKeepingRequest.forEach(requestData => {
                    const toDate = new Date();
                    const ciDate = new Date(requestData.arrival);
                    const coDate = new Date(requestData.departure);
                    const presentDate = toDate.getDate() + '/' + toDate.getMonth() + '/' + toDate.getFullYear();
                    const tomorrowDate = (toDate.getDate() + 1) + '/' + toDate.getMonth() + '/' + toDate.getFullYear();
                    const checkInDate = ciDate.getDate() + '/' + ciDate.getMonth() + '/' + ciDate.getFullYear();
                    const checkOutDate = coDate.getDate() + '/' + coDate.getMonth() + '/' + coDate.getFullYear();

                    if (presentDate === checkInDate) {
                        requestData.arrival = 'Today';
                    } else if (tomorrowDate === checkInDate) {
                        requestData.arrival = 'Tomorrow';
                    } else {
                        requestData.arrival = this.getGDate(ciDate);
                    }

                    if (presentDate === checkOutDate) {
                        requestData.departure = 'Today';
                    } else if (tomorrowDate === checkOutDate) {
                        requestData.departure = 'Tomorrow';
                    } else {
                        requestData.departure = this.getGDate(coDate);
                    }
                    requestData.requests.forEach(element => {
                        if (element.type === 'request') {
                            const objReq: HouseKeepingRequestModel = {
                                id: element.id,
                                guestId: element.guestId,
                                roomNumber: requestData.roomNumber,
                                arrival: requestData.arrival,
                                departure: requestData.departure,
                                requestStatus: element.status,
                                houseKeepingCallId: element.houseKeepingCallId,
                                requestCategory: element.requestCategory,
                                requestItem: element.requestItem,
                                requestNote: element.requestNote,
                                requestQuantity: element.requestQuantity,
                                dateTime: element.dateTime
                            };
                            this.requestListItems.push(objReq);
                        }
                    });
                });
                // this.globalService.showLoader$.next(false);
            })
            .catch(() => this.globalService.showAlert(this.globalService.errorMsg));
    }

    private getGDate(toDate: Date) {
        return toDate.getDate() + '-' + (this.globalService.monthList[toDate.getMonth()]) + '-' + toDate.getFullYear();
    }
}
