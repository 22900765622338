<div class="housekeeping-page width_100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start center">
    <form [formGroup]="transactionsForm" class="width_100" *ngIf="globalService.isMobile$.getValue()">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            <div class="filter_section width_100" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="filter_1" appearance="outline" fxLayoutAlign="start center">
                    <mat-icon matPrefix>search</mat-icon>
                    <input formControlName="name" matInput placeholder="Invoice ref., Guest name, Room" #input
                        (keyup)="searchFilter($event.target.value)">
                    <button type="button" class="search_button" (click)="resetFilter()" *ngIf="searchValue">
                        <i class="material-icons">close</i>
                    </button>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="width_100 position_relative" fxLayout="row" fxLayoutAlign="start center">
        <mat-tab-group fxFlex="100" (selectedIndexChange)="setTableTools($event)" [selectedIndex]="activeTableIndex">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <div id="all_transactions">All Transactions</div>
                    </div>
                </ng-template>
                <div class="cleaning_detail_container">
                    <div fxFlex="100" fxLayoutGap="20px" fxLayout="column" fxLayoutAlign="start start">
                        <div class="cleaning_filter width_100" fxLayout="row" fxLayoutAlign="start center"
                            *ngIf="!globalService.isMobile$.getValue()">
                            <form [formGroup]="transactionsForm" class="width_100" fxLayout="row" fxLayoutGap="10px"
                                fxLayoutAlign="space-between center">
                                <div class="filter_searchBox" fxLayoutAlign="start center">
                                    <div class="filter_section" fxLayout="row" fxLayoutAlign="start center">
                                        <mat-form-field class="filter_1" appearance="outline"
                                            fxLayoutAlign="start center">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <input formControlName="name" matInput
                                                placeholder="Invoice ref., Guest name, Room" #input
                                                (keyup)="searchFilter($event.target.value)">
                                            <button type="button" class="search_button" (click)="resetFilter()"
                                                *ngIf="searchValue">
                                                <i class="material-icons">close</i>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="filter_Box" fxLayout="row" fxLayoutGap="10px"
                                    fxLayoutAlign="flex-end center">
                                    <div (click)="resetFilter()"
                                        [class.clear_filter_section-hidden]="!isValueInFilterForm"
                                        class="clear_filter_section cursor_pointer" fxLayoutGap="5px" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <div>Clear filter</div>
                                        <mat-icon [svgIcon]="'refresh'"></mat-icon>
                                    </div>
                                    <div class="filter_section servicetype dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Service Status" [value]="serviceType.value"
                                            (selectionChange)="serviceType.setValue($event)"
                                            [selectOptions]="serviceList" [valueField]="'serviceName'"
                                            [labelField]="'serviceName'">
                                        </app-select>
                                    </div>
                                    <div class="filter_section paymentstatus dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Payment Status" [value]="paymentStatus.value"
                                            (selectionChange)="paymentStatus.setValue($event)"
                                            [selectOptions]="paymentList" [valueField]="'name'" [labelField]="'name'">
                                        </app-select>
                                    </div>
                                    <div class="filter_section transactionStatus dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <app-select placeholder="Transaction Status" [value]="transactionStatus.value"
                                            (selectionChange)="transactionStatus.setValue($event)"
                                            [selectOptions]="transactionList" [valueField]="'name'"
                                            [labelField]="'name'">
                                        </app-select>
                                    </div>
                                    <div class="filter_section sortSection dropdownCustom" fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <mat-form-field class="custom_Select">
                                            <mat-select placeholder="Sort by"
                                                (selectionChange)="onCustomeSorting($event)"
                                                formControlName="sortingValue">
                                                <mat-option value="">Select</mat-option>
                                                <mat-option [value]="sort.value" *ngFor="let sort of sortingList;">
                                                    {{sort.key}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon class="mat-icon" [svgIcon]="'keyboard-arrow-down'"></mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <span>
                                        <div class="button-section" fxLayout="center center"
                                            (click)="addSpaAppointment()">
                                            <div fxLayoutAlign="space-between center">
                                                <mat-icon>add</mat-icon>
                                                Add Appointment
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </form>
                        </div>

                        <!--*ngIf="transactions && transactions.length > 0"-->
                        <div class="cleaning_body_container width_100" fxLayout="column" fxLayoutGap="30px"
                            fxLayoutAlign="center center">
                            <div class="request_list width_100">
                                <mat-table #spaTable="matSort" [dataSource]="dataSource" matSort class="width_100" matSortDisableClear>
                                    <ng-container matColumnDef="invoiceNumber">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header> 
                                            Reference No.
                                        </mat-header-cell>
                                        <mat-cell mat-cell *matCellDef="let element">{{getInvoiceNo(element.invoiceNumber)}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="guestName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Guest Name
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.guestName}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="room">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Room
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.room}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="appointmentDate">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Appointment Date & Time
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" fxLayout="column"
                                            fxLayoutAlign="center start">
                                            {{element.appointmentDate | presentDateFormat}}<br>
                                            {{element.appointmentTime }} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="serviceType">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Service Type
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.serviceType}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="paymentStatus">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Payment Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div class="payment_status" fxLayout="row" fxLayoutAlign="start center"
                                                [ngClass]="element.paymentStatus">
                                                <div>{{element.transactionStatus == 'Cancelled' ? 'Cancelled':getPaymentStatus(element.paymentStatus)}}</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="totalAmount">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Total Amount
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{toNumber(element.totalAmount || element.price) | number:'1.2-2' }}
                                            {{globalService.currency}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="transactionStatus">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Transaction Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <div class="transaction_status" fxLayout="row" fxLayoutAlign="start center"
                                                [ngClass]="element.transactionStatus">
                                                <div style="margin:0 auto">{{element.transactionStatus}}</div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">
                                            Action
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()"
                                            fxLayoutAlign="center center">
                                            <button mat-button fxLayoutAlign="center center" [matMenuTriggerFor]="menu"
                                                class="send_link_button">
                                                <mat-icon>more_horiz</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu" yPosition="below">
                                                <button mat-menu-item (click)="addPayment(element)"
                                                    [disabled]="(element.paymentStatus == 'Paid' && element.amountDue === 0)||element.transactionStatus == 'Cancelled'||element.paymentStatus == 'Direct Billing'" 

                                                 
                                                    [ngStyle]="{
                                                  'color': (element.paymentStatus == 'Paid' && element.amountDue === 0)||element.transactionStatus == 'Cancelled'||element.paymentStatus == 'Direct Billing' ? '#A7A7A7' : '#495057'
                                                }">
                                                    Add Payment
                                                </button>
                                            </mat-menu>
                                        </mat-cell>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="openSpaDetail(row.invoiceNumber)"
                                        [ngClass]="{'highlightRow': row?.transactionStatus == 'Open'}">
                                    </tr>
                                </mat-table>
                                <mat-paginator #spaListDataPaginator [pageSizeOptions]="[10, 25, 50]"
                                    *ngIf="!(globalService.isMobile$ | async)" showFirstLastButtons
                                    aria-label="Select page of periodic elements">
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <div id="calendar_view">Calendar</div>
                    </div>
                </ng-template>
                <div class="navigationClass">
                    <a (click)="onPrevious()" class="preveous"><img src="../../../assets/images/keyboard-arrow-down.svg"
                            alt=""></a>
                    <a id="current" (click)="onToday()" class="currentWeek">This week</a>
                    <a (click)="onNext()" class="next"><img src="../../../assets/images/keyboard-arrow-down.svg"
                            alt=""></a>
                </div>

                <full-calendar [options]="calendarOptions" deepChangeDetection="true" #fullCalendar
                    id="calendar_event"></full-calendar>

                <ng-template #eventPanel>
                    <ng-container *ngIf="panelMode === 'view'">
                        <div class="eventViewpanle">
                            <div class="event_title">
                                <div class="event_title_inner">{{eventTitle}}</div>
                            </div>
                            <div class="event_description">
                                <div class="event_description_left">
                                    <div class="event_description_details">
                                        <div class="event_description_details_icon">
                                            <img src="../../../assets/images/spacalendar.svg" alt="">
                                        </div>
                                        <div class="event_description_details_icon_right">
                                            <div class="event_description_details_title">Date & Time</div>
                                            <div class="event_description_details_value">{{datetAndTime}}</div>
                                        </div>
                                    </div>
                                    <div class="event_description_details">
                                        <div class="event_description_details_icon">
                                            <img src="../../../assets/images/spaclipboard.svg" alt="">
                                        </div>
                                        <div class="event_description_details_icon_right">
                                            <div class="event_description_details_title">Service</div>
                                            <div class="event_description_details_value">{{Service}}</div>
                                        </div>
                                    </div>
                                    <div class="event_description_details" *ngIf="note && note!=''">
                                        <div class="event_description_details_icon">
                                            <img src="../../../assets/images/spanote.svg" alt="">
                                        </div>
                                        <div class="event_description_details_icon_right">
                                            <div class="event_description_details_title">Note</div>
                                            <div class="event_description_details_value">{{note}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="event_description_right">
                                    <div class="event_description_details">
                                        <div class="event_description_details_icon">
                                            <img src="../../../assets/images/spaperson.svg" alt="">
                                        </div>
                                        <div class="event_description_details_icon_right">
                                            <div class="event_description_details_title">Guest Name</div>
                                            <div class="event_description_details_value">{{guestName}}</div>
                                        </div>
                                    </div>
                                    <div *ngIf="assignee!=null || assignee!=undefined"
                                        class="event_description_details">
                                        <div class="event_description_details_icon">
                                            <img src="../../../assets/images/spamasked.svg" alt="">
                                        </div>
                                        <div class="event_description_details_icon_right">
                                            <div class="event_description_details_title">Assignee</div>
                                            <div class="event_description_details_value">{{assignee}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="event_buttons">
                                <a (click)="onClose()" class="close_button">Close</a>
                                <a (click)="onDeleteEvent()" class="delete_button">Cancel Appointment</a>
                                <a (click)="onEditEvent()" class="edit_button">Edit</a>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>

            </mat-tab>
        </mat-tab-group>
    </div>
</div>