import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { getTotalNumber, isiOS } from 'src/app/shared/functions/helpers';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { OrderService } from 'src/app/shared/services/order.service';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { RestfullServices } from 'src/app/shared/services/restfull.services';

@Component({
  selector: 'app-check-out-popup',
  templateUrl: './check-out-popup.component.html',
  styleUrls: ['./check-out-popup.component.scss']
})
export class CheckOutPopupComponent implements OnInit {

  public primaryGuest: string;

  public paymentForm = new UntypedFormGroup({
    primaryGuest: new UntypedFormControl(''),
    checkIn: new UntypedFormControl(''),
    room: new UntypedFormControl(''),
    amtBalance: new UntypedFormControl(''),
  });

  public subTotal = 0;
  public discount = 0;
  public totalAmount = 0;
  public change = 0;
  public balance = 0;
  public bookingNumber:string = "";

  public codeControl = new UntypedFormControl();

  constructor(public globalService: GlobalServices,
    private orderService: OrderService,
    private route: Router,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CheckOutPopupComponent>,
    public dialog: MatDialog,
    private restfullServices: RestfullServices,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public get isiOS(): boolean {
    return isiOS();
  }

  public getTotalNumber(value: number) {
    return getTotalNumber(Number(value));
  }

  ngOnInit(): void {
    // this.primaryGuest = this.data.order.orderNumber;
    this.paymentForm.get('primaryGuest').setValue(this.data.data.primaryGuest);
    this.paymentForm.get('primaryGuest').disable();

    this.paymentForm.get('checkIn').setValue(this.data.data.checkInDate + ' - ' + this.data.data.checkOutDate);
    this.paymentForm.get('checkIn').disable();


    this.paymentForm.get('room').setValue(this.data.data.room);
    this.paymentForm.get('room').disable();

    this.paymentForm.get('amtBalance').setValue(this.data.data.amountBalance + ',00'+this.globalService.currency);
    this.paymentForm.get('amtBalance').disable();

    this.balance = this.data.data.amountBalance;
    this.bookingNumber = this.data.data.bookingNumber;

    //this.calculateAmount();
    console.log(this.data);
    //   this.paymentForm.get('amountReceived').valueChanges
    //       .pipe(takeUntil(this['destroy$']))
    //       .subscribe(value => {
    //           this.paymentForm.get('change').setValue(+value - this.totalAmount > 0
    //               ? (+value - this.totalAmount).toFixed(2)
    //               : 0);
    //       });
  }

  ngOnDestroy() { }

  public showDialog() {
    let payload = {
      guestId: Number(this.activatedRoute.snapshot.queryParams.id),
      bookingNumber: this.data.data.bookingNumber,
      type: 'history',
      status: 'Checked-out',
      checkOut: this.getDate(),
      checkOutTime: this.getTime(),
      ratings: '',
      feedback: '',
      email:this.data.data.email,
      sopagoMinihotelInfo:this.data.data.sopagoMinihotelInfo,
      miniHotelReservationId:this.data.data.miniHotelReservationId,
      miniHotelMemberSerial:this.data.data.miniHotelMemberSerial
    };
    this.dialog.open(ModalDialogComponent, {
      panelClass: ['share-link_container'],
      width: '430px',
      height: '233px',
      autoFocus: false,
      data: {
        payload
      }
    }).afterClosed()
      .pipe()
      .subscribe((resp) => {
        if (resp != undefined) {
          this.closePopup();
        }
        // console.log(resp);
      });
  }

  public closePopup() {
    this.dialogRef.close();
  }
  public calculateChange(value: number) {
    //this.change = value - this.totalAmount;
    this.paymentForm.get('change').setValue(+value - this.totalAmount > 0
      ? (+value - this.totalAmount).toFixed(2)
      : 0);
  }

  getDate() {
    let toDate = new Date();
    //return (newDate.getDate() + " " + this.globalService.monthList[newDate.getMonth()] + " " + newDate.getFullYear());
    return ('0' + (toDate.getMonth() + 1).toString()).slice(-2) + '/' + ('0' + toDate.getDate().toString()).slice(-2) + '/' + toDate.getFullYear();
  }

  getTime() {
    let newDate = new Date();
    var hours = newDate.getHours() > 12 ? newDate.getHours() - 12 : newDate.getHours();
    var am_pm = newDate.getHours() >= 12 ? " pm" : " am";
    return (hours + ':00' + am_pm);
  }

  public submit() {
    const order = this.data.order;
    order.paymentMethod = this.paymentForm.get('paymentMethod').value;
    this.dialogRef.close();
  }

  private calculateAmount() {
    this.data.order.orders.forEach(order => {
      this.totalAmount += order.order.price * order.amount;
    });
    this.paymentForm.get('amount').setValue(this.totalAmount);
    this.paymentForm.get('amount').disable();

    this.paymentForm.get('paymentMethod').setValue(this.data.order.paymentMethod);
  }

}

