import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryListModel, GuestDetailModel, HeaderModel } from 'src/app/shared/models/shared.model';
import { GlobalServices } from 'src/app/shared/services/global.services';
import { RestfullServices } from 'src/app/shared/services/restfull.services';
import { boardOpt, cancellationPolicyOpt, genderOpt, roomNumberOpt } from '../edit-guest/consts';
import { takeUntil } from 'rxjs/operators';
import countryData from '../../../../assets/json/countires.json';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import firebase from 'firebase/compat/app';
import { CheckInPopupComponent } from '../check-in-popup/check-in-popup.component';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {
  @Input() guestDetail: GuestDetailModel;

  public guestForm: UntypedFormGroup;
  public countryList: CountryListModel[];

  public isEditMode: boolean;

  public genderOpt = genderOpt;
  public roomNumberOpt = roomNumberOpt;
  public boardOpt = boardOpt;
  public cancellationPolicyOpt = cancellationPolicyOpt;
  public roomTypeOpt: any;
  public age: number = 0;
  public checkInData: {};

  public minDate = new Date();
  private key: string;
  public guestType: string;

  public guestsArray = [];
  public availableGuestsArrayLabels = [
    {
      formName: 'secondGuest',
      label: '2nd'
    },
    {
      formName: 'thirdGuest',
      label: '3rd'
    },
    {
      formName: 'fourthGuest',
      label: '4th'
    }
  ];

  constructor(public globalService: GlobalServices,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private restfullServices: RestfullServices,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private route: Router,
    private changeDetector: ChangeDetectorRef) {
    this.key = this.activatedRoute.snapshot.queryParams.key;
    this.globalService.selectedGuestId = this.activatedRoute.snapshot.queryParams.id;
  }

  public getFormControl(formControlName: string) {
    return this.guestForm.get(formControlName);
  }


  ngOnInit() {
    this.isEditMode = this.activatedRoute.snapshot.queryParams.id;

    this.countryList = countryData;

    const headerData: HeaderModel = {
      headerTitle: 'User Registration',
      showBackButton: true,
      info1: '',
      info2: '',
      confirmBack: true,
      backActionTitle: 'Unsaved changes',
      backActionMsg: 'Are you sure you want to leave this page? Unsaved changes will be lost.',
      navigationPage: '/pages/guests/guest',
    };
    if (this.isEditMode) {
      headerData.queryParams = {
        id: this.activatedRoute.snapshot.queryParams.id
      };
    }
    this.globalService.headerData$.next(headerData);

    this.getRoomDetails();

    if (this.activatedRoute.snapshot.url.toString().includes('user-registration')) {
      this.getGuestDetails();
      if (this.guestForm.valid) {

      }
    } else {
      this.createGuestForm();
    }
  }

  ngOnDestroy() { }

  public getRoomDetails() {
    this.restfullServices.getHotelRooms().subscribe(rooms => {
      if (rooms) {
        this.roomTypeOpt = rooms;
      }
    });
  }

  public addGuestToForm() {
    if (this.guestsArray.length < this.availableGuestsArrayLabels.length) {
      const availableGuestLabel = this.availableGuestsArrayLabels[this.guestsArray.length];
      this.guestsArray.push(availableGuestLabel);
      this.guestForm.addControl(availableGuestLabel.formName, this.fb.group({
        lastName: ['', Validators.required],
        firstName: ['', Validators.required],
        birthDate: ['', Validators.required],
        contactNo: '',
        email: ''
      }));
    }
  }

  public removeGuestToForm() {
    if (this.guestsArray.length) {
      const availableGuestLabel = this.availableGuestsArrayLabels[this.guestsArray.length];
      this.guestsArray.pop();
      this.guestForm.removeControl(availableGuestLabel.formName);
    }
  }
  public checkNonNull = (control: AbstractControl): boolean => {
    if (control instanceof UntypedFormControl) {
      return control.value ? true : false;
    }
  }
  public editGuest() {
    this.guestsArray = [];
    if (this.guestForm.valid) {
      this.fillGuestForm();
      this.guestForm.value.bookingNumber = this.guestDetail.bookingNumber;
      //this.globalService.showLoader$.next(true);
      if (this.isNullish(this.guestForm.value.secondGuest)) {
        this.guestForm.value.secondGuest = null;
      }
      if (this.isNullish(this.guestForm.value.thirdGuest)) {
        this.guestForm.value.thirdGuest = null;
      }
      if (this.isNullish(this.guestForm.value.fourthGuest)) {
        this.guestForm.value.fourthGuest = null;
      }
      this.restfullServices.editGuest(this.guestForm.value)
        .subscribe(response => {
          if (response) {
            if (response.id > 0) {
              this.updateGuestDetails(response);
              //Register guest
              this.registerGuest();
            }
          } else {
            this.globalService.showAlert(this.globalService.errorMsg);
          }
          this.globalService.showLoader$.next(false);
        }, (err) => {
          {
            if (err === 402) {
              this.globalService.showAlert("Guest already exist with same email id");
            }
            if (err === 403) {
              this.globalService.showAlert("Guest already exist with booking id");
            }
            this.globalService.showLoader$.next(false);
          }

          //this.globalService.showAlert(this.globalService.errorMsg)
        });

    } else {
      this.guestForm.markAllAsTouched();
    }
  }
  public isNullish(object: any) {
    return Object.values(object).every(value => {
      if (value !== null) {
        return false;
      }
      return true;
    });
  }
  registerGuest() {
    const payload =
    {
      guestId: this.guestForm.value.id.toString(),
      lastName: this.guestForm.value.lastName,
      firstName: this.guestForm.value.firstName,
      email: this.guestForm.value.email,
      gender: this.guestForm.value.gender,
      birthDate: this.guestForm.value.dob,
      mobileNumber: this.guestForm.value.mobileNumber,
      streetAddress: this.guestForm.value.streetName,
      city: this.guestForm.value.town,
      country: this.guestForm.value.country,
      zipCode: this.guestForm.value.zipCode
    }
    this.restfullServices.saveRegistrationFormHotel(payload)
      .subscribe(response => {
        if (response) {
          if (response.guestId != undefined)
            this.openCheckInPopup();
        } else {
          this.globalService.showAlert(this.globalService.errorMsg);
        }
        this.globalService.showLoader$.next(false);
      }, (err) => {
        {
          this.globalService.showAlert(this.globalService.errorMsg);
          this.globalService.showLoader$.next(false);
        }

        //this.globalService.showAlert(this.globalService.errorMsg)
      });

    this.globalService.showLoader$.next(false);
  }
  openCheckInPopup() {
    this.checkInData = {
      id: this.activatedRoute.snapshot.queryParams.id,
      bookingNumber: this.guestDetail.bookingNumber,
      primaryGuest: this.guestDetail.lastName + ', ' + this.guestDetail.firstName,
      lastName: this.guestDetail.lastName,
      checkInDate: this.guestDetail.checkInDate,
      checkOutDate: this.guestDetail.checkOutDate,
      room: this.guestDetail.roomType,
      roomNo: this.guestDetail.roomNumber,
      guests: this.guestDetail.adults,
      email: this.guestDetail.email
    }
    this.dialog.open(CheckInPopupComponent, {
      panelClass: ['share-link_container', 'border-top'],
      width: '440px',
      autoFocus: false,
      data: {
        data: this.checkInData
      }
    }).afterClosed()
      .pipe(takeUntil(this['destroy$']))
      .subscribe((resp) => {
        this.globalService.headerClickAction$.next(null);
        const queryParams: any = {
          id: this.globalService.selectedGuestId,
        };
        this.route.navigate(['/pages/guests/guest'], { queryParams }).
          then(() => {
            window.location.reload();
          });
        this.snackBar.openFromComponent(SnackBarComponent, {
          panelClass: ['custom-snackbar'],
          horizontalPosition: 'right',
          verticalPosition: 'top',
          data: {
            svgIcon: 'check-circle',
            message: 'Check-In successful!'
          }
        });
      });
  }
  private updateGuestDetails(newGuestDetail: GuestDetailModel) {
    const guestName = newGuestDetail.lastName + ', ' + newGuestDetail.firstName;

    const headerData = this.getHeaderData();
    headerData.info1 = guestName;
    // update guest name in firebase chat
    firebase.database().ref('chatrooms/' + this.key + '/guestname').set(guestName);
    this.globalService.headerData$.next(headerData);
    this.guestDetail = newGuestDetail;
    const queryParams: any = {
      id: this.guestDetail.id,
    };
    this.router.navigate(['/pages/guests/guest'], { queryParams });
  }

  private openSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: ['custom-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      data: {
        svgIcon: 'check-circle',
        message
      }
    });
  }

  private getGuestDetails() {
    this.globalService.showLoader$.next(true);
    this.restfullServices.getGuestDetails(this.globalService.selectedGuestId).subscribe(response => {
      this.guestDetail = response;
      this.guestType = this.guestDetail?.guestType;

      // const headerData = this.getHeaderData();
      // headerData.info1 = this.guestDetail.lastName + ', ' + this.guestDetail.firstName;
      // headerData.info2 = 'Booking ID: ' + this.guestDetail.bookingNumber;
      // this.globalService.headerData$.next(headerData);

      this.createGuestForm();
      if (response.secondGuest != null) {
        this.addGuestToForm();
      }
      if (response.thirdGuest != null) {
        this.addGuestToForm();
      }
      if (response.fourthGuest != null) {
        this.addGuestToForm();
      }
      this.globalService.showLoader$.next(false);
    }, () => {
      this.globalService.showAlert(this.globalService.errorMsg);
      this.globalService.showLoader$.next(false);
    });
  }

  private createGuestForm() {
    this.guestForm = this.fb.group({
      id: [this.guestDetail?.id || null],
      hotelId: [],
      bookingId: [this.guestDetail?.bookingNumber || null, [Validators.required]],
      lastName: [this.guestDetail?.lastName || null, [Validators.required]],
      firstName: [this.guestDetail?.firstName || null, [Validators.required]],
      dob: [this.guestDetail?.dob ? new Date(this.guestDetail?.dob) : null, [Validators.required]],
      age: [this.guestDetail?.age || null],
      gender: [this.guestDetail?.gender || null, [Validators.required]],
      nationality: [this.guestDetail?.nationality || null, [Validators.required]],
      companyName: [this.guestDetail?.companyName || null],
      streetName: [this.guestDetail?.streetName || null, [Validators.required]],
      houseNumber: [this.guestDetail?.houseNumber || null, [Validators.required]],
      zipCode: [this.guestDetail?.zipCode || null, [Validators.required]],
      town: [this.guestDetail?.town || null, [Validators.required]],
      state: [this.guestDetail?.state || null],
      country: [this.guestDetail?.country || null, [Validators.required]],
      mobileNumber: [this.guestDetail?.mobileNumber || null, [Validators.required, Validators.pattern('^[0-9.+-, ]+$')]],
      bookingNumber: [this.guestDetail?.bookingNumber || null],
      email: [this.guestDetail?.email || null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      checkInDate: [this.guestDetail?.checkInDate ? new Date(this.guestDetail?.checkInDate) : new Date(), [Validators.required]],
      checkOutDate: [this.guestDetail?.checkOutDate ? new Date(this.guestDetail?.checkOutDate) : new Date(), [Validators.required]],
      nights: [this.guestDetail?.nights || null],
      numberOfRooms: [this.guestDetail?.numberOfRooms || null, [Validators.required, Validators.pattern('^[0-9]+$')]],
      roomType: [this.guestDetail?.roomType || null, [Validators.required]],
      roomId: [this.guestDetail?.roomId || null],
      roomNumber: [this.guestDetail?.roomNumber.toString() || null, [Validators.required]],
      board: [this.guestDetail?.board || null],
      cancellationPolicy: [this.guestDetail?.cancellationPolicy || null],
      children: [this.guestDetail?.children || null],
      extras: [this.guestDetail?.extras || null],
      status: [this.guestDetail?.status || null],
      appActivity: [this.guestDetail?.appActivity.toLowerCase() || null],
      created: [null],
      guestType: [this.guestDetail?.guestType || null, [Validators.required]],
      guestApproval: [this.guestDetail?.guestApproval || 0],
      secondGuest: this.fb.group({
        lastName: [this.guestDetail?.secondGuest?.lastName || null],
        firstName: [this.guestDetail?.secondGuest?.firstName || null],
        birthDate: [this.guestDetail?.secondGuest?.birthDate || null],
        contactNo: [this.guestDetail?.secondGuest?.contactNo || null],
        email: [this.guestDetail?.secondGuest?.email || null]
      }) || null,
      thirdGuest: this.fb.group({
        lastName: [this.guestDetail?.thirdGuest?.lastName || null],
        firstName: [this.guestDetail?.thirdGuest?.firstName || null],
        birthDate: [this.guestDetail?.thirdGuest?.birthDate || null],
        contactNo: [this.guestDetail?.thirdGuest?.contactNo || null],
        email: [this.guestDetail?.thirdGuest?.email || null]
      }) || null,
      fourthGuest: this.fb.group({
        lastName: [this.guestDetail?.fourthGuest?.lastName || null],
        firstName: [this.guestDetail?.fourthGuest?.firstName || null],
        birthDate: [this.guestDetail?.fourthGuest?.birthDate || null],
        contactNo: [this.guestDetail?.fourthGuest?.contactNo || null],
        email: [this.guestDetail?.fourthGuest?.email || null]
      }) || null

    });
    this.guestForm.get('checkInDate').valueChanges.subscribe(() => this.setDates(true));
    this.guestForm.get('checkOutDate').valueChanges.subscribe(() => this.setDates());

    this.changeDetector.detectChanges();
  }

  private setDates(checkIn?: boolean) {
    setTimeout(() => {
      const dayDifference = Number((((this.guestForm.value.checkOutDate?.getTime() + (6 * 60 * 60 * 1000))
        - this.guestForm.value.checkInDate?.getTime()) / (1000 * 3600 * 24)).toFixed(0));
      this.guestForm.get('nights').setValue(dayDifference ? dayDifference : 0);
      if (dayDifference < 0 && checkIn) {
        this.guestForm.get('checkOutDate').setValue(this.guestForm.value.checkInDate);
      }
    }, 100);
  }

  private fillGuestForm() {
    this.guestForm.value.hotelId = this.globalService.HOTELID;
    this.guestForm.value.bookingNumber = new Date().getTime().toString();
    this.guestForm.value.dob = this.getDate(new Date(this.guestForm.value.dob));
    this.guestForm.value.checkInDate = this.getDate(new Date(this.guestForm.value.checkInDate));
    this.guestForm.value.checkOutDate = this.getDate(new Date(this.guestForm.value.checkOutDate));
    this.guestForm.value.created = new Date();
    this.guestForm.value.age = this.age.toString();
  }

  private getDate(toDate: Date) {
    return ('0' + (toDate.getMonth() + 1).toString()).slice(-2) + '/' + ('0' + toDate.getDate().toString()).slice(-2) + '/' + toDate.getFullYear();
  }

  private getHeaderData(): HeaderModel {
    return this.globalService.headerData$.getValue();
  }
  public getAge(event: any) {
    var timeDiff = Math.abs(Date.now() - event.value);
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }
}
